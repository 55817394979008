import { useEffect } from 'react';
import type { MapLayerMouseEvent, MapRef } from 'react-map-gl';
import type { SerializedCoordinates } from '../types/Results';
import { useDispatch } from '../redux';
import { clearClickedCoordinates, updateClickedCoordinates } from '../redux/reducer';

/**
 * useMarkerLayerMouseClickEvents registers user click events with marker
 * layers.
 */

type Args = {
  map?: MapRef;
  onMarkerDoubleClick?: () => void;
  hoveredSerializedCoordinates?: SerializedCoordinates;
  clickedSerializedCoordinates?: SerializedCoordinates;
  trailheadResults?: string[];
  allowClickEvents?: boolean;
  layerIds?: string[];
};

export default function useMarkerLayerMouseClickEvents({
  map,
  hoveredSerializedCoordinates,
  clickedSerializedCoordinates,
  trailheadResults,
  onMarkerDoubleClick,
  allowClickEvents,
  layerIds
}: Args) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map || !allowClickEvents) {
      return;
    }

    const onClick = (event: MapLayerMouseEvent) => {
      const feature = map?.queryRenderedFeatures(event?.point, { layers: layerIds })[0];
      if (feature?.properties?.serializedCoordinates) {
        const serializedCoordinates = feature?.properties?.serializedCoordinates;

        dispatch(updateClickedCoordinates?.({ coordinates: serializedCoordinates, index: 0 }));

        // onClick for trailhead markers is managed in TrailheadMarker,
        // but this is still triggered
        // only trigger click events if there isn't a currently hovered trailhead
        // or there are current trailhead results but the user has not clicked on another pin
      } else if (
        (!trailheadResults && !hoveredSerializedCoordinates) ||
        (trailheadResults && clickedSerializedCoordinates) ||
        (trailheadResults && !feature?.properties?.serializedCoordinates)
      ) {
        dispatch(clearClickedCoordinates());
      }
    };

    const onDblClick = (event: MapLayerMouseEvent) => {
      const feature = map?.queryRenderedFeatures(event?.point)[0];
      const isDoubleClickable = feature?.properties?.serializedCoordinates;

      if (isDoubleClickable && onMarkerDoubleClick && !trailheadResults) {
        onMarkerDoubleClick();

        dispatch(clearClickedCoordinates());
      }
    };

    map?.on('click', onClick);
    map?.on('dblclick', onDblClick);

    // eslint-disable-next-line consistent-return
    return () => {
      map?.off('click', onClick);
      map?.off('dblclick', onDblClick);
    };
  }, [map, dispatch, onMarkerDoubleClick, trailheadResults, hoveredSerializedCoordinates, clickedSerializedCoordinates, allowClickEvents, layerIds]);
}
