import { Layer, Source } from 'react-map-gl';
import { useMemo } from 'react';
import { Feature } from 'geojson';
import { defaultSymbolLayerLayoutProps, getPolygonFillId, getUnclusteredPinsId } from '../../utils/layers';
import { useSelector } from '../../redux';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import { coordinatesToSerializedCoordinates } from '../../utils/serializedCoordinates';
import useParkPinMouseEvents from '../../hooks/useParkPinMouseEvents';
import { AllTrailsResult } from '../../types/Results';
import nationalParkSvg from './nationalParkSvg';
import defaultParkSvg from './defaultParkSvg';

type Props = {
  uniqueId: number;
};

const ParkPins = ({ uniqueId }: Props) => {
  const map = useMap();
  const sourceId = 'park';
  const { allParkCoordinates, parkIdsBySerializedCoordinates, parksById, allowClickEvents } = useSelector(state => ({
    allParkCoordinates: state.map.allParkCoordinates,
    parkIdsBySerializedCoordinates: state.map.parkIdsBySerializedCoordinates,
    parksById: state.map.parksById,
    allowClickEvents: state.map.allowClickEvents
  }));

  useParkPinMouseEvents({ fillLayerId: getPolygonFillId(uniqueId), map, allowClickEvents });

  const features = useMemo(() => {
    const features: Feature[] = [];

    (allParkCoordinates ?? []).forEach((coords, index) => {
      const { lat, lng } = coords;

      const serializedCoordinates = coordinatesToSerializedCoordinates(coords);
      const parkIds = parkIdsBySerializedCoordinates[serializedCoordinates];
      const type = parksById[parkIds[0]]?.type;

      const feature = {
        id: index,
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [lng, lat] },
        properties: { serializedCoordinates, type }
      } as Feature;

      features.push(feature);
    });
    return features;
  }, [allParkCoordinates, parkIdsBySerializedCoordinates, parksById]);

  const nationalImageIsLoaded = useSvgImage('national-parks', nationalParkSvg, 36, 36);
  const defaultImageIsLoaded = useSvgImage('default-parks', defaultParkSvg, 36, 36);

  if (!nationalImageIsLoaded || !defaultImageIsLoaded || !features || features.length === 0) {
    return null;
  }

  return (
    <Source
      id={sourceId}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features
      }}
    >
      <Layer
        type="symbol"
        id={getUnclusteredPinsId('park')}
        layout={{
          ...defaultSymbolLayerLayoutProps,
          'icon-anchor': 'bottom',
          'icon-image': ['case', ['==', ['get', 'type'], 'N'], 'national-parks', 'default-parks'],
          'icon-offset': [0, 8]
        }}
      />
    </Source>
  );
};

export default ParkPins;
