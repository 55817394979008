import { type FormatNumberOptions } from 'react-intl';
import { IntlShape } from '@alltrails/shared/react-intl';
import { getConvertedDistance, getConvertedElevation, metersToKilometers, metersToMiles } from './distanceConverters';
import { wrapFormattedPartsUnitInSpan } from './utils';
import { formatTimeHhMmSs } from './dateTimeFormatters';

/**
 * Gets the abbreviated and localized version of the specified unit
 * @param intl Intl object
 * @param unit Unit to abbreviate
 * @returns Abbreviated, localized unit
 */
export const getUnitAbbreviation = (intl: IntlShape, unit: 'meter' | 'kilometer' | 'foot' | 'mile') => {
  const parts = intl.formatNumberToParts(1, { style: 'unit', unit, notation: 'compact' });
  return parts.find(part => part.type === 'unit')?.value || '';
};

/**
 * Converts meters to the appropriate unit and wraps the unit in a span so it can be styled differently than the numeric value
 * @param intl Intl object
 * @param meters Meters value
 * @param metric Use km instead of mi
 * @param opts `formatNumberToParts` options
 * @param unitSpace Include a space before the unit
 * @returns A fragment with the converted distance value and the unit, wrapped in a span
 */
export const getFormattedDistanceWithUnitInSpan = (
  intl: IntlShape,
  meters: number,
  metric: boolean,
  opts?: FormatNumberOptions,
  unitSpace?: boolean
) =>
  wrapFormattedPartsUnitInSpan(
    intl.formatNumberToParts(getConvertedDistance(meters, metric), {
      style: 'unit',
      unit: metric ? 'kilometer' : 'mile',
      notation: 'compact',
      maximumFractionDigits: 1,
      ...opts
    }),
    unitSpace
  );

/**
 * Converts meters to the appropriate unit and wraps the unit in a span so it can be styled differently than the numeric value
 * @param intl Intl object
 * @param meters Meters value
 * @param metric Leave as m instead of ft
 * @param unitSpace Include a space before the unit
 * @returns A fragment with the converted distance value and the unit, wrapped in a span
 */
export const getFormattedElevationWithUnitInSpan = (intl: IntlShape, meters: number, metric: boolean, unitSpace?: boolean) =>
  wrapFormattedPartsUnitInSpan(
    intl.formatNumberToParts(getConvertedElevation(meters, metric), {
      style: 'unit',
      unit: metric ? 'meter' : 'foot',
      maximumFractionDigits: 0
    }),
    unitSpace
  );

/**
 * Calculates pace and wraps the unit in a span so it can be styled differently than the numeric value
 * @param intl Intl object
 * @param meters Meters value
 * @param seconds Seconds value
 * @returns A fragment with the converted pace and the unit, wrapped in a span
 */
export const getFormattedPaceWithUnitInSpan = (intl: IntlShape, meters: number, seconds: number, metric: boolean) => {
  const distance = metric ? metersToKilometers(meters) : metersToMiles(meters);
  const secondsPerUnitDistance = seconds / distance;
  return (
    <>
      {formatTimeHhMmSs(secondsPerUnitDistance)}
      <span>/{getUnitAbbreviation(intl, metric ? 'kilometer' : 'mile')}</span>
    </>
  );
};
