import { AllTrailsResult } from '../../types/Results';
import useCurrentResult from '../../hooks/useCurrentResult';
import useSelectedPinCategory from '../../hooks/useSelectedPinCategory';
import SelectedPin from '../SelectedPin';
import TrailheadMarker from '../TrailheadMarker';

export type Props = {
  hoveredResult?: AllTrailsResult;
  isPageWithListHovering?: boolean;
  shouldFetchPolyline?: boolean;
};

const BasePins = ({ hoveredResult, isPageWithListHovering, shouldFetchPolyline }: Props) => {
  const currentResult = useCurrentResult(shouldFetchPolyline);
  const selectedPinCategory = useSelectedPinCategory({
    hoveredResult,
    isPageWithListHovering
  });

  return (
    <>
      {selectedPinCategory === 'trailhead' && <TrailheadMarker currentResult={currentResult} />}
      {selectedPinCategory === 'unclustered' && <SelectedPin result={hoveredResult} />}
      {selectedPinCategory === 'standard' && <SelectedPin result={currentResult} />}
    </>
  );
};

export default BasePins;
