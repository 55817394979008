import { useEffect, useState } from 'react';
import { featureCollection, type FeatureCollection, type LineString } from '@turf/helpers';
import getFeatureCollectionFromTrailPolylines from '../utils/getFeatureCollectionFromTrailPolylines';
import { useGetGeoServiceTrailPolylinesQuery } from '../redux/geoServiceApi';

const usePolylines = (trailIds: number[]) => {
  const [data, setData] = useState<FeatureCollection<LineString>>(featureCollection([]));
  const { data: rawData } = useGetGeoServiceTrailPolylinesQuery(trailIds, {
    skip: trailIds.length === 0
  });

  useEffect(() => {
    let isSubscribed = true;

    if (!trailIds.length) {
      setData(featureCollection([]));
      return;
    }

    if (!rawData) {
      return;
    }

    const format = async () => {
      const newData = await getFeatureCollectionFromTrailPolylines(rawData);
      if (isSubscribed) {
        setData(newData);
      }
    };

    format();

    return () => {
      isSubscribed = false;
    };
  }, [trailIds, rawData]);

  return data;
};

export default usePolylines;
