import { post } from '@alltrails/shared/api';
import { Polylines } from '../types/Geo';

type Elevations = {
  elevation: number;
  location: {
    latitude: number;
    longitude: number;
  };
};

export type ElevationResponse = {
  elevations: Elevations[];
  stats: {
    gain: number;
    loss: number;
    distance: number;
  };
};

const getElevationsForPolylines = (encodedPolylines: Polylines): Promise<ElevationResponse | []> => {
  if (encodedPolylines.length < 1) {
    return Promise.resolve([]);
  }

  return post<ElevationResponse>('/api/alltrails/elevator', {
    paths: encodedPolylines
  });
};

export default getElevationsForPolylines;
