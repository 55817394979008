import { useCallback, useEffect, useState } from 'react';
import useMap from '../hooks/useMap';

/**
 * Hook to add an svg to mapbox as an image to be used by markers/layers/etc
 * @param imageName The identifier used by mapbox
 * @param svgString An svg definition, _as a string_ - see curatedWaypointSvg.ts for an example.
 *                  If we try to use an actual svg file and pass in svgFile.src, for example, next
 *                  recognizes that as an asset so the resulting src string is a url to our CDN where the
 *                  svg is being hosted (it works locally, but not for an actual deployment).
 *                  This would require us to then call map.loadImage which introduces race conditions
 *                  and extra waiting time. Instead, if we just keep the svg definition as a string
 *                  and convert it to a base-64 encoded string, we can use that directly as the image
 *                  src and skip the call to loadImage altogether.
 * @param svgWidth The width of the rendered image on the map
 * @param svgHeight The height of the rendered image on the map
 * @returns True once the image has been added to mapbox
 */
const useSvgImage = (imageName: string, svgString: string, svgWidth: number, svgHeight: number) => {
  const map = useMap();
  const [isLoaded, setIsLoaded] = useState(map?.hasImage(imageName));

  const loadImage = useCallback(() => {
    if (!map || map.hasImage(imageName)) {
      return;
    }

    // Doubling the image size and using a pixelRatio of 2 gives us a much higher-res image
    const img = new Image(svgWidth * 2, svgHeight * 2);

    img.onload = () => {
      map.addImage(imageName, img, { pixelRatio: 2 });
      setIsLoaded(true);
    };

    const src = `data:image/svg+xml;base64,${window.btoa(svgString)}`;
    img.src = src;
  }, [map, svgHeight, svgWidth, imageName, svgString]);

  useEffect(() => {
    if (!map) {
      return;
    }

    loadImage();

    // When the map's base style is changed, all sources and layers are lost.
    // style.load is triggered when a new base style is loaded, so re-add the svg image whenever this happens.
    // https://github.com/mapbox/mapbox-gl-js/issues/4006
    map.on('style.load', () => {
      setIsLoaded(false);
      loadImage();
    });
  }, [loadImage, map]);

  return isLoaded;
};

export default useSvgImage;
