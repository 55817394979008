import { useEffect } from 'react';
import type { MapRef, MapLayerMouseEvent } from 'react-map-gl';
import { useDispatch } from '../redux';
import {
  updateClickedParkCoordinates,
  clearClickedParkCoordinates,
  updateHoveredParkCoordinates,
  clearHoveredParkCoordinates
} from '../redux/reducer';
import { getUnclusteredPinsId } from '../utils/layers';

type Args = {
  fillLayerId: string;
  map?: MapRef;
  allowClickEvents?: boolean;
};

const useParkPinMouseEvents = ({ fillLayerId, map, allowClickEvents }: Args) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map || !allowClickEvents) {
      return;
    }

    const layerIds = [getUnclusteredPinsId('park')];
    const onMouseMove = (event: MapLayerMouseEvent) => {
      const features = map?.queryRenderedFeatures(event?.point, { layers: layerIds });
      const feature = map?.queryRenderedFeatures(event?.point, { layers: layerIds })[0];

      if (feature?.properties?.serializedCoordinates) {
        const serializedCoordinates = feature?.properties?.serializedCoordinates;

        dispatch(updateHoveredParkCoordinates?.(serializedCoordinates));
      } else if (features?.length === 0) {
        dispatch(clearHoveredParkCoordinates?.());
      }
    };

    const onClick = (event: MapLayerMouseEvent) => {
      const features = map?.queryRenderedFeatures(event?.point, { layers: [...layerIds, fillLayerId] });
      const feature = map?.queryRenderedFeatures(event?.point, { layers: layerIds })[0];

      if (feature?.properties?.serializedCoordinates) {
        const serializedCoordinates = feature?.properties?.serializedCoordinates;

        dispatch(updateClickedParkCoordinates?.({ coordinates: serializedCoordinates }));
      } else if (features?.length === 0) {
        dispatch(clearClickedParkCoordinates?.());
      }
    };

    map?.on('mousemove', onMouseMove);
    map?.on('click', onClick);

    return () => {
      map?.off('mousemove', onMouseMove);
      map?.off('click', onClick);
    };
  }, [dispatch, fillLayerId, map, allowClickEvents]);
};

export default useParkPinMouseEvents;
