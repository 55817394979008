import { ComponentProps } from 'react';
import ReactMapGL from 'react-map-gl';
import type { AllTrailsBounds, AllTrailsCenter, LngLat } from '../types/Geo';

const getInitialViewState = (
  initialBounds?: AllTrailsBounds,
  initialCenter?: AllTrailsCenter
): ComponentProps<typeof ReactMapGL>['initialViewState'] => {
  if (initialBounds) {
    const { longitudeTopLeft, latitudeTopLeft, longitudeBottomRight, latitudeBottomRight, padding } = initialBounds;
    const sw: LngLat = [longitudeTopLeft, latitudeBottomRight];
    const ne: LngLat = [longitudeBottomRight, latitudeTopLeft];

    return {
      bounds: [sw, ne],
      ...(padding ? { fitBoundsOptions: { padding: { left: padding, top: padding, right: padding, bottom: padding } } } : {})
    };
  }

  if (initialCenter) {
    return {
      latitude: Number(initialCenter[0]),
      longitude: Number(initialCenter[1]),
      zoom: Number(initialCenter[2])
    };
  }

  return {};
};

export default getInitialViewState;
