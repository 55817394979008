import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '@alltrails/denali/components/IconButton';
import CompassIcon from '@alltrails/denali/icons/Compass';
import useMap from '../../hooks/useMap';
import { useSelector } from '../../redux';
import { defaultPitchFor3dTerrain } from '../../utils/constants';
import styles from './styles/styles.module.scss';

const CompassControl = () => {
  const intl = useIntl();
  const map = useMap();
  const { is3dActive } = useSelector(state => ({
    is3dActive: state.map.is3dActive
  }));

  const [bearing, setBearing] = useState((map?.getBearing() || 0) * -1);

  useEffect(() => {
    let timer;

    const onRotate = ({ viewState }) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        // Multiply by -1 since the compass is pointing at the _opposite_ of the
        // current bearing of the map.
        setBearing(viewState.bearing * -1);
      }, 100);
    };

    map?.on('rotate', onRotate);

    return () => {
      map?.off('rotate', onRotate);
      clearTimeout(timer);
    };
  }, [map]);

  return (
    <IconButton
      className={styles.compass}
      icon={{ Component: CompassIcon, orientation: bearing }}
      onClick={() => {
        // Bearing is the direction we're facing (reset to 0, North).
        // Pitch is the angle, which is relevant for 3D maps.
        map?.easeTo({ bearing: 0, pitch: is3dActive ? defaultPitchFor3dTerrain : 0 });
      }}
      testId="map-compass"
      title={intl.formatMessage({ defaultMessage: 'Reset north and pitch' })}
      variant="elevated"
    />
  );
};

export default CompassControl;
