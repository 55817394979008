/* eslint-disable @next/next/no-img-element */
import { useEffect, useMemo, useState } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import { Feature, Geometry } from 'geojson';
import { getPhotos } from '@alltrails/trails';
import type { TrailPhoto } from '@alltrails/shared/types/trail';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { SPACE_50 } from '@alltrails/denali/tokens';
import { PhotoSize } from '@alltrails/shared/types/photos';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import { defaultSymbolLayerLayoutProps, getLayerBeforeId, userPhotosId } from '../../utils/layers';
import userPhotoSvg from './userPhotoSvg';
import styles from './styles/styles.module.scss';

const svgSize = 24;
const svgSizeWithoutShadow = 20;
const imageName = 'user-photo';

type FeatureProperties = Pick<TrailPhoto, 'id' | 'photoHash' | 'title'> & {
  latitude: number;
  longitude: number;
};

type UserPhotosProps = { trailId: number };

const UserPhotos = ({ trailId }: UserPhotosProps) => {
  const [photos, setPhotos] = useState<TrailPhoto[]>();
  const map = useMap();

  const imageIsLoaded = useSvgImage(imageName, userPhotoSvg, svgSize, svgSize);

  useEffect(() => {
    const fetchPhotos = async () => {
      getPhotos(trailId)
        .then(response => {
          setPhotos(response.photos);
        })
        .catch(e => {
          console.log('Error fetching photos');
        });
    };
    fetchPhotos();
  }, [trailId]);

  const features = useMemo(() => {
    if (!photos?.length) {
      return [];
    }
    return photos.reduce(
      (allPhotos, { id, location, photoHash, title }) => {
        if (!location) return allPhotos;

        const { latitude, longitude } = location;
        // Technically this could filter out a valid photo that was taken at exactly 0,0,
        // but the server is sending down 0,0 in prod for photos that don't have location data,
        // so we'd rather filter out that one-in-a-trillion photo rather than show a bunch of photos in the wrong location.
        if (latitude && longitude) {
          allPhotos.push({
            geometry: { type: 'Point', coordinates: [longitude, latitude] },
            properties: { id, latitude, longitude, photoHash, title },
            type: 'Feature'
          });
        }
        return allPhotos;
      },
      [] as Feature<Geometry, FeatureProperties>[]
    );
  }, [photos]);

  const [hoveredPhoto, setHoveredPhoto] = useState<FeatureProperties>();

  useEffect(() => {
    map?.on('mousemove', userPhotosId, e => {
      if (e.features) {
        setHoveredPhoto(e.features[0].properties as FeatureProperties);
      }
    });

    map?.on('mouseleave', userPhotosId, e => {
      setHoveredPhoto(undefined);
    });
  }, [map]);

  const popup = useMemo(() => {
    if (!hoveredPhoto) {
      return null;
    }

    const { id, latitude, longitude, photoHash, title } = hoveredPhoto;
    return (
      <Popup
        closeButton={false}
        closeOnClick={false}
        focusAfterOpen={false}
        latitude={latitude}
        longitude={longitude}
        maxWidth="none"
        offset={svgSizeWithoutShadow / 2 + SPACE_50}
      >
        <div className={styles.popup}>
          <img alt={title} src={getPhotoUrl({ id, photoHash }, PhotoSize.LargeSquare)} />
        </div>
      </Popup>
    );
  }, [hoveredPhoto]);

  if (!imageIsLoaded) {
    return null;
  }

  return (
    <>
      <Source type="geojson" data={{ type: 'FeatureCollection', features }}>
        <Layer
          id={userPhotosId}
          beforeId={getLayerBeforeId(map, userPhotosId)}
          type="symbol"
          layout={{ ...defaultSymbolLayerLayoutProps, 'icon-image': imageName }}
        />
      </Source>
      {popup}
    </>
  );
};

export default UserPhotos;
