import { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import type { AllTrailsResult } from '../../types/Results';
import type { PinImageKey } from '../../types/Images';
import { useSelector } from '../../redux';
import { getPinImage, getPinType } from '../../utils/images';
import { coordinatesToSerializedCoordinates } from '../../utils/serializedCoordinates';
import styles from './styles/styles.module.scss';

type Props = {
  result?: AllTrailsResult;
};

const SelectedPin = ({ result }: Props) => {
  const { resultIdsBySerializedCoordinates } = useSelector(state => ({
    resultIdsBySerializedCoordinates: state.map.resultIdsBySerializedCoordinates
  }));

  const trailheadCount = useMemo(() => {
    const resultCoordinates = result?._cluster_geoloc ? result?._cluster_geoloc : result?._geoloc;
    const serializedCoords = resultCoordinates && coordinatesToSerializedCoordinates(resultCoordinates);

    return serializedCoords ? resultIdsBySerializedCoordinates[serializedCoords]?.length : 0;
  }, [result, resultIdsBySerializedCoordinates]);

  const pinType = getPinType(result?.type);
  const minTrailheadCount = Math.min(trailheadCount, 9);
  const imageKey = trailheadCount <= 1 ? 'pinImageSelected' : (`pinTrailheadImageSelected${minTrailheadCount}` as PinImageKey);
  const pinImage = getPinImage(pinType, imageKey);

  return (
    <Marker anchor="bottom" latitude={result?._geoloc.lat as number} longitude={result?._geoloc.lng as number} offset={[0, 2]}>
      <div className={styles.pin}>
        <img className={styles.pinImage} src={pinImage} alt={trailheadCount === 1 ? 'selected pin' : 'selected trailhead'} />
      </div>
    </Marker>
  );
};

export default SelectedPin;
