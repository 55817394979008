import { COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/denali/tokens';
import { Layer, Source } from 'react-map-gl';
import { isProduction } from '@alltrails/core';
import { getPolygonFillId, getPolygonOutlineId } from '../../utils/layers';

type Props = {
  hoveredId?: string;
  beforeId: string;
  id: number;
};

const ParkBoundary = ({ hoveredId, beforeId, id }: Props) => {
  const production = isProduction();
  const namespace = production ? 'production' : 'alpha';
  if (!hoveredId) {
    return null;
  }
  const fillLayerId = getPolygonFillId(id);
  const lineLayerId = getPolygonOutlineId(id);

  return (
    <Source id={`park-areas-${namespace}`} type="vector" url={`mapbox://alltrails.park-areas-${namespace}`}>
      <Layer
        id={fillLayerId}
        type="fill"
        paint={{
          'fill-color': 'transparent'
        }}
        source-layer={`park-areas-${namespace}`}
        beforeId={beforeId}
      />
      <Layer
        beforeId={fillLayerId}
        id={lineLayerId}
        type="line"
        paint={{
          'line-color': COLOR_TEXT_PRIMARY_INVERSE,
          'line-width': 3
        }}
        source-layer={`park-areas-${namespace}`}
        filter={['==', ['get', 'AT_ID'], hoveredId]}
      />
    </Source>
  );
};

export default ParkBoundary;
