import { useCallback, useEffect } from 'react';
import { COLOR_PLUS_600 } from '@alltrails/denali/tokens';
import { Layer, Source } from 'react-map-gl';
import { MultiPolygon as MultiPolygonType } from 'geojson';
import useMap from '../../hooks/useMap';
import { getPolygonFillId, getPolygonOutlineId } from '../../utils/layers';

type Props = {
  boundaries: MultiPolygonType;
  lineColor?: string;
  fillColor?: string;
  id: number;
  isActive?: boolean;
  isMuted?: boolean;
  onUpdateActiveGeoBoundaryId?: (id?: number) => void;
};

const MultiPolygon = ({
  boundaries,
  lineColor = COLOR_PLUS_600,
  fillColor = COLOR_PLUS_600,
  id,
  isActive,
  isMuted,
  onUpdateActiveGeoBoundaryId
}: Props) => {
  const map = useMap();
  const fillLayerId = getPolygonFillId(id);
  const lineLayerId = getPolygonOutlineId(id);

  const setActiveGeoBoundaryId = useCallback(() => {
    if (onUpdateActiveGeoBoundaryId) {
      onUpdateActiveGeoBoundaryId(id);
    }
  }, [id, onUpdateActiveGeoBoundaryId]);

  const unsetActiveGeoBoundaryId = useCallback(() => {
    if (onUpdateActiveGeoBoundaryId) {
      onUpdateActiveGeoBoundaryId(undefined);
    }
  }, [onUpdateActiveGeoBoundaryId]);

  useEffect(() => {
    map?.on('mouseenter', fillLayerId, setActiveGeoBoundaryId);
    map?.on('mouseleave', fillLayerId, unsetActiveGeoBoundaryId);
    return () => {
      map?.off('mouseenter', fillLayerId, setActiveGeoBoundaryId);
      map?.off('mouseleave', fillLayerId, unsetActiveGeoBoundaryId);
    };
  }, [map, fillLayerId, setActiveGeoBoundaryId, unsetActiveGeoBoundaryId]);

  return (
    <Source
      id={`${id}-geo-boundary`}
      type="geojson"
      data={{
        type: 'Feature',
        properties: {
          id,
          type: 'geoBoundary'
        },
        geometry: boundaries
      }}
    >
      <Layer
        id={fillLayerId}
        type="fill"
        paint={{
          'fill-color': fillColor,
          'fill-opacity': isActive ? 0.5 : 0.15
        }}
      />
      {!isMuted && (
        <Layer
          beforeId={fillLayerId}
          id={lineLayerId}
          type="line"
          paint={{
            'line-color': lineColor,
            'line-width': isActive ? 5 : 3
          }}
        />
      )}
    </Source>
  );
};

export default MultiPolygon;
