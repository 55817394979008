import Icon from '../components/Icon';

const Compass = Icon(
  'compass',
  <>
    <path
      fill="#E9501E"
      d="M11.64.856 8.898 5.222c-.25.396.14.875.619.76l2.465-.588a.597.597 0 0 1 .277 0l2.465.589c.478.114.867-.365.619-.76L12.6.855a.578.578 0 0 0-.961 0Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.23 15.84h1.31V8.4h-1.406v5.088L10.079 8.4h-1.38l.001 7.44h1.406v-5.215l4.125 5.215Z" />
    <path
      fill="#DBDAD2"
      d="m12.6 23.384 2.742-4.366c.25-.396-.14-.875-.619-.76l-2.465.588a.597.597 0 0 1-.277 0l-2.465-.589c-.478-.114-.868.364-.619.76l2.742 4.367a.578.578 0 0 0 .961 0Z"
    />
  </>
);

export default Compass;
