import { get, post, put, destroy } from '@alltrails/shared/api';
import { GeoBoundaryTrailFilter, IntersectingTrail, Project, SearchResult } from '../types';

type ProjectResponse = {
  project: Project;
};

export type GetSearchResultsParams = {
  limit?: number;
  order: string;
  searchString: string;
};

export type PaginatedSearchResponse = {
  searchResults: SearchResult[];
  meta: {
    pageInfo: {
      itemCount: number;
      hasNextPage: boolean;
      nextCursor: string;
    };
  };
};

export type PaginatedProjectResponse = {
  projects: Project[];
  meta: {
    pageInfo: {
      itemCount: number;
      hasNextPage: boolean;
      nextCursor: string;
    };
  };
};

export type ProjectCreatePayload = {
  name: string;
  creatorId: number;
};

export type ProjectUpdatePayload = {
  id: number;
  name: string;
};

export type ProjectMovePayload = {
  id: number;
  projectId: number | null;
};

type IntersectingTrailsReponse = {
  intersectingTrails: IntersectingTrail[];
};

type GeoBoundaryTrailFilterResponse = {
  geoBoundaryTrailFilter: GeoBoundaryTrailFilter;
};

export type GeoBoundaryTrailFilterParams = {
  id?: number;
  assignmentId?: number;
  assignmentType?: string;
  trailHeadWithinBoundary?: boolean;
  percentage?: number;
  exclusionIds?: number[];
  name?: string;
  creatorId?: number;
  projectId?: number;
  boundaries?: string;
  createNewBoundary?: boolean;
};

export type SearchTrailsParams = {
  boundary: string;
};

export type TraillPolylinesResponse = {
  trailMaps: {
    trailId: number;
    polyline: string;
    trailData: string;
  }[];
};

export const searchRequest = ({ limit, order, searchString }: GetSearchResultsParams) => {
  const params = new URLSearchParams();

  params.append('limit', String(limit || 20));
  params.append('name', searchString);
  params.append('sort', order);

  return get<PaginatedSearchResponse>(`/api/alltrails/geo-service/search?${params.toString()}`);
};

export const searchProjectsRequest = (searchString: string) => {
  return get<PaginatedProjectResponse>(`/api/alltrails/geo-service/projects/search?name=${searchString}`);
};

export const getProjectRequest = (id: number) => {
  return get<ProjectResponse>(`/api/alltrails/geo-service/projects/${id}`);
};

export const createProjectRequest = (projectCreatePayload: ProjectCreatePayload) => {
  return post<ProjectResponse>(`/api/alltrails/geo-service/projects`, projectCreatePayload);
};

export const updateProjectRequest = (projectUpdatePayload: ProjectUpdatePayload) => {
  return put<ProjectResponse>(`/api/alltrails/geo-service/projects/${projectUpdatePayload.id}`, projectUpdatePayload);
};

export const deleteProjectRequest = (id: number) => {
  return destroy<{}>(`/api/alltrails/geo-service/projects/${id}`);
};

// TODO update this here and in the geo service
export const getParkGeoBoundaryTrailFilter = (id: number | string) => {
  return get<GeoBoundaryTrailFilterResponse>(`/api/alltrails/geo-service/geo/area/${id}/geo_boundary_trail_filters`);
};

export const getGeoBoundaryTrailFilterRequest = (id: number) => {
  return get<GeoBoundaryTrailFilterResponse>(`/api/alltrails/geo-service/geo_boundary_trail_filters/${id}`);
};

export const getGeoBoundaryTrailFilterTrailsRequest = (id: number) => {
  return get<IntersectingTrailsReponse>(`/api/alltrails/geo-service/geo_boundary_trail_filters/${id}/intersecting_trails`);
};

export const createGeoBoundaryTrailFilterRequest = (geoBoundaryTrailFilterParams: GeoBoundaryTrailFilterParams) => {
  return post<GeoBoundaryTrailFilterResponse>(`/api/alltrails/geo-service/geo_boundary_trail_filters`, geoBoundaryTrailFilterParams);
};

export const updateGeoBoundaryTrailFilterRequest = (geoBoundaryTrailFilterParams: GeoBoundaryTrailFilterParams) => {
  const { id, ...rest } = geoBoundaryTrailFilterParams;
  return put<GeoBoundaryTrailFilterResponse>(`/api/alltrails/geo-service/geo_boundary_trail_filters/${id}`, rest);
};

export const moveGeoBoundaryTrailFilterRequest = (projectMovePayload: ProjectMovePayload) => {
  const { id, projectId } = projectMovePayload;
  return put<GeoBoundaryTrailFilterResponse>(`/api/alltrails/geo-service/geo_boundary_trail_filters/${id}/projects`, { projectId });
};

export const searchTrailsRequest = (boundary_payload: SearchTrailsParams) => {
  return post<IntersectingTrailsReponse>(`/api/alltrails/geo-service/trail_maps/boundary_search`, boundary_payload);
};

export const deleteGeoBoundaryTrailFilterRequest = (id: number) => {
  return destroy<{}>(`/api/alltrails/geo-service/geo_boundary_trail_filters/${id}`);
};

export const getTrailPolylines = (trailIds: number[]) => {
  return post<TraillPolylinesResponse>(`/api/alltrails/geo-service/trail_maps/polylines`, { trailIds });
};
