import classNames from 'classnames';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import styles from './styles/styles.module.scss';

export type IconButtonGroupProps = {
  buttons: {
    disabled?: boolean;
    icon: IconDefinition<'orientation' | 'color'>;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    testId: string;
    title: string;
  }[];
  className?: string;
  orientation: 'horizontal' | 'vertical';
  testId: string;
};

export default function IconButtonGroup({ buttons, className, orientation, testId }: IconButtonGroupProps) {
  return (
    <div className={classNames(className, styles.container, styles[orientation])} data-testid={testId}>
      {buttons.map(({ disabled, icon, onClick, testId: buttonTestId, title }) => (
        <button
          key={title}
          className={styles.button}
          onClick={onClick}
          disabled={disabled}
          title={title}
          aria-label={title}
          data-testid={buttonTestId}
          type="button"
        >
          <IconRenderer icon={icon} defaults={{ color: 'currentColor' }} />
        </button>
      ))}
    </div>
  );
}
