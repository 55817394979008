import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';

/**
 * An extension of useState that exposes a ref object that has a copied value of the current state.
 *
 * State values are updated asynchronously, so referencing state values in JS callbacks potentially yields a stale state value.
 *
 * Reference `state` for any usage of the state in a dependency array, or in typical React flows where closures don't apply.
 * Reference `stateRef.current` for the most up-to-date value inside callbacks.
 * @param initialState initial state of generic type <T>
 * @returns [state value, state setter, state ref object]
 */
function useStateRef<T>(initialState: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const [state, setState] = useState<T>(initialState);
  const stateRef = useRef<T>(initialState);

  stateRef.current = state;

  return [state, setState, stateRef];
}

export default useStateRef;
