import { useEffect } from 'react';
import type { MapLayerMouseEvent, MapRef } from 'react-map-gl';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import type { SerializedCoordinates } from '../types/Results';
import { useDispatch } from '../redux';
import { clearHoveredCoordinates, updateActiveClusterId, updateHoveredCoordinates, updateIsShiftKeyPressedOnHover } from '../redux/reducer';

/**
 * useMapMouseMoveEvents registers user mousemove events with specific map
 * layers.
 */

type Args = {
  hoveredSerializedCoordinates?: SerializedCoordinates;
  layerIds: string[];
  map?: MapRef;
  updateCursor: (cursor: string) => void;
  allowClickEvents?: boolean;
};

export default function useMapMouseMoveEvents({ hoveredSerializedCoordinates, layerIds, map, updateCursor, allowClickEvents }: Args) {
  const dispatch = useDispatch();
  const isMobile = useIsMobileSizedScreen();

  useEffect(() => {
    if (!map || !allowClickEvents) {
      return;
    }

    const onMouseMove = (event: MapLayerMouseEvent) => {
      dispatch(updateIsShiftKeyPressedOnHover(Boolean(event.originalEvent?.shiftKey)));
      const features = map?.queryRenderedFeatures(event.point, { layers: layerIds });
      const firstFeature = features?.[0];

      if (firstFeature?.properties?.cluster_id) {
        dispatch(updateActiveClusterId(firstFeature?.properties?.cluster_id));

        updateCursor('pointer');
      } else if (firstFeature?.properties?.serializedCoordinates) {
        const isStillHoveringLastFeature = features.some(feature => feature?.properties?.serializedCoordinates === hoveredSerializedCoordinates);

        if (isStillHoveringLastFeature) {
          return;
        }

        updateCursor('pointer');
        const serializedCoordinates = firstFeature?.properties?.serializedCoordinates;

        dispatch(updateHoveredCoordinates({ coordinates: serializedCoordinates, index: 0 }));
      } else {
        dispatch(clearHoveredCoordinates());
        updateCursor('grab');
      }
    };

    if (!isMobile) {
      map?.on('mousemove', onMouseMove);
    }

    return () => {
      map?.off('mousemove', onMouseMove);
    };
  }, [map, dispatch, hoveredSerializedCoordinates, isMobile, layerIds, updateCursor, allowClickEvents]);
}
