'use client';

import { useUser } from '@alltrails/context';
import { useSearchParams } from 'next/navigation';
import PlusCta from '../PlusCta';

const LocationPageHeader = () => {
  const user = useUser();
  const searchParams = useSearchParams();

  const renderPlusCta = searchParams.get('offer-trial') === 'true' && !user?.pro;
  return renderPlusCta ? <PlusCta /> : null;
};

export default LocationPageHeader;
