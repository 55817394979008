// exprssionIsTrailhead is 'true' if the marker has more than one result.
const expressionIsTrailhead = () => ['>', ['get', 'resultsCount'], 1];

// expressionEqualsCoordinates returns true if the coordinates passed in match
// the serializedCoordinates property of a marker.
export const expressionEqualsCoordinates = coordinates => ['==', ['get', 'serializedCoordinates'], coordinates || null];

// expressionIsHoveredOrClicked returns true if either the hovered OR clicked coordinates match
// the serializedCoordinates property of a marker.
export const expressionIsHoveredOrClicked = (hoveredCoordinates, clickedCoordinates) => [
  'any',
  expressionEqualsCoordinates(hoveredCoordinates),
  expressionEqualsCoordinates(clickedCoordinates)
];

// expressionIsNotSelectedTrail returns true if the coordinates are not hovered or clicked
// and the marker is a trail
export const expressionIsNotSelectedTrail = (hoveredCoordinates, clickedCoordinates) => [
  'all',
  ['!', expressionIsHoveredOrClicked(hoveredCoordinates, clickedCoordinates)],
  ['!', expressionIsTrailhead()]
];

// expressionEqualsSelectedTrail returns true if either the hovered OR clicked coordinates match
// the serializedCoordinates property of a marker **AND** the marker is a trail
export const expressionIsSelectedTrail = (hoveredCoordinates, clickedCoordinates) => [
  'all',
  expressionIsHoveredOrClicked(hoveredCoordinates, clickedCoordinates)
];

// expressionIsNotSelectedTrailhead returns true if either the hovered OR clicked coordinates DON'T
// match the serializedCoordinates property of a marker **AND** the marker is a trailhead
export const expressionIsNotSelectedTrailhead = (hoveredCoordinates, clickedCoordinates) => [
  'all',
  ['!', expressionIsHoveredOrClicked(hoveredCoordinates, clickedCoordinates)],
  expressionIsTrailhead()
];

// expressionIsSelectedTrailhead returns true if either the hovered OR clicked coordinates match
// the serializedCoordinates property of a marker, **AND** the marker is a trailhead, **AND**
// isMobile is false
export const expressionIsSelectedTrailhead = (hoveredCoordinates, clickedCoordinates, isMobile) => [
  'all',
  expressionIsHoveredOrClicked(hoveredCoordinates, clickedCoordinates),
  expressionIsTrailhead(),
  !isMobile
];

// expressionAggregateResultsCount is intended for use on a Source's
// clusterProperties prop. This allows us to aggregate the resultsCount of all
// child markers.
export const expressionAggregateResultsCount = () => ['+', ['get', 'resultsCount']];
