import { getPinImages } from '../../utils/images';

// To be imported by any components using this component and passed as BaseMap props to prevent race conditions between Mapbox image and layer loading
const images = getPinImages([
  'pinImage',
  'pinTransparent',
  'pinTrailheadImage1',
  'pinTrailheadImage2',
  'pinTrailheadImage3',
  'pinTrailheadImage4',
  'pinTrailheadImage5',
  'pinTrailheadImage6',
  'pinTrailheadImage7',
  'pinTrailheadImage8',
  'pinTrailheadImage9',
  'pinTrailheadTransparent',
  'clusterImage',
  'clusterImageSelected'
]);

export default images;
