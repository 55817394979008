/* eslint-disable @next/next/no-img-element */
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { useCallback, useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';
import { AlgoliaHit } from '@alltrails/search/types/algoliaResultTypes';
import { getAdminPinImage } from '../../utils/images';
import { LngLat } from '../../types/Geo';
import { AdminPinType } from '../../types/Images';
import useHoveredAdminResult from '../../hooks/useHoveredAdminResult';
import { generateSpiderLegParams, popupOffsetForSpiderLeg, SpiderLeg } from './utils';
import styles from './styles/styles.module.scss';

type Props = {
  coordinates: LngLat;
  features: Feature<Geometry, GeoJsonProperties>[];
  onPinHover?: (feature: AlgoliaHit | undefined) => void;
  type?: AdminPinType;
};

const ClusterLegs = ({ coordinates, features, onPinHover, type = 'trail' }: Props) => {
  const hoveredAdminResult = useHoveredAdminResult();
  const [spiderLegs, setSpiderLegs] = useState<undefined | SpiderLeg[]>(undefined);
  const pinImage = getAdminPinImage(type, 'pinImage');
  const activePinImage = getAdminPinImage(type, 'pinImageSelected');

  useEffect(() => {
    const legs = generateSpiderLegParams(features);
    setSpiderLegs(legs);
  }, [features]);

  const onPinMouseOver = useCallback(
    (leg: SpiderLeg | undefined) => {
      const [lng, lat] = coordinates;
      const feature = leg?.properties ? { ...leg.properties, _geoloc: { lat, lng }, popupOffset: popupOffsetForSpiderLeg(leg) } : undefined;
      onPinHover?.(feature);
    },
    [onPinHover, coordinates]
  );

  return spiderLegs ? (
    <>
      {spiderLegs.reverse().map(spiderLeg => (
        <Marker
          key={spiderLeg.index}
          longitude={coordinates[0]}
          latitude={coordinates[1]}
          style={{
            marginLeft: `${spiderLeg.x}px`,
            marginTop: `${spiderLeg.y}px`,
            width: '1px',
            height: '1px',
            overflow: 'visible',
            willChange: 'transform'
          }}
        >
          <div
            className={classNames(styles.spiderLegLine, { [styles.active]: hoveredAdminResult?.objectID === spiderLeg.properties.objectID })}
            style={{ height: `${spiderLeg.legLength}px`, transform: `rotate(${spiderLeg.angle - Math.PI / 2}rad)` }}
          />

          <div
            className={styles.spiderLegPin}
            onMouseOver={() => onPinMouseOver?.(spiderLeg)}
            onFocus={() => onPinMouseOver?.(spiderLeg)}
            onMouseOut={() => onPinMouseOver?.(undefined)}
            onBlur={() => onPinMouseOver?.(undefined)}
          >
            <img
              className={styles.pinImage}
              src={hoveredAdminResult?.objectID === spiderLeg.properties.objectID ? activePinImage : pinImage}
              alt=""
            />
          </div>
        </Marker>
      ))}
    </>
  ) : null;
};

export default ClusterLegs;
