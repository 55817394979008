import { useCallback, useMemo } from 'react';
import { useUser } from '@alltrails/context';
import { useLanguageRegionCode } from '@alltrails/language';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import logMapBaseLayerTapped from '@alltrails/analytics/events/logMapBaseLayerTapped';
import logMapOverlayTapped from '@alltrails/analytics/events/logMapOverlayTapped';
import MapLocation from '@alltrails/analytics/enums/MapLocation';
import MapOverlay from '@alltrails/analytics/enums/MapOverlay';
import MapBaseLayer from '@alltrails/analytics/enums/MapBaseLayer';
import { StyleId } from '../types/Styles';
import { baseStyleCardConfigs, dynamicStyleCardConfigs, staticStyleCardConfigs } from '../utils/styleCardConfigs';
import { useDispatch, useSelector } from '../redux';
import { toggleDynamicStyleId, toggleStaticStyleId, updateBaseStyleId } from '../redux/reducer';
import adminUrlIsValid from '../utils/adminUrlIsValid';
import { isBaseStyle, isDynamicStyle, isStaticStyle } from '../utils/styleIdHelpers';
import { getMapBaseLayer, getMapOverlay } from '../utils/amplitudeHelpers';

type UseStyleSelectionArgs = {
  onMapBaseLayerTapped?: (baseLayer: MapBaseLayer | undefined) => void;
  onMapOverlayTapped?: (overlay: MapOverlay | undefined, isAddition: boolean) => void;
  disableAnalytics?: boolean;
};

const useStyleSelection = ({ onMapBaseLayerTapped, onMapOverlayTapped, disableAnalytics }: UseStyleSelectionArgs = {}) => {
  const dispatch = useDispatch();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const { adminStyleSettings, baseStyleId, dynamicStyleIds, staticStyleIds } = useSelector(state => ({
    adminStyleSettings: state.map.adminStyleSettings,
    baseStyleId: state.map.baseStyleId,
    dynamicStyleIds: state.map.dynamicStyleIds,
    staticStyleIds: state.map.staticStyleIds,
    is3dActive: state.map.is3dActive
  }));

  const onStyleSelect = useCallback(
    (id: StyleId) => {
      const returnToUrl = window.location.pathname + window.location.search;
      if (isBaseStyle(id)) {
        if (id === baseStyleId) {
          // Do nothing if base style has already been selected
          return;
        }
        if (baseStyleCardConfigs[id].requiresAdminStyleSourceUrl && !adminUrlIsValid(adminStyleSettings?.styleSourceUrls?.[id])) {
          return;
        }

        // This gets added onto the returnToUrl, but I don't think we actually do anything with that...
        // we can probably get rid of this once we no longer are using the monorepo's modalRoadblock function
        const mapLayerRef = `map-layer-${id}`;
        if (!user) {
          modalRoadblock('signup', mapLayerRef, returnToUrl, languageRegionCode);
          return;
        }

        const mapBaseLayer = getMapBaseLayer(id);
        if (mapBaseLayer && !disableAnalytics) {
          logMapBaseLayerTapped({ map_base_layer: mapBaseLayer });
        }

        if (mapBaseLayer && onMapBaseLayerTapped) {
          onMapBaseLayerTapped(mapBaseLayer);
        }

        dispatch(updateBaseStyleId(id));
      } else if (
        isStaticStyle(id) &&
        staticStyleCardConfigs[id].requiresAdminStyleSourceUrl &&
        !adminUrlIsValid(adminStyleSettings?.styleSourceUrls?.[id])
      ) {
        return;
      } else {
        // This gets added onto the returnToUrl, but I don't think we actually do anything with that...
        // we can probably get rid of this once we no longer are using the monorepo's modalRoadblock function
        const mapOverlayRef = `map-overlay-${id}`;
        const returnToUrl = window.location.pathname + window.location.search;

        if (isStaticStyle(id)) {
          const config = staticStyleCardConfigs[id];
          if (config.requiresPlus && !user?.pro) {
            modalRoadblock('plus', mapOverlayRef, returnToUrl, languageRegionCode);
            return;
          }

          if (!disableAnalytics) {
            logMapOverlayTapped({
              is_addition: !staticStyleIds.includes(id),
              map_location: '' as MapLocation, // This enum hasn't been updated in a while and doesn't have entries for any of the mugen usages, but it required
              map_overlay: getMapOverlay(id) ?? ('' as MapOverlay)
            });
          }

          if (onMapOverlayTapped) {
            onMapOverlayTapped(getMapOverlay(id), !staticStyleIds.includes(id));
          }

          dispatch(toggleStaticStyleId(id));
        } else if (isDynamicStyle(id)) {
          const config = dynamicStyleCardConfigs[id];
          if (config.requiresPlus && !user?.pro) {
            modalRoadblock('plus', mapOverlayRef, returnToUrl, languageRegionCode);
            return;
          }

          if (!disableAnalytics) {
            logMapOverlayTapped({
              is_addition: !dynamicStyleIds.includes(id),
              map_location: '' as MapLocation, // This enum hasn't been updated in a while and doesn't have entries for any of the mugen usages, but it required
              map_overlay: getMapOverlay(id) ?? ('' as MapOverlay)
            });
          }

          if (onMapOverlayTapped) {
            onMapOverlayTapped(getMapOverlay(id), !dynamicStyleIds.includes(id));
          }

          dispatch(toggleDynamicStyleId(id));
        }
      }
    },
    [
      adminStyleSettings,
      baseStyleId,
      dispatch,
      dynamicStyleIds,
      languageRegionCode,
      staticStyleIds,
      user,
      onMapBaseLayerTapped,
      onMapOverlayTapped,
      disableAnalytics
    ]
  );

  return useMemo(
    () => ({
      onStyleSelect
    }),
    [onStyleSelect]
  );
};

export default useStyleSelection;
