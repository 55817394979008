import { get } from '@alltrails/shared/api';
import Waypoint from '../types/Waypoint';

export async function getMapWaypoints(mapId: number): Promise<Waypoint[]> {
  const response = await get<{ maps: (unknown & { waypoints: Waypoint[] })[] }>(`/api/alltrails/maps/${mapId}`, { params: { detail: 'deep' } });
  return response.maps[0].waypoints || [];
}

export async function getTrailWaypoints(trailId: number): Promise<Waypoint[]> {
  const response = await get<{ waypoints: Waypoint[] }>(`/api/alltrails/v2/trails/${trailId}/waypoints`);
  return response.waypoints || [];
}
