import { useSelector } from '../../redux';
import Polyline from '../Polyline';
import { useGetPolylineQuery } from '../../redux/mapsApi';

const ActiveTrailPolyline = ({ trailId }: { trailId: number }) => {
  const { data } = useGetPolylineQuery({ trailId });

  if (!data) {
    return null;
  }

  return <Polyline coordinates={data} uniqueId={String(trailId)} />;
};

const ActiveTrailPolylines = () => {
  const { activeTrails } = useSelector(state => ({ activeTrails: state.map.activeTrails }));

  if (!activeTrails) {
    return null;
  }

  return (
    <>
      {activeTrails.map(({ ID }) => {
        return <ActiveTrailPolyline key={ID} trailId={ID} />;
      })}
    </>
  );
};

export default ActiveTrailPolylines;
