import { type MapRef } from 'react-map-gl';
import { point } from '@turf/helpers';
import { buffer } from '@turf/buffer';
import bbox from '@turf/bbox';
import type { AllTrailsResult } from '../types/Results';
import { LngLat, SwNeBounds } from '../types/Geo';
import { pointItemToLngLat } from './legacyGeoJSONConversions';

function extendBounds(bounds: [number, number, number, number], lngLat: [number, number]) {
  const updatedBounds: [number, number, number, number] = [
    Math.min(bounds?.[0], lngLat[0]),
    Math.min(bounds?.[1], lngLat[1]),
    Math.max(bounds?.[2], lngLat[0]),
    Math.max(bounds?.[3], lngLat[1])
  ];
  return updatedBounds;
}

export default function fitBoundsOnLoad(results: AllTrailsResult[], mapRef: MapRef, duration?: number, padding?: number, maxZoom?: number) {
  const initialLngLat = pointItemToLngLat(results?.[0]);

  if (results?.length === 1 && maxZoom) {
    const center = initialLngLat as LngLat;
    const buffered = buffer(point(center), 3, { units: 'kilometers' });
    mapRef.fitBounds(bbox(buffered) as SwNeBounds, {
      duration: duration || 0,
      padding: padding || 20,
      linear: true,
      maxZoom
    });
  } else {
    let bounds: [number, number, number, number] | null = initialLngLat
      ? [initialLngLat[0], initialLngLat[1], initialLngLat[0], initialLngLat[1]]
      : null;

    if (bounds) {
      results?.forEach(result => {
        const lngLat = pointItemToLngLat(result);
        if (lngLat && bounds) {
          bounds = extendBounds(bounds, lngLat);
        }
      });

      mapRef.fitBounds(bounds, {
        duration: duration || 0,
        padding: padding || 20,
        linear: true,
        ...(maxZoom && { maxZoom })
      });
    }
  }
}
