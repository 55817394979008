/**
 * Wraps the unit string in a span so that it can be styled differently than the numeric value
 * @param parts Array of formatted parts, the return of formatNumberToParts
 * @param unitSpace Include a space before the unit
 * @returns A fragment with just the unit wrapped in a span
 */
export const wrapFormattedPartsUnitInSpan = (parts: Intl.NumberFormatPart[], unitSpace?: boolean) => {
  const filteredParts = parts.filter(part => part.value !== ' ');
  const unitIndex = filteredParts.findIndex(part => part.type === 'unit');
  if (unitIndex === -1) {
    return parts.map(part => part.value).join('');
  }
  return (
    <>
      {filteredParts
        .slice(0, unitIndex)
        .map(part => part.value)
        .join('')}
      <span>
        {unitSpace && ' '}
        {filteredParts[unitIndex].value}
      </span>
      {filteredParts
        .slice(unitIndex + 1)
        .map(part => part.value)
        .join('')}
    </>
  );
};
