import { Popup } from 'react-map-gl';
import TrailCard from '@alltrails/shared/components/TrailCard';
import { useLanguageRegionCode } from '@alltrails/language';
import { trailFromAlgoliaTrail } from '@alltrails/trails';
import { SPACE_100 } from '@alltrails/denali/tokens';
import { TrailSearchResult } from '@alltrails/search/types/algoliaResultTypes';
import { useSelector } from '../../redux';
import { pinMarkerHeight, pinMarkerShadowShadowHeight } from '../../utils/svgMarkerComponents';

const offset = pinMarkerHeight - pinMarkerShadowShadowHeight + SPACE_100;

type ActiveTrailPopupProps = {
  isComplete: (id: number) => boolean;
  isFavorite: (id: number) => boolean;
  isVerified: (id: number) => boolean;
  onFavoriteClick: (id: number) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  trails: TrailSearchResult[];
};

const ActiveTrailPopup = ({ isComplete, isFavorite, isVerified, onFavoriteClick, onMouseEnter, onMouseLeave, trails }: ActiveTrailPopupProps) => {
  const languageRegionCode = useLanguageRegionCode();
  const { displayMetric } = useSelector(state => ({ displayMetric: state.context.displayMetric }));

  if (!trails.length) {
    return null;
  }

  const activeTrail = trails[0];

  const coordinates = activeTrail._cluster_geoloc ?? activeTrail._geoloc;
  if (!coordinates) {
    return null;
  }

  return (
    <Popup
      closeButton={false}
      closeOnClick={false}
      focusAfterOpen={false}
      latitude={coordinates.lat}
      longitude={coordinates.lng}
      maxWidth="none"
      offset={offset}
    >
      <TrailCard
        variant="small"
        languageRegionCode={languageRegionCode}
        onCardMouseEnter={onMouseEnter}
        onCardMouseLeave={onMouseLeave}
        onFavoriteClick={() => onFavoriteClick(activeTrail.ID)}
        isFavorite={isFavorite(activeTrail.ID)}
        isCompleted={isComplete(activeTrail.ID)}
        isVerified={isVerified(activeTrail.ID)}
        trail={trailFromAlgoliaTrail(activeTrail)}
        useMetric={displayMetric}
        lazyLoadImages={true}
        openInNewTab
      />
    </Popup>
  );
};

export default ActiveTrailPopup;
