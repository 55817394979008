import { CSSProperties, useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactMapGL, { type MapRef, AttributionControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useIntl } from 'react-intl';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import { getMapboxAccessToken } from '@alltrails/core';
import { useLanguageRegionCode } from '@alltrails/language';
import type { AllTrailsBounds, AllTrailsCenter } from '../../types/Geo';
import StaticStyle from '../StaticStyle';
import { defaultSupportedDynamicStyleIds, mapId, maxPitch, maxPitchFor3dTerrain } from '../../utils/constants';
import getInitialViewState from '../../utils/getInitialViewState';
import use3dMap from '../../hooks/use3dMap';
import { Images } from '../../types/Images';
import useBaseStyle from '../../hooks/useBaseStyle';
import AtmosphereStyle from '../AtmosphereStyle';
import { useDispatch, useSelector } from '../../redux';
import DynamicStyleOverlays from '../DynamicStyleOverlays';
import { updateSupportedDynamicStyleIds } from '../../redux/reducer';
import { DynamicStyleId } from '../../types/Styles';
import { onMoveEndHandler, onStyleDataHandler } from './eventHandlers';
import styles from './styles/styles.module.scss';

type ControlsConfig = Pick<
  React.ComponentProps<typeof ReactMapGL>,
  'dragPan' | 'dragRotate' | 'scrollZoom' | 'touchZoomRotate' | 'touchPitch' | 'keyboard' | 'doubleClickZoom' | 'boxZoom' | 'interactive'
>;

type Props = {
  bottomPadding?: number; // Used when the map is being slightly covered by something like the top of a mobile drawer
  className?: string;
  children?: React.ReactNode;
  dimensionsTheme?: 'default' | 'compact';
  ignoreInitial3dPitch?: boolean;
  initialBounds?: AllTrailsBounds;
  initialCenter?: AllTrailsCenter;
  onMoveEnd?: ({ center, bounds, zoom }) => void;
  preventReuseMaps?: boolean;
  controlsConfig?: ControlsConfig;
  handleOnLoad?: () => void;
  // The images of any children of BaseMap. These are used in onStyleDataHandler and must be loaded here to avoid race conditions between Mapbox image and layer loading.
  // https://docs.mapbox.com/mapbox-gl-js/example/add-image
  images?: Images; // Some images have been transitioned to a new svg based pattern - see CuratedWaypoints or StartEndMarker for examples
  supportedDynamicStyleIds?: DynamicStyleId[];
  overlaysExcludedTrailIds?: number[];
} & Pick<
  React.ComponentProps<typeof ReactMapGL>,
  'cooperativeGestures' | 'onDrag' | 'onTouchMove' | 'onZoomEnd' | 'onDragEnd' | 'onTouchEnd' | 'padding'
>;

/**
 * BaseMap represents our standard customization around our 3rd party map
 * vendor's Map API. We set our typical settings, add our controls, and handle
 * complex functionality specific to maps here and in the appropriate
 * sub-components.
 *
 * @param {props}: Props
 * @returns
 */

const BaseMap = ({
  bottomPadding = 0,
  children,
  className,
  dimensionsTheme = 'default',
  ignoreInitial3dPitch,
  initialBounds,
  initialCenter,
  preventReuseMaps = false,
  onMoveEnd,
  onDrag,
  onTouchMove,
  onZoomEnd,
  onDragEnd,
  onTouchEnd,
  padding,
  cooperativeGestures = false,
  controlsConfig,
  handleOnLoad,
  images = {},
  supportedDynamicStyleIds = defaultSupportedDynamicStyleIds,
  overlaysExcludedTrailIds
}: Props) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const mapboxAccessToken = getMapboxAccessToken();
  const { cursor, is3dActive, staticStyleIds } = useSelector(state => ({
    cursor: state.map.cursor,
    is3dActive: state.map.is3dActive,
    staticStyleIds: state.map.staticStyleIds
  }));

  const baseMapStyle = useBaseStyle();
  const { styles: map3DBaseStyles } = use3dMap(is3dActive, ignoreInitial3dPitch);
  const [mapRef, setMapRef] = useState<MapRef>();
  const [isStyleDataInitialized, setIsStyleDataInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (supportedDynamicStyleIds) {
      dispatch(updateSupportedDynamicStyleIds(supportedDynamicStyleIds));
    }
  }, [dispatch, supportedDynamicStyleIds]);

  useEffect(() => {
    const resizer = new ResizeObserver(() => mapRef?.resize());
    const container = mapRef?.getContainer();
    if (container) {
      resizer.observe(container);
    }

    return () => {
      resizer.disconnect();
    };
  }, [mapRef]);

  return (
    <div
      className={classNames(styles.map, className)}
      style={{ '--map-bottom-padding': `${bottomPadding}px` } as CSSProperties}
      data-at-theme-dimensions={dimensionsTheme}
    >
      {/* don't render the map until we have our base styles from mapbox 
      we need the glyphs from the style in order to render the cluster marker text
      */}
      {baseMapStyle && (
        <ReactMapGL
          id={mapId}
          ref={mapboxRef => {
            if (!mapboxRef || mapboxRef === mapRef) {
              return;
            }

            setMapRef(mapboxRef);
          }}
          cursor={cursor}
          onLoad={handleOnLoad}
          initialViewState={getInitialViewState(initialBounds, initialCenter)}
          mapboxAccessToken={mapboxAccessToken}
          mapStyle={baseMapStyle}
          onMoveEnd={({ viewState }) => onMoveEndHandler(viewState, mapRef, onMoveEnd)}
          onTouchMove={onTouchMove} // Called when the map is moved via touch (mobile)
          onDrag={onDrag} // Called when the map is moved via drag (desktop)
          onDragEnd={onDragEnd}
          onZoomEnd={onZoomEnd}
          onTouchEnd={onTouchEnd}
          onStyleData={() => onStyleDataHandler(images, mapRef, isStyleDataInitialized, setIsStyleDataInitialized)}
          padding={padding}
          reuseMaps={!preventReuseMaps}
          {...map3DBaseStyles}
          maxPitch={is3dActive ? maxPitchFor3dTerrain : maxPitch}
          attributionControl={false}
          cooperativeGestures={cooperativeGestures}
          {...(controlsConfig || {})}
        >
          {staticStyleIds.map(styleId => (
            <StaticStyle key={styleId} styleId={styleId} />
          ))}
          {is3dActive && <AtmosphereStyle />}
          <DynamicStyleOverlays excludedTrailIds={overlaysExcludedTrailIds} />
          <AttributionControl
            customAttribution={`<strong><a href="${getHelpCenterUrl(
              languageRegionCode,
              11555324555924
            )}" target="_blank"> ${intl.formatMessage({ defaultMessage: 'Map legend' })} </a></strong>`}
          />

          {/* Render children only after the style data has been initialized to prevent pop-in and warnings for missing images */}
          {isStyleDataInitialized && children}
        </ReactMapGL>
      )}
    </div>
  );
};

export default BaseMap;
