import { TrailHit, TrackHit, MapHit } from '@alltrails/search/types/algoliaResultTypes';
import { Area } from '@alltrails/shared/types/area';
import { Trail, TrailPhoto } from '@alltrails/shared/types/trail';
import { TrackId } from '@alltrails/shared/types/track';
import { Activity, ActivityType } from '@alltrails/shared/types/activity';
import { Map } from '@alltrails/shared/types/map';
import { UserId } from '@alltrails/shared/types/User';

export const UNITED_STATES_ID = 313;
export const HAWAII_ID = 366;

export type AdminTrackHit = TrackHit & { created_at: any; activities: ActivityType[] };
export type AdminMapHit = MapHit & { created_at: any; activities: ActivityType[] };

export const trailHitToTrail = (hit: TrailHit): Trail => ({
  id: hit.ID,
  name: hit.name,
  slug: hit.slug ? hit.slug.replace('trail/', '') : '',
  area: { name: getLocationName(hit), slug: getLocationSlug(hit) } as Area,
  avgRating: hit.avg_rating || 0,
  defaultActivityStats: { difficulty: Number(hit.difficulty_rating), rating: hit.avg_rating },
  defaultPhoto: hit.has_profile_photo ? ({} as TrailPhoto) : undefined,
  trailCounts: { reviewCount: hit.num_reviews || 0 },
  trailDetail: { description: hit.description },
  trailGeoStats: { durationMinutes: hit.duration_minutes?.toString() || '0', length: hit.length || 0 }
});

export const trackHitToActivity = (track: AdminTrackHit): Activity => ({
  id: track.ID as TrackId,
  slug: track.slug,
  name: track.name,
  minutes: track.duration_minutes || 0, // TODO look into getting the proper value for this based on activity
  meters: track.length || 0,
  elevationGain: track.elevation_gain || 0,
  activityType: (track.activities?.length ? track.activities[0] : '') as ActivityType,
  user: { id: 0 as UserId, name: `${track.user?.first_name} ${track.user?.last_name}`, slug: track.user?.slug || '' },
  createdAt: typeof track.created_at === 'string' ? track.created_at : track.created_at.toUTCString(),
  hasPhotos: !!track.photo_count
});

export const mapHitToMap = (map: AdminMapHit): Map => ({
  id: map.ID as TrackId,
  slug: map.slug,
  name: map.name,
  minutes: map.duration_minutes || 0, // TODO look into getting the proper value for this based on activity
  meters: map.length || 0,
  user: { id: 0 as UserId, name: `${map.user?.first_name} ${map.user?.last_name}`, slug: map.user?.slug || '' },
  hasPhotos: !!map.photo_count,
  locationName: getLocationName(map),
  locationSlug: getLocationSlug(map)
});

const isValidString = (str: string): boolean => !!str && str.length > 0;

const getLocationName = result => {
  const localizedAreaName = result.area_name;
  if (isValidString(localizedAreaName)) {
    return localizedAreaName;
  } else if (isValidString(result.park_name)) {
    return result.park_name;
  }
  const cityName = isValidString(result.city_name) ? result.city_name : '';
  const stateName = isValidString(result.state_name) ? result.state_name : '';
  const countryName =
    isValidString(result.country_name) &&
    result.country_id !== UNITED_STATES_ID &&
    result.country_id !== HAWAII_ID &&
    // Maps do not have a country_id attribute
    !result.country_name.includes('United States') &&
    !result.country_name.includes('Hawaii')
      ? result.country_name
      : '';
  return [cityName, stateName, countryName].filter(isValidString).join(', ');
};

const getLocationSlug = result => {
  if (result.park_slug) {
    return `parks/${result.park_slug}`;
  }
  return result.area_slug || result.city_url || '';
};
