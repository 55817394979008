import { useCallback, useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { Feature, Point } from 'geojson';
import { COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/denali/tokens';
import { useGroupHover } from '@alltrails/core';
import { useReduxListItems } from '@alltrails/lists';
import {
  defaultPinLayerLayoutProps,
  defaultPinLayerPaintProps,
  defaultSymbolLayerLayoutProps,
  getLayerBeforeId,
  trailPinsId
} from '../../utils/layers';
import { useDispatch, useSelector } from '../../redux';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import { pinMarkerHeight, pinMarkerWidth } from '../../utils/svgMarkerComponents';
import { updateActiveSerializedTrailCoordinates, updateCursor } from '../../redux/reducer';
import trailPinSvg from './trailPinSvg';
import trailPinActiveSvg from './trailPinActiveSvg';
import multiTrailPinSvg from './multiTrailPinSvg';
import multiTrailPinActiveSvg from './multiTrailPinActiveSvg';
import TrailResultProperties from './TrailResultProperties';
import ActiveTrailPopup from './ActiveTrailPopup';

const trailPinImageName = 'trail-pin';
const trailPinActiveImageName = 'trail-pin-active';
const multiTrailPinImageName = 'multi-trail-pin';
const multiTrailPinActiveImageName = 'multi-trail-pin-active';

const isMultiTrailExpression = ['>', ['get', 'trailCount'], 1];

export type TrailPinsProps = {
  sourceId: string;
};

const TrailPins = ({ sourceId }: TrailPinsProps) => {
  const map = useMap();
  const dispatch = useDispatch();
  const { activeSerializedTrailCoordinates, allowClickEvents, trailResults } = useSelector(state => ({
    activeSerializedTrailCoordinates: state.map.activeSerializedTrailCoordinates,
    allowClickEvents: state.map.allowClickEvents,
    trailResults: state.map.trailResults
  }));

  const { isComplete, isFavorite, isVerified, handleFavoriteClick, listModal, signUpModal } = useReduxListItems();

  const trailPinImageIsLoaded = useSvgImage(trailPinImageName, trailPinSvg, pinMarkerWidth, pinMarkerHeight);
  const trailPinActiveImageIsLoaded = useSvgImage(trailPinActiveImageName, trailPinActiveSvg, pinMarkerWidth, pinMarkerHeight);
  const multiTrailPinImageIsLoaded = useSvgImage(multiTrailPinImageName, multiTrailPinSvg, pinMarkerWidth, pinMarkerHeight);
  const multiTrailPinActiveImageIsLoaded = useSvgImage(multiTrailPinActiveImageName, multiTrailPinActiveSvg, pinMarkerWidth, pinMarkerHeight);

  const onHover = useCallback(
    (properties?: TrailResultProperties) => {
      // We don't bother passing in properties when a user hovers on the trail card, since no changes need to be dispatched in that case
      if (properties) {
        // We must use the serialized coordinates that we assigned to the point rather than constructing the string from the coordinates
        // on the mapbox event because for some (still unknown) reason, the mapbox coordinates were the slightest bit off...
        const serializedCoordinates = properties.serializedCoordinates;
        dispatch(updateActiveSerializedTrailCoordinates(serializedCoordinates));

        // Multi-trail markers have on-click behavior
        dispatch(updateCursor(properties.trailCount > 1 ? 'pointer' : 'auto'));
      }
    },
    [dispatch]
  );

  const onHoverEnd = useCallback(() => {
    dispatch(updateActiveSerializedTrailCoordinates(undefined));
    dispatch(updateCursor('unset'));
  }, [dispatch]);

  const { onMouseEnter, onMouseLeave } = useGroupHover(onHover, onHoverEnd, true);

  useEffect(() => {
    if (!allowClickEvents) {
      return;
    }

    map?.on('mousemove', trailPinsId, e => {
      if (onMouseEnter && e.features) {
        const marker = e.features[0] as unknown as Feature<Point, TrailResultProperties>;
        onMouseEnter(marker.properties);
      }
    });

    map?.on('mouseleave', trailPinsId, () => {
      onMouseLeave?.();
    });
  }, [allowClickEvents, dispatch, map, onMouseEnter, onMouseLeave]);

  if (!trailPinImageIsLoaded || !trailPinActiveImageIsLoaded || !multiTrailPinImageIsLoaded || !multiTrailPinActiveImageIsLoaded) {
    return null;
  }

  const isActiveExpression = ['==', ['get', 'serializedCoordinates'], activeSerializedTrailCoordinates || ''];

  return (
    <>
      <Layer
        source={sourceId}
        type="symbol"
        id={trailPinsId}
        beforeId={getLayerBeforeId(map, trailPinsId)}
        filter={['!', ['has', 'point_count']]}
        layout={{
          ...defaultSymbolLayerLayoutProps,
          ...defaultPinLayerLayoutProps,
          'icon-image': [
            'case',
            // Active and multi-trail
            ['all', isActiveExpression, isMultiTrailExpression],
            multiTrailPinActiveImageName,
            // In-active and multi-trail
            isMultiTrailExpression,
            multiTrailPinImageName,
            // Active and single-trail
            isActiveExpression,
            trailPinActiveImageName,
            // In-active and single-trail
            trailPinImageName
          ],
          'text-field': ['case', isMultiTrailExpression, ['get', 'trailCount'], '']
        }}
        paint={{
          ...defaultPinLayerPaintProps,
          'text-color': COLOR_TEXT_PRIMARY_INVERSE
        }}
      />
      {trailResults && activeSerializedTrailCoordinates && (
        <ActiveTrailPopup
          isComplete={id => isComplete('trail', id)}
          isFavorite={id => isFavorite('trail', id)}
          isVerified={id => isVerified('trail', id)}
          onFavoriteClick={id => handleFavoriteClick('trail', id)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          trails={trailResults[activeSerializedTrailCoordinates] ?? []}
        />
      )}
      {listModal}
      {signUpModal}
    </>
  );
};

export default TrailPins;
