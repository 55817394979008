import { useEffect, useMemo } from 'react';
import { getBaseUrl } from '@alltrails/shared/api';
import { useDispatch, useSelector } from '../redux';
import { updateRoutesById } from '../redux/reducer';
import { useGetPolylineQuery } from '../redux/mapsApi';
import { useGetGeoServiceTrailPolylineQuery } from '../redux/geoServiceApi';
import { LngLat } from '../types/Geo';

const useCurrentResult = (
  shouldFetchPolyline?: boolean,
  /**
   * @deprecated Don't use this before checking with the DISCO team. GeoService is not ready for user-facing load yet.
   */
  shouldUseGeoService?: boolean
) => {
  const dispatch = useDispatch();
  const baseUrl = getBaseUrl();
  const { clickedResult, hoveredResult } = useSelector(state => ({
    clickedResult: state.map.clickedResult,
    hoveredResult: state.map.hoveredResult
  }));

  const currentResult = useMemo(() => hoveredResult || clickedResult, [clickedResult, hoveredResult]);

  const {
    data: polylineData,
    isFetching,
    isError
  } = useGetPolylineQuery({ baseUrl, trailId: currentResult?.ID }, { skip: !currentResult?.ID || !shouldFetchPolyline || shouldUseGeoService });

  const {
    data: geoServicePolylineData,
    isFetching: geoServiceIsFetching,
    isError: geoServiceIsError
  } = useGetGeoServiceTrailPolylineQuery(parseInt(currentResult?.ID as string), {
    skip: !currentResult?.ID || !shouldFetchPolyline || !shouldUseGeoService
  });

  useEffect(() => {
    if (
      shouldFetchPolyline &&
      currentResult?.ID &&
      ((!shouldUseGeoService && polylineData && !isFetching && !isError) ||
        (shouldUseGeoService && geoServicePolylineData && !geoServiceIsFetching && !geoServiceIsError))
    ) {
      dispatch(
        updateRoutesById({
          resultId: String(currentResult?.ID),
          routeCoordinates: (shouldUseGeoService ? geoServicePolylineData : polylineData) as LngLat[]
        })
      );
    }
  }, [
    currentResult?.ID,
    dispatch,
    isError,
    isFetching,
    polylineData,
    shouldFetchPolyline,
    shouldUseGeoService,
    geoServicePolylineData,
    geoServiceIsFetching,
    geoServiceIsError
  ]);

  return currentResult;
};

export default useCurrentResult;
