import { useCallback, useEffect, useMemo, useState } from 'react';
import { type MapLayerMouseEvent, Popup } from 'react-map-gl';
import { useLanguageRegionCode } from '@alltrails/language';
import TrailCard from '@alltrails/shared/components/TrailCard';
import { TrailHit } from '@alltrails/search/types/algoliaResultTypes';
import { getBaseUrl } from '@alltrails/shared/api';
import { useAuthorization } from '@alltrails/context';
import { useSelector } from '../../redux';
import useHoveredAdminResult from '../../hooks/useHoveredAdminResult';
import useMap from '../../hooks/useMap';
import { useGetPolylineQuery } from '../../redux/mapsApi';
import Polyline from '../Polyline';
import { AdminMapHit, AdminTrackHit, trailHitToTrail } from './utils';
import MapCard from './MapCard';
import ActivityCard from './ActivityCard';

const HoveredResultCardAndPolyline = () => {
  const baseUrl = getBaseUrl();
  const map = useMap();
  const hoveredAdminResult = useHoveredAdminResult();
  const displayMetric = useSelector(state => state.context.displayMetric);

  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });
  const isPLP = hasPermission({ permission: 'public_lands:manage' });

  const languageRegionCode = useLanguageRegionCode();
  const [isPopupHovered, setIsPopupHovered] = useState(false);
  const [currentResult, setCurrentResult] = useState(hoveredAdminResult);
  const [showPopup, setShowPopup] = useState(true);
  const { mapId, trailId } = useMemo(() => {
    if (!currentResult) {
      return {};
    }

    return {
      mapId: currentResult.type !== 'trail' ? currentResult.ID : undefined,
      trailId: currentResult.type === 'trail' ? currentResult.ID : undefined
    };
  }, [currentResult]);
  const { data: polylineCoordinates } = useGetPolylineQuery({ baseUrl, mapId, trailId }, { skip: !mapId && !trailId });

  useEffect(() => {
    if (hoveredAdminResult) {
      setCurrentResult(hoveredAdminResult);
    } else if (!isPopupHovered) {
      setCurrentResult(undefined);
    }
  }, [hoveredAdminResult, isPopupHovered]);

  const openContributeLink = useCallback(() => {
    window.open(`/contribute/route?trail_id=${currentResult?.ID}`, '_blank');
  }, [currentResult]);

  useEffect(() => {
    if (!map) {
      return;
    }
    const onMouseMove = (event: MapLayerMouseEvent) => {
      setShowPopup(!event.originalEvent?.shiftKey);
    };
    map?.on('mousemove', onMouseMove);
    return () => {
      map?.off('mousemove', onMouseMove);
    };
  }, [map]);

  return (
    <>
      {currentResult?._geoloc && showPopup && (
        <Popup
          closeButton={false}
          closeOnClick={false}
          focusAfterOpen={false}
          latitude={currentResult._geoloc.lat}
          longitude={currentResult._geoloc.lng}
          maxWidth="none"
          offset={currentResult.popupOffset || [0, -16]}
        >
          {currentResult.type === 'trail' && (
            <TrailCard
              languageRegionCode={languageRegionCode}
              onCardMouseEnter={() => setIsPopupHovered(true)}
              onCardMouseLeave={() => setIsPopupHovered(false)}
              variant="small"
              trail={trailHitToTrail(currentResult as TrailHit)}
              useMetric={!!displayMetric}
              footerCTAs={
                isAdmin || isPLP
                  ? [
                      {
                        label: 'Edit',
                        onClick: openContributeLink
                      }
                    ]
                  : undefined
              }
              openInNewTab
            />
          )}
          {currentResult.type === 'track' && <ActivityCard currentResult={currentResult as AdminTrackHit} setIsPopupHovered={setIsPopupHovered} />}
          {currentResult.type === 'map' && <MapCard setIsPopupHovered={setIsPopupHovered} currentResult={currentResult as AdminMapHit} />}
        </Popup>
      )}

      {polylineCoordinates && currentResult && <Polyline coordinates={polylineCoordinates} uniqueId="hovered-map" />}
    </>
  );
};

export default HoveredResultCardAndPolyline;
