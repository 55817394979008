import { useCallback } from 'react';
import { useSelector } from '../redux';
import useMap from './useMap';

// Based on buildPrintMapUrlParams in the monolith
const usePrintUrlSearchParams = () => {
  const map = useMap();
  const { baseStyleId, dynamicStyleIds, staticStyleIds } = useSelector(state => ({
    baseStyleId: state.map.baseStyleId,
    dynamicStyleIds: state.map.dynamicStyleIds,
    staticStyleIds: state.map.staticStyleIds
  }));

  const getParams = useCallback(() => {
    const params = new URLSearchParams({});

    if (!map) {
      return params;
    }

    const center = map.getCenter();
    params.append('map_center_lat', String(center.lat));
    params.append('map_center_lon', String(center.lng));
    params.append('map_zoom', String(map.getZoom()));
    params.append('map_type', baseStyleId);
    [...dynamicStyleIds, ...staticStyleIds].forEach(overlay => params.append('enabled_overlays[]', overlay));

    return params;
  }, [baseStyleId, dynamicStyleIds, map, staticStyleIds]);

  return getParams;
};

export default usePrintUrlSearchParams;
