import { TraillPolylinesResponse } from '@alltrails/geo-service';
import { type Feature, type LineString, featureCollection, lineString } from '@turf/helpers';
import { LngLat } from '../types/Geo';
import * as legacyGeoJSONConversions from './legacyGeoJSONConversions';

/**
 * Convert trail polylines to a GeoJSON FeatureCollection with an optional delay between chunks to prevent blocking the main thread
 * Long term a better approach would be to use WebWorkers but doing so will require updating our ESBuild config to support import.meta.url and configuring nextjs to host the worker files
 */
const getFeatureCollectionFromTrailPolylines = async (response: TraillPolylinesResponse, { chunks = 5, delay = 10 } = {}) => {
  const features: Feature<LineString>[] = [];
  let count = 0;

  for (let i = 0; i < response.trailMaps.length; i += 1) {
    if (delay && count && count >= chunks) {
      await new Promise(resolve => setTimeout(resolve, delay));
      count = 0;
    }

    if (response.trailMaps[i].polyline) {
      const coordinates = legacyGeoJSONConversions.decodeSegmentLngLat({ polyline: { pointsData: response.trailMaps[i].polyline } }) as LngLat[];
      // lineString requires multiple points, otherwise it throws an error
      if (coordinates.length > 1) {
        features.push(lineString(coordinates, { id: response.trailMaps[i].trailId }));
        count += 1;
      }
    }
  }

  return featureCollection<LineString>(features);
};

export default getFeatureCollectionFromTrailPolylines;
