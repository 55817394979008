import { Popup } from 'react-map-gl';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { useSelector } from '../../redux';
import type { AllTrailsResult } from '../../types/Results';
import { trailCardOffset, trailCardTrailheadOffset } from '../../utils/constants';
import useCurrentResult from '../../hooks/useCurrentResult';
import MobilePopupCarousel from '../MobilePopupCarousel';
import styles from './styles/styles.module.scss';

type Props = {
  renderPopupChild: (result: AllTrailsResult) => JSX.Element;
  enableShiftKeyHover?: boolean;
};

/**
 * TrailheadPopup conditionally renders children
 * @param {props}: Props
 * @returns
 */

const TrailheadPopup = ({ renderPopupChild, enableShiftKeyHover }: Props) => {
  const isMobile = useIsMobileSizedScreen();
  const currentResult = useCurrentResult();

  const { trailheadResults, isHoveringListViewResult, isShiftKeyPressedOnHover } = useSelector(state => ({
    trailheadResults: state.map.trailheadResults,
    isHoveringListViewResult: state.map.isHoveringListViewResult,
    isShiftKeyPressedOnHover: state.map.isShiftKeyPressedOnHover
  }));

  const getCoordinates = (type: string, result?: AllTrailsResult) => {
    if (result) {
      if (result?._cluster_geoloc) {
        return result?._cluster_geoloc[type];
      }
      return result?._geoloc?.[type];
    }
    return null;
  };

  const longitude = getCoordinates('lng', currentResult);
  const latitude = getCoordinates('lat', currentResult);

  if (!currentResult || !latitude || !longitude || isHoveringListViewResult || (enableShiftKeyHover && isShiftKeyPressedOnHover)) {
    return null;
  }

  return (
    <Popup
      className={styles.popup}
      closeButton={false}
      closeOnClick={false}
      focusAfterOpen={false}
      latitude={latitude}
      longitude={longitude}
      maxWidth="none"
      offset={trailheadResults ? trailCardTrailheadOffset : trailCardOffset}
    >
      {isMobile && trailheadResults ? (
        <MobilePopupCarousel renderPopupChild={renderPopupChild} currentResult={currentResult} />
      ) : (
        renderPopupChild(currentResult)
      )}
    </Popup>
  );
};

export default TrailheadPopup;
