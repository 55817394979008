/**
 * This was created from useFlyover in the monolith to better fit our map-component pattern.
 * The initial usage is for the memories project, to render a flyover for the server to record.
 * This doesn't require any control mechanism or animation in/out of the flyover, so that logic was not
 * ported over to reduce the complexity of this work until it is actually needed.
 */

import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type Position, lineString } from '@turf/helpers';
import turfLength from '@turf/length';
import turfBearing from '@turf/bearing';
import turfSimplify from '@turf/simplify';
import { useAnimationFrame, useStateRef } from '@alltrails/core';
import { type MapRef } from 'react-map-gl';
import { COLOR_MAP_ACTIVITY, COLOR_MAP_ACTIVITY_OUTLINE } from '@alltrails/denali/tokens';
import BaseMap from '../BaseMap';
import MapProvider from '../MapProvider';
import useMap from '../../hooks/useMap';
import { useDispatch, useSelector } from '../../redux';
import { updateBaseStyleId, updateCurrentCoordinates, updateIs3dActive, updateRouteCoordinates } from '../../redux/reducer';
import Polyline, { polylineImages } from '../Polyline';
import { LngLat } from '../../types/Geo';
import getCoordinatesFromFeatures from '../../utils/getCoordinatesFromFeatures';
import { defaultPitchFor3dTerrain, maxMapboxZoom } from '../../utils/constants';
import * as legacyGeoJSONConversions from '../../utils/legacyGeoJSONConversions';
import CurrentMarker from '../CurrentMarker';
import {
  CameraPosition,
  Path,
  computePositionValues,
  getAnimationSpeed,
  getBounds,
  getCameraPositions,
  getInitialZoom,
  getInterpolatedCameraPosition,
  getNextCameraPosition
} from './utils';

// Defined outside of the component function so that this is always the same object
const animationDeps = [];
const images = polylineImages;

export type FlyoverStatus = 'loading' | 'active' | 'done';

// We don't have a good type for atMap - most places, we just have it typed as any.
// Check where we pass atMap to make sure the type you are passing in is OK
type FlyoverMapProps = {
  atMap: any;
  onStatusChange: (status: FlyoverStatus) => void;
  pitch?: number;
  speed?: number;
  useMinimalMap?: boolean;
  zoom?: number;
} & Pick<ComponentProps<typeof BaseMap>, 'children' | 'handleOnLoad' | 'initialCenter'>;

const BaseFlyoverMap = ({
  atMap,
  handleOnLoad,
  onStatusChange,
  pitch = defaultPitchFor3dTerrain,
  speed,
  useMinimalMap,
  zoom,
  ...baseMapProps
}: FlyoverMapProps) => {
  const map = useMap() as MapRef;

  const dispatch = useDispatch();
  const { currentCoordinates, routeCoordinates } = useSelector(state => ({
    currentCoordinates: state.map.currentCoordinates,
    routeCoordinates: state.map.routeCoordinates
  }));

  // Path info
  const pathCoordinates = useMemo(() => getCoordinatesFromFeatures(legacyGeoJSONConversions.atMapsToGeojson([atMap])), [atMap]);
  const [path, setPath] = useState<Path>();
  const [cameraPath, setCameraPath] = useState<CameraPosition[]>([]);
  const [pathDistance, setPathDistance] = useState(0);

  // Animation state
  const [animationProgress, setAnimationProgress] = useState(0);
  const calculatedDuration = useRef(0);
  const cameraBearing = useRef<number>();
  const cameraTarget = useRef<CameraPosition>();
  const cameraPrevTarget = useRef<CameraPosition>();
  const cameraTargetStartingProgress = useRef(0);
  const initialBearing = useRef<number>();
  const initialZoom = useRef<number>(zoom || maxMapboxZoom);
  const prevCoordinates = useRef<Position>();
  const prevCameraCoordinates = useRef<LngLat>();
  const startTime = useRef(0);
  const timeElapsed = useRef(0);

  const [isPaused, setIsPaused, isPausedRef] = useStateRef(true);

  useEffect(() => {
    // Get these big map updates going ASAP
    dispatch(updateBaseStyleId(useMinimalMap ? 'alltrailsSatelliteMinimal' : 'alltrailsSatellite'));
    dispatch(updateIs3dActive(true));
  }, [dispatch, useMinimalMap]);

  useEffect(() => {
    // Initialize static values based on the path
    if (pathCoordinates && pathCoordinates.length > 1) {
      dispatch(updateRouteCoordinates(pathCoordinates));
      dispatch(updateCurrentCoordinates({ lat: pathCoordinates[0][1], lng: pathCoordinates[0][0] }));

      const pathString = lineString(pathCoordinates);
      const pathDistKm = turfLength(pathString);
      const animationSpeed = speed || getAnimationSpeed(pathDistKm);
      const mapZoom = zoom || getInitialZoom(pathDistKm);

      const resolution = animationSpeed * (1 + mapZoom / maxMapboxZoom);
      const tolerance = 1 / resolution;
      let simplifiedPath = pathString;
      try {
        simplifiedPath = turfSimplify(pathString, { tolerance });
      } catch (e) {}
      const startingBearing = turfBearing(simplifiedPath.geometry.coordinates[0], simplifiedPath.geometry.coordinates[1]);

      setPath(pathString);
      setPathDistance(pathDistKm);
      setCameraPath(getCameraPositions(simplifiedPath));
      initialZoom.current = mapZoom;

      calculatedDuration.current = ((pathDistKm * 1000) / animationSpeed) * 1000;
      initialBearing.current = startingBearing;
      cameraBearing.current = startingBearing;
    }
  }, [dispatch, pathCoordinates, speed, zoom]);

  /**
   * Initialize flyover by
   * 1) Enabling 3D
   * 2) Removing the existing polyline
   * 3) Drawing the line and marker that will be animated
   * @param enabled3D whether 3D is enabled at the time of initializing
   * @param enable3D a function to enable 3D view
   * @param setLayer a function to set a specified map layer
   * @param currentLayer the current layer at the time of initializing
   * @param onMapLoaded a callback fired when the correct layer & 3D view is loaded
   */
  const initFlyover = useCallback(() => {
    if (!map || !atMap || !pathCoordinates?.length) {
      return;
    }

    const startFlyover = () => {
      onStatusChange('active');
      setIsPaused(false);
    };

    map.flyTo({
      center: pathCoordinates[0],
      zoom: initialZoom.current,
      bearing: cameraBearing.current,
      pitch,
      speed: 0.3,
      curve: 2,
      essential: true
    });

    // After the map move, give a small delay to ensure the map has finished loading before starting the flyover
    map.once('moveend', () => setTimeout(startFlyover, 2000));
  }, [map, atMap, pathCoordinates, pitch, onStatusChange, setIsPaused]);

  /**
   * Responsible for updating the animation state on each call to `requestAnimationFrame`.
   * @param time current timestamp from when the first requestAnimationFrame was triggered
   */
  const flyoverFrame = (time: DOMHighResTimeStamp) => {
    if (!startTime.current) {
      startTime.current = time;
    } else {
      timeElapsed.current = time - startTime.current;
    }

    const percentageComplete = timeElapsed.current / calculatedDuration.current;

    if (percentageComplete >= 1) {
      setIsPaused(true);
      onStatusChange('done');
      return;
    }

    if (isPausedRef.current) {
      return;
    }

    const { lngLat, position } = computePositionValues(path!, pathDistance, percentageComplete, prevCoordinates.current);

    // Update the marker location
    dispatch(updateCurrentCoordinates(lngLat));

    // We pass the animationProgress into the "completed" polyline so it knows how much to render of the full path.
    // The problem is that this update is faster than updating the current marker, likely because that marker is getting
    // fully rerendered each time since the lat lng changes are causing the <Source> element to change around the layer.
    // This timeout helps to align those updates so that the marker isn't visibly behind the progress line.
    // Let's give this a try and if we notice problems with it, we can revisit the issue
    setTimeout(() => setAnimationProgress(percentageComplete), 50);

    // Move camera
    const nextTarget = getNextCameraPosition(cameraPath, percentageComplete);
    if (!cameraTarget.current || nextTarget?.location !== cameraTarget.current.location) {
      cameraPrevTarget.current = cameraTarget.current;
      cameraTarget.current = nextTarget;
      cameraTargetStartingProgress.current = percentageComplete;
    }

    const previousTarget = cameraPrevTarget.current ?? cameraPath[0];
    const cameraPosition =
      previousTarget && nextTarget
        ? getInterpolatedCameraPosition(
            previousTarget,
            nextTarget,
            cameraTargetStartingProgress.current,
            percentageComplete,
            calculatedDuration.current / 1000
          )
        : { location: lngLat, bearing: initialBearing.current };

    map.jumpTo({
      center: cameraPosition.location as any,
      bearing: cameraPosition.bearing
    });

    prevCoordinates.current = position;
    prevCameraCoordinates.current = cameraPosition.location as LngLat;
    cameraBearing.current = cameraPosition.bearing;
  };

  useAnimationFrame(flyoverFrame, isPaused, isPausedRef, undefined, animationDeps);

  return (
    <BaseMap
      ignoreInitial3dPitch
      images={images}
      initialBounds={getBounds(atMap)}
      handleOnLoad={() => {
        initFlyover();
        handleOnLoad?.();
      }}
      {...baseMapProps}
    >
      {routeCoordinates && <Polyline coordinates={routeCoordinates} />}
      {routeCoordinates && (
        <Polyline
          uniqueId="completed"
          coordinates={routeCoordinates}
          lineColor={COLOR_MAP_ACTIVITY}
          lineOutlineColor={COLOR_MAP_ACTIVITY_OUTLINE}
          lineProgress={Math.min(animationProgress, 1)}
        />
      )}
      {currentCoordinates && <CurrentMarker latitude={currentCoordinates.lat} longitude={currentCoordinates.lng} />}
    </BaseMap>
  );
};

const FlyoverMap = (props: FlyoverMapProps) => (
  <MapProvider>
    <BaseFlyoverMap {...props} />
  </MapProvider>
);

export default FlyoverMap;
