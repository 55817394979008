import { Layer, Source } from 'react-map-gl';
import { Feature, FeatureCollection, LineString } from 'geojson';
import { COLOR_GREEN_700, COLOR_UI_HIGHLIGHT } from '@alltrails/denali/tokens';
import { defaultSymbolLayerLayoutProps, getChevronsId, getLayerBeforeId, getPolylineId } from '../../utils/layers';
import useMap from '../../hooks/useMap';
import { chevronImageKey } from '../../utils/images';

type Props = {
  data: Feature<LineString> | FeatureCollection<LineString>;
  hide?: boolean;
  lineColor?: string;
  lineOutlineColor?: string;
  lineProgress?: number;
  uniqueId?: string;
};

const PolylineSource = ({ data, hide, lineColor = COLOR_UI_HIGHLIGHT, lineOutlineColor = COLOR_GREEN_700, lineProgress = 1, uniqueId }: Props) => {
  const map = useMap();
  const polylineId = getPolylineId(uniqueId);
  const chevronsId = getChevronsId(uniqueId);

  return (
    <Source
      type="geojson"
      data={data}
      lineMetrics // Allows us to use line-progress below
    >
      <Layer
        beforeId={getLayerBeforeId(map, chevronsId)}
        id={chevronsId}
        type="symbol"
        layout={{
          ...defaultSymbolLayerLayoutProps,
          'icon-image': ['image', chevronImageKey],
          'icon-rotate': 90,
          'symbol-placement': 'line',
          'symbol-spacing': 50,
          visibility: hide ? 'none' : 'visible'
        }}
        paint={{}}
      />
      <Layer
        id={polylineId}
        beforeId={chevronsId}
        type="line"
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
          visibility: hide ? 'none' : 'visible'
        }}
        paint={{
          'line-width': 4,
          'line-gradient': ['step', ['line-progress'], lineColor, lineProgress, 'rgba(0, 0, 0, 0)']
        }}
      />
      <Layer
        beforeId={polylineId}
        type="line"
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
          visibility: hide ? 'none' : 'visible'
        }}
        paint={{
          'line-width': 8,
          'line-gradient': ['step', ['line-progress'], lineOutlineColor, lineProgress, 'rgba(0, 0, 0, 0)']
        }}
      />
    </Source>
  );
};

export default PolylineSource;
