import { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertType, AlgoliaAlert, ObjectType } from '@alltrails/shared/types/alert';
import { AlgoliaObjectType, and, getAlertObjectTypeFilters, getObjectIdFilters, getStatusFilters } from '@alltrails/search/utils/algoliaFilters';
import { search } from '@alltrails/search/utils/algoliaRequests';
import { AssignmentType } from '@alltrails/geo-service';
import { LngLatBounds } from 'mapbox-gl';
import debounce from 'lodash.debounce';
import { useSelector } from '../redux';
import getGeoBoundariesInBounds, {
  type GeoBoundarySearchResult,
  type GeoBoundarySearchResultGeoBoundaryTrailFilter
} from '../requests/getGeoBoundariesInBounds';
import { getBoundingBoxParams } from '../components/DynamicStyleOverlays/algoliaUtils';
import { BoundaryFilterType } from '../types/Styles';

export type DecoratedGeoBoundaryTrailFilter = GeoBoundarySearchResultGeoBoundaryTrailFilter & { assignmentObject: AlgoliaAlert | { ID: string } };
export type DecoratedGeoBoundarySearchResult = Omit<GeoBoundarySearchResult, 'geoBoundaryTrailFilters'> & {
  geoBoundaryTrailFilters: DecoratedGeoBoundaryTrailFilter[];
};

type AlertsById = { [key: number]: AlgoliaAlert };

// Only for alerts filtered by type, which require algolia results, therefor cannot be server filtered
const getClientFilteredResults = (results: DecoratedGeoBoundarySearchResult[], boundaryFilters?: BoundaryFilterType[]) => {
  const alertFilters = boundaryFilters?.filter(f => [BoundaryFilterType.alertClosure, BoundaryFilterType.alertNonClosure].includes(f));
  // Only filter by alert type if 1 of 2 alert type filters are present. If both are present, we show all alert results
  if (alertFilters?.length === 1) {
    // TODO rewrite this all to a reduce function that removes unmatched geoBoundaryTrailFilters and GeoBoundaries when necessary
    const filtered = results.filter(r => meetsFilterCriteria(r, alertFilters[0]));
    const withoutUmatchedGeoBoundaryTrailFilers = filtered.map(r => ({
      ...r,
      geoBoundaryTrailFilters: r.geoBoundaryTrailFilters.filter(f => {
        if (f.assignmentObject === undefined) {
          return false;
        }
        const { alert_type } = f.assignmentObject as AlgoliaAlert;
        return (
          f.assignmentType !== AssignmentType.ALERT ||
          (alert_type === AlertType.CLOSURE && alertFilters[0] === BoundaryFilterType.alertClosure) ||
          (alert_type !== AlertType.CLOSURE && alertFilters[0] === BoundaryFilterType.alertNonClosure)
        );
      })
    }));

    return withoutUmatchedGeoBoundaryTrailFilers;
  }

  return results;
};

const meetsFilterCriteria = (result: DecoratedGeoBoundarySearchResult, boundaryFilter: BoundaryFilterType): boolean => {
  // Boundary must have at least one geoBoundaryTrailFilter with an assigned alert who's type matches that of the passed boundaryFilter type
  return result.geoBoundaryTrailFilters.some(f => {
    // TODO need to handle these cases in one place. Sometimes an alert has been deleted but its ID remains an assignment ID in the geo service
    if (f.assignmentObject === undefined) {
      return false;
    }
    const { alert_type } = f.assignmentObject as AlgoliaAlert;
    return (
      f.assignmentType !== AssignmentType.ALERT ||
      (f.assignmentType === AssignmentType.ALERT &&
        ((alert_type === AlertType.CLOSURE && boundaryFilter === BoundaryFilterType.alertClosure) ||
          (alert_type !== AlertType.CLOSURE && boundaryFilter === BoundaryFilterType.alertNonClosure)))
    );
  });
};

// Attach assignmentObject(AlgoliaAlert) to alert results. Alert data is used down the line for display and filter purposes
const decorateResults = (results: GeoBoundarySearchResult[], alerts: AlertsById): DecoratedGeoBoundarySearchResult[] => {
  const newResults = results.map(result => ({
    ...result,
    geoBoundaryTrailFilters: result.geoBoundaryTrailFilters.map(f => ({
      ...f,
      assignmentObject: f.assignmentType === AssignmentType.ALERT ? alerts[f.assignmentId] : { ID: f.id }
    }))
  }));
  return newResults;
};

const getUniqueAlertIds = (results: GeoBoundarySearchResult[]): string[] => {
  return results.reduce((acc, result) => {
    const alertIds = result.geoBoundaryTrailFilters.filter(f => f.assignmentType === AssignmentType.ALERT).map(f => f.assignmentId);
    return acc.concat([...new Set(alertIds)]);
  }, [] as string[]);
};

const fetchAlerts = async (alertIds, algoliaIndex, algoliaConfigs): Promise<AlertsById> => {
  const objectTypeFilter = getAlertObjectTypeFilters([ObjectType.Area, ObjectType.Trail, ObjectType.Geo]);
  const objectIdFilters = getObjectIdFilters(AlgoliaObjectType.Alert, alertIds);
  const statusFilter = getStatusFilters(['A', 'S', 'X']);
  const filters = and([objectTypeFilter, objectIdFilters, statusFilter]);
  const options = {
    attributesToRetrieve: ['ID', 'title', 'name', 'alert_type', 'status', 'start_date', 'end_date'],
    filters,
    hitsPerPage: 1000
  };
  const { hits } = await search<AlgoliaAlert>(algoliaIndex, '', algoliaConfigs, options);
  return hits.reduce((acc, hit) => ({ ...acc, [hit.ID]: hit }), {});
};

const useGeoBoundariesSearch = ({ bounds }: { bounds?: LngLatBounds }) => {
  const { boundaryFilters } = useSelector(state => ({
    boundaryFilters: state.map.adminStyleSettings?.boundaryFilters
  }));
  const { algoliaConfigs } = useSelector(state => ({ algoliaConfigs: state.algoliaConfigs }));
  const context = useSelector(state => state.context);
  const [results, setResults] = useState<{ items: DecoratedGeoBoundarySearchResult[]; requestTime: string } | undefined>();
  const [returnValue, setReturnValue] = useState<DecoratedGeoBoundarySearchResult[]>([]);
  const [lastRequestTime, setLastRequestTime] = useState<string | undefined>();

  const assignmentTypes = useMemo(() => {
    if (!boundaryFilters?.length) {
      return undefined;
    }
    return [
      ...new Set(
        boundaryFilters?.map(value => {
          switch (value) {
            case BoundaryFilterType.osm:
              return AssignmentType.AREA;
            case BoundaryFilterType.alertClosure:
              return AssignmentType.ALERT;
            case BoundaryFilterType.alertNonClosure:
              return AssignmentType.ALERT;
            case BoundaryFilterType.groupPermissions:
              return AssignmentType.GROUP;
          }
        })
      )
    ];
  }, [boundaryFilters]);

  const getResults = useCallback(async ({ bounds, context, boundaryFilters, algoliaConfigs, assignmentTypes, requestTime }) => {
    try {
      const result = await getGeoBoundariesInBounds({
        insideBoundingBox: getBoundingBoxParams(bounds).insideBoundingBox as string,
        assignmentTypes
      });
      const { geoBoundarySearchResults } = result;
      const alertIds = getUniqueAlertIds(geoBoundarySearchResults);
      const alerts = alertIds.length ? await fetchAlerts(alertIds, context.alertsAlgoliaIndexId, algoliaConfigs) : {};
      const mergedResults = decorateResults(geoBoundarySearchResults, alerts);
      const filteredResults = getClientFilteredResults(mergedResults, boundaryFilters);
      setResults({ items: filteredResults, requestTime });
    } catch {
      // do nothing to keep results as is
    }
  }, []);

  const debouncedGetResults = useMemo(() => debounce(getResults, 1000, { leading: true, trailing: false }), [getResults]);

  useEffect(() => {
    if (!bounds) {
      return;
    }
    const requestTime = new Date().toISOString();
    setLastRequestTime(requestTime);
    debouncedGetResults({ bounds, context, boundaryFilters, algoliaConfigs, assignmentTypes, requestTime });
  }, [algoliaConfigs, boundaryFilters, bounds, context, debouncedGetResults, assignmentTypes]);

  useEffect(() => {
    if (results?.requestTime === lastRequestTime) {
      setReturnValue(results?.items || []);
    }
  }, [results, lastRequestTime]);

  return returnValue;
};

export default useGeoBoundariesSearch;
