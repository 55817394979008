'use client';

import { AllTrailsResult, BoundingBox } from '@alltrails/maps';
import { createContext, useCallback, useContext, useState } from 'react';
import { SubLocationObject } from '@/types/subLocationListPages/shared';

const LocationMapContext = createContext({
  cardHoveredResult: undefined as AllTrailsResult | undefined,
  setCardHoveredResult: (result: AllTrailsResult | undefined) => {},
  cardHoveredPark: undefined as AllTrailsResult | undefined,
  setCardHoveredPark: (result: AllTrailsResult | undefined) => {},
  hoveredAreaBBox: undefined as BoundingBox | undefined,
  setHoveredAreaBBox: (result: BoundingBox | undefined) => {},
  initialBoundingBox: undefined as BoundingBox | undefined,
  setInitialBoundingBox: (result: BoundingBox | undefined) => {},
  areasToRender: [] as SubLocationObject[],
  handleSetAreasToRender: (areas: SubLocationObject[]) => {},
  currentPageForAreaQuery: 1,
  handleGetMoreAreas: () => {},
  hoveredCityOrState: undefined as SubLocationObject | undefined,
  setHoveredCityOrState: (result: SubLocationObject | undefined) => {}
});

type Props = {
  areas?: SubLocationObject[];
  children: React.ReactNode;
};

export const LocationMapProvider = ({ areas, children }: Props) => {
  // These values come from hovering Trail and City/State/Park Cards
  const [cardHoveredResult, setCardHoveredResult] = useState<AllTrailsResult | undefined>();
  const [cardHoveredPark, setCardHoveredPark] = useState<AllTrailsResult | undefined>();
  const [hoveredAreaBBox, setHoveredAreaBBox] = useState<BoundingBox | undefined>();
  const [initialBoundingBox, setInitialBoundingBox] = useState<BoundingBox | undefined>();
  const [currentPageForAreaQuery, setCurrentPageForAreaQuery] = useState(1);
  const [areasToRender, setAreasToRender] = useState<SubLocationObject[]>(areas ?? ([] as SubLocationObject[]));
  const [hoveredCityOrState, setHoveredCityOrState] = useState<SubLocationObject | undefined>();

  const handleSetAreasToRender = useCallback((areas: SubLocationObject[]) => {
    setAreasToRender(prevAreas => [...new Set([...prevAreas, ...areas])]);
  }, []);

  const handleGetMoreAreas = useCallback(() => {
    setCurrentPageForAreaQuery(prevPage => prevPage + 1);
  }, []);

  return (
    <LocationMapContext.Provider
      value={{
        cardHoveredResult,
        setCardHoveredResult,
        cardHoveredPark,
        setCardHoveredPark,
        hoveredAreaBBox,
        setHoveredAreaBBox,
        initialBoundingBox,
        setInitialBoundingBox,
        areasToRender,
        handleSetAreasToRender,
        currentPageForAreaQuery,
        handleGetMoreAreas,
        hoveredCityOrState,
        setHoveredCityOrState
      }}
    >
      {children}
    </LocationMapContext.Provider>
  );
};

export const useLocationMap = () => useContext(LocationMapContext);
