import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

export type ButtonConfig = {
  label: string;
  labelInProgress?: string;
  labelSuccess?: string;
  labelFailed?: string;
  handleClick: () => Promise<any> | void;
};

const useAdminFooterCTAs = (bottomButtonsConfig?: ButtonConfig[]) => {
  const intl = useIntl();
  const [footerCTALabels, setFooterCTALabels] = useState<string[]>(bottomButtonsConfig ? bottomButtonsConfig.map(({ label }) => label) : []);

  const updateLabelAtIndex = useCallback(
    (index: number, label: string) => {
      const newLabels = [...footerCTALabels.slice(0, index), label, ...footerCTALabels.slice(index + 1)];
      setFooterCTALabels(newLabels);
    },
    [footerCTALabels]
  );

  const onFooterCTAClick = useCallback(
    (index: number, config: ButtonConfig) => {
      const { labelInProgress, labelFailed, labelSuccess, handleClick } = config;
      // Not a promise if there is no labelInProgress
      if (!labelInProgress) {
        return handleClick();
      }

      updateLabelAtIndex(index, labelInProgress);
      return Promise.resolve(handleClick())
        .then(() => {
          updateLabelAtIndex(index, labelSuccess || intl.formatMessage({ defaultMessage: 'Success' }));
        })
        .catch(_ => {
          updateLabelAtIndex(index, labelFailed || intl.formatMessage({ defaultMessage: 'Failed' }));
        });
    },
    [updateLabelAtIndex, intl]
  );

  if (!bottomButtonsConfig || !bottomButtonsConfig.length) {
    return undefined;
  }

  return bottomButtonsConfig.map((config, index) => ({
    label: footerCTALabels[index],
    onClick: () => onFooterCTAClick(index, config)
  }));
};

export default useAdminFooterCTAs;
