import { ScaleControl, type ScaleControlProps } from 'react-map-gl';
import { useSelector } from '../../redux';

const DistanceScale = ({ maxWidth = 80, position }: Pick<ScaleControlProps, 'maxWidth' | 'position'>) => {
  const { displayMetric } = useSelector(state => ({
    displayMetric: state.context.displayMetric
  }));

  return <ScaleControl maxWidth={maxWidth} position={position} unit={displayMetric ? 'metric' : 'imperial'} />;
};

export default DistanceScale;
