import { createCircleMarker } from '../../utils/svgMarkerComponents';

const additionalDefs = `<linearGradient id="gradient" x1="0%" x2="100%" y1="0%" y2="0%">
  <stop offset="0%" stop-color="#51C152" />
  <stop offset="50%" stop-color="#51C152" />
  <stop offset="50%" stop-color="#1A2B16" />
  <stop offset="100%" stop-color="#1A2B16" />
</linearGradient>`;

const startEndSvg = createCircleMarker({ additionalDefs, innerDiameter: 16, innerFill: 'url(#gradient)' });

export default startEndSvg;
