import { ReactNode } from 'react';
import ActivityType from '@alltrails/shared/types/activityType';

// The core map type that is required to render the Mapbox map
export type BaseStyleId =
  | 'adminBasemap'
  | 'alltrailsOutdoorsV2'
  | 'alltrailsSatellite'
  | 'alltrailsSatelliteMinimal'
  | 'googleSatellite'
  | 'opencycle'
  | 'openstreet'
  | 'roadmap'
  | 'topo'
  | 'worldparks'
  | 'worldtopo';

// Static styles are basic overlays that don't need API calls to render
export type StaticStyleId =
  | 'adminOverlay'
  | 'airQuality'
  | 'lightPollution'
  | 'pollen'
  | 'waymarkedCycling'
  | 'waymarkedHiking'
  | 'waymarkedMTB'
  | 'weather';

// Dynamic (a term made up for our needs) styles are overlays that rely on API calls to render
// and the data can be tightly coupled with AllTrails data
export type DynamicStyleId =
  | 'after'
  | 'clusterTrails'
  | 'contribs'
  | 'distanceMarkers'
  | 'geoBoundaries'
  | 'heatmap'
  | 'limit'
  | 'mapsAll'
  | 'nearbyTrails'
  | 'pendingTrails'
  | 'photos'
  | 'recordingsAll'
  | 'recordingsPop'
  | 'waypoints';

export type StyleId = BaseStyleId | StaticStyleId | DynamicStyleId;

export type BaseStyleCardConfig = {
  displayName: ReactNode;
  imgSrc: string;
  key: BaseStyleId;
  requiresAdmin?: boolean;
  requiresPLP?: boolean;
  requiresAdminStyleSourceUrl?: boolean;
  supports3d?: boolean;
};

export type StaticStyleCardConfig = {
  displayName: ReactNode;
  imgSrc: string;
  key: StaticStyleId;
  requires2d?: boolean;
  requiresAdmin?: boolean;
  requiresPLP?: boolean;
  requiresAdminStyleSourceUrl?: boolean;
  requiresGPSies?: boolean;
  requiresPlus?: boolean;
};

export type DynamicStyleCardConfig = {
  displayName: ReactNode;
  imgSrc: string;
  key: DynamicStyleId;
  hasHeatmap?: boolean;
  hideFromPLP?: boolean;
  requires2d?: boolean;
  requiresAdmin?: boolean;
  requiresPLP?: boolean;
  requiresPlus?: boolean;
};

export type StyleCardConfig = BaseStyleCardConfig | StaticStyleCardConfig | DynamicStyleCardConfig;

export type SourceConfig = {
  attribution: string;
  maxzoom?: number;
  minzoom?: number;
  tileSize?: number;
};

export type LayerConfig = {
  paint?: {
    opacity?: number;
    'raster-opacity'?: number;
  };
};

export enum BoundaryFilterType {
  alertClosure = 'alert-closure',
  alertNonClosure = 'alert-non-closure',
  osm = 'osm',
  groupPermissions = 'group-permissions'
}

export type AdminStyleSettings = {
  activityType?: ActivityType | 'all';
  styleSourceUrls?: Partial<Record<StyleId, string>>;
  boundaryFilters?: BoundaryFilterType[];
};
