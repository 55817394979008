import { useIntl } from 'react-intl';
import IconButton from '@alltrails/denali/components/IconButton';
import ZoomIn from '@alltrails/denali/icons/ZoomIn';
import ZoomOut from '@alltrails/denali/icons/ZoomOut';
import useMap from '../../hooks/useMap';

const ZoomControls = () => {
  const intl = useIntl();
  const map = useMap();

  return (
    <>
      <IconButton
        icon={{ Component: ZoomIn }}
        onClick={() => {
          map?.zoomIn();
        }}
        testId="zoom-in"
        title={intl.formatMessage({ defaultMessage: 'Zoom map in' })}
        variant="elevated"
      />
      <IconButton
        icon={{ Component: ZoomOut }}
        onClick={() => {
          map?.zoomOut();
        }}
        testId="zoom-out"
        title={intl.formatMessage({ defaultMessage: 'Zoom map out' })}
        variant="elevated"
      />
    </>
  );
};

export default ZoomControls;
