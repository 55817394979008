import { LngLatBounds } from 'mapbox-gl';
import { SearchRequestOptions } from '@alltrails/search/types/algoliaResultTypes';
import { AdminStyleSettings } from '../../types/Styles';

// Extra attributes here that are not used by default are required for map and activity cards
export const attributesToRetrieve = [
  'ID',
  '_geoloc',
  'type',
  'rating',
  'photo_count',
  'activities',
  'slug',
  'name',
  'duration_minutes',
  'length',
  'elevation_gain',
  'user',
  'created_at',
  'area_name',
  'park_name',
  'city_name',
  'country_name',
  'country_id',
  'park_slug',
  'city_url',
  'area_slug'
];

export const getBoundingBoxParams = (bounds: LngLatBounds): Pick<SearchRequestOptions, 'insideBoundingBox'> => {
  const boundingBox = [bounds.getNorth(), bounds.getWest(), bounds.getSouth(), bounds.getEast()].join(',');
  return { insideBoundingBox: boundingBox };
};

export const getFilters = (activityType: AdminStyleSettings['activityType'], requireRatingOrPhoto = false): Pick<SearchRequestOptions, 'filters'> => {
  const base = 'trail_id=0 AND hidden=0 AND missing_polyline=0';
  const withActivity = activityType === 'all' ? base : `${base} AND activities:${activityType}`;
  const withRatingOrPhoto = requireRatingOrPhoto ? `${withActivity} AND (rating>0 OR photo_count>0)` : withActivity;

  return { filters: withRatingOrPhoto };
};
