/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// now using Long.js because bitwise operations in JS automatically convert to 32bit, which was rounding UNIX timestamps
// https://github.com/dcodeIO/long.js
import { featureCollection, multiLineString, point } from '@turf/helpers';
import turfDistance from '@turf/distance';
import { metersToMiles, metersToFeet } from '@alltrails/data-formatting';
import Long from 'long';
import { ElevationPoint, LatLngComplete, LngLat } from '../types/Geo';

/*
 * Actively work to destroy this code. It is carried over for our specific
 * needs when deserializing activities from the API into geoJSON. This is all
 * directly copied from the alltrails monolith. We should lobby to make API
 * changes _if_ that might help clean up some of the #frontend work involved
 * below.
 *
 * DO NOT ADD TO THIS CODE. ACTIVELY WORK TO TEAR AWAY AT THIS CODE.
 */

const buildAtMapProps = atMap => {
  const { ID, id, created_at, metadata, name, presentationType, slug, trail_id, trailId, type } = atMap;
  return {
    ID: ID || id,
    created_at: metadata ? metadata.created : created_at,
    isVerifiedMap: type === 'AtVerifiedMap',
    name,
    slug,
    trailId: trailId || trail_id,
    type: presentationType || 'track'
  };
};

const getLineColor = atLine => {
  const defaultLineColor = '#ff0000';
  if (!atLine) {
    return defaultLineColor;
  }
  return atLine.lineDisplayProperty ? atLine.lineDisplayProperty.color : defaultLineColor;
};

const lineOrSegSort = (a, b) => {
  if (a.sequence_num && b.sequence_num) return a.sequence_num - b.sequence_num;
  if (a.sequenceNum && b.sequenceNum) return a.sequenceNum - b.sequenceNum;
  return a.id - b.id;
};

const getLines = atMap => {
  if (!atMap) {
    return [];
  }
  const { routes, tracks } = atMap;
  const lines = routes && routes.length > 0 ? routes : tracks;
  if (!lines) {
    return [];
  }
  return lines.sort(lineOrSegSort);
};

const getSegments = atLine => {
  if (!atLine) {
    return [];
  }
  const { lineSegments, lineTimedSegments } = atLine;
  const segments = lineSegments && lineSegments.length > 0 ? lineSegments : lineTimedSegments;
  if (!segments) {
    return [];
  }
  return segments.sort(lineOrSegSort);
};

// Mapbox may do this for us - verify changing the implementation doesn't break
// things first, but maybe pursue https://github.com/mapbox/polyline
const decodedObjectsFromPolyline = (chars, resolutions) => {
  const { length } = chars;
  const dimensions = resolutions.length;

  let count = 0;
  let shift = 0;
  let value = Long.ZERO;
  let bbyte = Long.ZERO;

  const buffer = [dimensions * length, 0];
  let bufferCount = 0;

  while (count < length) {
    bbyte = Long.ZERO;

    for (let i = 0; i < dimensions; i += 1) {
      value = Long.ZERO;
      shift = 0;
      do {
        if (count >= length) {
          break;
        }
        bbyte = Long.fromNumber(chars.charCodeAt(count) - 63);
        count += 1;
        value = value.or(bbyte.and(0x1f).toNumber() * 2 ** shift);
        shift += 5;
      } while (bbyte.toNumber() >= 0x20);
      const delta = value.and(1).toNumber() !== 0 ? value.shiftRight(1).not() : value.shiftRight(1);
      const lastValue = bufferCount === 0 ? 0 : buffer[(bufferCount - 1) * dimensions + i];
      buffer[bufferCount * dimensions + i] =
        Math.round((lastValue + 10 ** -resolutions[i] * delta.toNumber()) * 10 ** resolutions[i]) / 10 ** resolutions[i];
    }
    bufferCount += 1;
  }

  const res: any[] = [];
  for (let index = 0, { length: bufferLength } = buffer; index < bufferLength; index += dimensions) {
    res.push(buffer.slice(index, index + dimensions));
  }
  return res;
};

const decodeLatLngs = pointsData => decodedObjectsFromPolyline(pointsData, [5, 5]);

const reverseCoord = coord => [coord[1], coord[0]];

const decodeSegmentLatLng = segment => {
  if (!segment || !segment.polyline) {
    return [];
  }
  const { pointsData } = segment.polyline;
  if (!pointsData || pointsData === '') {
    return [];
  }
  return decodeLatLngs(pointsData);
};

const decodeSegmentLngLat = segment => decodeSegmentLatLng(segment).map(latLng => reverseCoord(latLng));

// Note: explore if it makes sense to combine start/end markers, photos, waypoints, etc. into single Geojson source
// Pros: easier to switch in/out atMap data
// Cons: less flexibility / modularity
// Layers would need to be filtered like ['==', $type, 'LineString']
// https://docs.mapbox.com/help/troubleshooting/mapbox-gl-js-performance/#combine-vector-tile-sources
const atMapsToGeojson = (atMaps, includeProps = false) => {
  const features: any[] = [];

  atMaps.forEach(atMap => {
    if (!atMap) {
      return;
    }
    let atMapProps;
    if (includeProps) {
      atMapProps = buildAtMapProps(atMap);
    }
    getLines(atMap).forEach(atLine => {
      const coordinates: any[] = [];
      const color = getLineColor(atLine);
      const lineId = atLine.id;
      getSegments(atLine).forEach(segment => {
        const lngLats = decodeSegmentLngLat(segment);
        if (lngLats.length > 0) {
          // Collect segment lngLats as coordinates for multiLineString
          coordinates.push(lngLats);
        }
      });
      if (coordinates.length > 0) {
        features.push(multiLineString(coordinates, { ...atMapProps, color, lineId }));
      }
    });
  });

  return featureCollection(features);
};

const pointItemToLngLat = item => {
  let lngLat;
  if (!item) {
    return null;
  }
  if (item._geoloc) {
    // Algolia Objects
    const loc = item._geoloc;
    lngLat = [loc.lng, loc.lat];
  } else if (item.location) {
    // AllTrails Objects
    const loc = item.location;
    lngLat = [loc.longitude, loc.latitude];
  } else if (item.latitude && item.longitude) {
    // Location Objects
    lngLat = [item.longitude, item.latitude];
  } else if (item.lngLat) {
    // Object with lngLat
    lngLat = item.lngLat;
  }
  if (lngLat && (lngLat[0] || lngLat[0] === 0) && (lngLat[1] || lngLat[1] === 0)) {
    if (lngLat[0] === 0 && lngLat[1] === 0) {
      // assume items with lat = 0 and lng = 0 have no location data
      return null;
    }

    return lngLat;
  }
  // N/A
  return null;
};

const pointItemToGeojson = item => {
  const lngLat = pointItemToLngLat(item);
  if (lngLat) {
    const { id, ID } = item;
    return point(lngLat, item, { id: id || ID });
  }
  // N/A
  return null;
};

const pointItemsToGeojson = items => {
  const geojsonPoints: any[] = [];
  items.forEach(item => {
    const p = pointItemToGeojson(item);
    if (p) {
      geojsonPoints.push(p);
    }
  });
  return featureCollection(geojsonPoints);
};

const elevationConversion = (elevation?: number, metric?: boolean) => {
  if (!elevation && elevation !== 0) return null;
  if (metric) return elevation;
  return metersToFeet(elevation);
};

const decodeIdxElev = idxElevData => decodedObjectsFromPolyline(idxElevData, [2, 5]);

const decodeSegmentIdxElev = segment => {
  if (!segment || !segment.polyline) {
    return [];
  }
  const idxElevData = segment.polyline.indexedElevationData;
  if (!idxElevData) {
    return [];
  }
  return decodeIdxElev(idxElevData);
};

const decodeSegmentIdxElevHash = (segment: [string, number][]) => {
  const hash: { [key: string]: number } = {};
  decodeSegmentIdxElev(segment).forEach(([key, value]) => {
    hash[key] = value;
  });
  return hash;
};

const decodeSegmentIdxTimeData = segment => {
  if (!segment || !segment.polyline) {
    return [];
  }
  const idxTimeData = segment.polyline.indexedTimeData;
  if (!idxTimeData) {
    return [];
  }
  return decodedObjectsFromPolyline(idxTimeData, [2, 2]);
};

const decodeSegmentIdxTimeDataHash = (segment: [string, number][]) => {
  const hash: { [key: string]: number } = {};
  decodeSegmentIdxTimeData(segment).forEach(([key, value]) => {
    hash[key] = value;
  });
  return hash;
};

const demPtToNdim = (pt): LatLngComplete => [pt.location.latitude, pt.location.longitude, pt.elevation, 0, 0, 0];
const getIncrStats = (currNdim, prevNdim, displayMetric) => {
  const currLatLng = [currNdim[0], currNdim[1]];
  const convertedElev = elevationConversion(currNdim[2], displayMetric);
  const currentTime = currNdim[3];
  if (!prevNdim) {
    return {
      distFromLast: 0,
      elevGainFromLast: 0,
      convertedElev,
      currLatLng,
      currentTime,
      timeFromLast: 0
    };
  }

  // Calculate distance from last point
  const currLngLat = currLatLng.slice().reverse();
  const prevLngLat = [prevNdim[1], prevNdim[0]];
  const incrDistanceInKm = turfDistance(prevLngLat, currLngLat);
  const distFromLast = displayMetric ? Math.round(incrDistanceInKm * 10 ** 5) / 10 ** 5 : metersToMiles(incrDistanceInKm * 1000);

  // Calculate elevation gain from last point
  const currElev = currNdim[2];
  const prevElev = prevNdim[2];
  let elevGainFromLast = 0;
  if (currElev > prevElev) {
    elevGainFromLast = currElev - prevElev;
  }

  return {
    distFromLast,
    elevGainFromLast,
    convertedElev,
    currLatLng,
    currentTime,
    timeFromLast: currentTime - prevNdim[3]
  };
};

// Group by segment for recordings, group by route for maps
// Grouping behavior defines how gaps are shown on elevation graph
const getGroupedSegments = atMap => {
  if (!atMap) {
    return [];
  }
  const isRecording = atMap.presentationType === 'track';
  const groupedSegments: { segments: any[]; color: string }[] = [];
  getLines(atMap).forEach(atLine => {
    const color = getLineColor(atLine);
    const lineSegments = getSegments(atLine).map(segment => {
      if (isRecording) {
        groupedSegments.push({
          segments: [segment],
          color
        });
      }
      return segment;
    });
    if (!isRecording && lineSegments.length > 0) {
      groupedSegments.push({
        segments: lineSegments,
        color
      });
    }
  });
  return groupedSegments;
};

const processGroupedSegments = (groupedSegments, displayMetric, shouldReturnTimeData?: boolean) => {
  const pointByPointSegmented: ElevationPoint[][] = [];
  let totalDistance = 0;
  let elevationGain = 0;
  let elapsedTime = 0;
  groupedSegments?.forEach(group => {
    const pointByPoint: ElevationPoint[] = [];
    group?.segments?.forEach?.(segment => {
      const segPoints = decodeSegmentLatLng?.(segment);
      const segIdxElevs = decodeSegmentIdxElevHash?.(segment);
      const segIdxTimeData = decodeSegmentIdxTimeDataHash?.(segment);
      segPoints?.forEach?.((curr, i) => {
        const currNdim = shouldReturnTimeData
          ? [curr[0], curr[1], segIdxElevs[i] || 0, segIdxTimeData[i] || 0]
          : [curr[0], curr[1], segIdxElevs[i] || 0];
        let prevNdim;
        if (i > 0) {
          const prev = segPoints[i - 1];
          prevNdim = shouldReturnTimeData
            ? [prev[0], prev[1], segIdxElevs[i - 1] || 0, segIdxTimeData[i - 1] || 0]
            : [prev[0], prev[1], segIdxElevs[i - 1] || 0];
        }
        const { distFromLast, elevGainFromLast, convertedElev, currLatLng, currentTime, timeFromLast } = getIncrStats(
          currNdim,
          prevNdim,
          displayMetric
        );
        const pointData = shouldReturnTimeData
          ? ([totalDistance, convertedElev, currLatLng, { currentTime, elapsedTime }] as ElevationPoint)
          : ([totalDistance, convertedElev, currLatLng] as ElevationPoint);
        // Push totalDistance, converted elevation, lat/lng, and optional currentTime/elapsedTime onto pointByPoint
        pointByPoint.push(pointData);
        // Increment distance and elevation gain counters
        totalDistance += distFromLast;
        elevationGain += elevGainFromLast;
        elapsedTime += timeFromLast;
      });
    });
    pointByPointSegmented.push(pointByPoint);
  });
  return {
    pointByPointSegmented,
    totalDistance,
    elevationGain
  };
};

const processElevations = (results, displayMetric) => {
  const { elevations } = results;
  const pointByPointSegmented: any[] = [];
  const pointByPoint: any[] = [];
  let distToPoint = 0;
  elevations?.forEach((curr, i) => {
    const currNdim = demPtToNdim(curr);
    let prevNdim;
    if (i > 0) {
      prevNdim = demPtToNdim(elevations?.[i - 1]);
    }
    const { distFromLast, convertedElev, currLatLng } = getIncrStats(currNdim, prevNdim, displayMetric);
    const pointData = [distToPoint, convertedElev, currLatLng];
    // Push distToPoint, converted elevation and lat/lng onto pointByPoint
    pointByPoint.push(pointData);
    // Increment distance counters
    distToPoint += distFromLast;
  });
  if (pointByPoint.length > 0) {
    pointByPointSegmented.push(pointByPoint);
  }
  // Return server-calculated stats
  const serverCalculatedDistance = displayMetric ? results.stats.distance / 1000.0 : metersToMiles(results.stats.distance);
  return {
    pointByPointSegmented,
    totalDistance: serverCalculatedDistance,
    elevationGain: results.stats.gain
  };
};

const averageGrade = (pointByPoint: ElevationPoint[], atIndex: number, displayMetric?: boolean) => {
  // Least-squares fit to points within 100m of selected point
  // Find at least one point ahead and behind, even if it's more than 100m
  const distanceSpan = 100.0 / (displayMetric ? 1000.0 : 1609.34);
  const centerDistance = pointByPoint?.[atIndex]?.[0] || 0;
  let xSum = 0;
  let ySum = 0;
  let xySum = 0;
  let xxSum = 0;
  let count = 0;
  // Look backwards from selected point for 100m, then look forwards from it
  let movingBackwards = true;
  let i = atIndex;
  for (;;) {
    if (i === pointByPoint.length) {
      break;
    }
    const distanceFromCenter = (pointByPoint?.[i]?.[0] || 0) - centerDistance;
    if (movingBackwards) {
      if (-distanceFromCenter > distanceSpan && i !== atIndex - 1) {
        movingBackwards = false;
        i = atIndex + 1;
      }
    } else if (distanceFromCenter > distanceSpan && i !== atIndex + 1) {
      break;
    }
    const x = (pointByPoint?.[i]?.[0] || 0) * (displayMetric ? 1000 : 5280);
    xSum += x;
    ySum += pointByPoint?.[i]?.[1] || 0;
    xxSum += x * x;
    xySum += x * (pointByPoint?.[i]?.[1] || 0);
    count += 1;
    if (movingBackwards) {
      i -= 1;
      if (i === -1) {
        movingBackwards = false;
        i = atIndex + 1;
      }
    } else {
      i += 1; // This is checked against bounds on next iteration
    }
  }
  if (count > 1 && count * xxSum - xSum * xSum !== 0.0) {
    const grade = (count * xySum - xSum * ySum) / (count * xxSum - xSum * xSum);
    return 100.0 * grade;
  }
  return 0;
};

const decodeNDim = (ndim): LatLngComplete[] => decodedObjectsFromPolyline(ndim, [5, 5, 5, 2, 5, 5]);

const encodeObjectsToPolyline = (values, cols, append, resolutions) => {
  const rows = values.length;
  const newValues = values.reduce((a, b) => a.concat(b), []);

  let value = 0;
  let output = '';
  for (let i = 0; i < rows * cols; i += 1) {
    // in 0..rows * cols-1
    value = 0;
    // Calculate the delta between the last value and the current one
    // Need to round both to the correct resolution first otherwise a rounding error drift occurs
    value = Math.round(10 ** resolutions[i % cols] * newValues[i]) - (i < cols ? 0 : Math.round(10 ** resolutions[i % cols] * newValues[i - cols]));

    // If value is negative take its two's complement ~value, and then left shift by 1 bit
    // eslint-disable-next-line no-bitwise
    value = value < 0 ? ~(value << 1) : value << 1;

    // The number is split into 5bit chunks starting from the right
    // Iterate through each chunk in reverse order
    while (value >= 0x20) {
      // OR each value with 0x20 (ie 32)
      // (value & 0x1F) makes sure value is 0-31.
      // we then add 0x3F (ie 63 or '?') to place our chars into the right domain space ie ?@ABC...etc
      // eslint-disable-next-line no-bitwise
      output += String.fromCharCode((0x20 | (value & 0x1f)) + 0x3f);
      // move to next 5bit chunk
      // eslint-disable-next-line no-bitwise
      value >>= 5;
    }
    output += String.fromCharCode(value + 0x3f);
  }
  return output;
};

const encodeLatLngs = latLngs => encodeObjectsToPolyline(latLngs, 2, false, [5, 5]);
// Convert array of n-dim encoded polylines to standard (2-dim) polylines
const convertNDimTo2DimPolylines = nDimPolylines =>
  nDimPolylines.map(nDimPolyline => {
    const latLngs = decodeNDim(nDimPolyline).map(mapPoint => [mapPoint[0], mapPoint[1]]);
    return encodeLatLngs(latLngs);
  });

export {
  atMapsToGeojson,
  decodedObjectsFromPolyline,
  decodeSegmentLatLng,
  decodeSegmentLngLat,
  getLineColor,
  getLines,
  getSegments,
  lineOrSegSort,
  pointItemsToGeojson,
  pointItemToLngLat,
  reverseCoord,
  processElevations,
  processGroupedSegments,
  getGroupedSegments,
  averageGrade,
  decodeNDim,
  convertNDimTo2DimPolylines
};
