import { useCallback, useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { GeoJSONSource } from 'mapbox-gl';
import { Feature, FeatureCollection } from 'geojson';
import { useDispatch } from 'react-redux';
import { AlgoliaHit } from '@alltrails/search/types/algoliaResultTypes';
import { COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/denali/tokens';
import { defaultSymbolLayerLayoutProps, getClusteredPinsId, getUnclusteredPinsId } from '../../utils/layers';
import { getPinImageKey } from '../../utils/images';
import useMap from '../../hooks/useMap';
import ClusterLegs from '../ClusterLegs';
import { LngLat } from '../../types/Geo';
import { AdminPinType } from '../../types/Images';
import { updateHoveredAdminResult } from '../../redux/reducer';
import { defaultClusterRadius } from '../../utils/constants';
import { parseProperties } from './utils';

type ActiveCluster = {
  coordinates: LngLat;
  features: Feature[];
};

type Props = {
  featureCollection: FeatureCollection;
  type?: AdminPinType;
  id: string;
};

const AdminClusteredPins = ({ featureCollection, type = 'trail', id }: Props) => {
  const srcId = `${id}-source`;
  const pinsId = getUnclusteredPinsId(id);
  const clustersId = getClusteredPinsId(id);
  const map = useMap();
  const dispatch = useDispatch();
  const [activeCluster, setActiveCluster] = useState<ActiveCluster | undefined>(undefined);

  const setHoveredResult = useCallback(
    e => {
      const properties = parseProperties(e.features[0].properties);
      dispatch(updateHoveredAdminResult(properties as AlgoliaHit));
    },
    [dispatch]
  );

  const unsetHoveredResult = useCallback(() => {
    dispatch(updateHoveredAdminResult(undefined));
  }, [dispatch]);

  const onClusterHover = useCallback(
    e => {
      const source = map?.getSource(srcId) as GeoJSONSource;
      const feature = e.features?.[0];
      if (feature) {
        const clusterId = feature.properties?.cluster_id;
        const pointCount = feature.properties?.point_count;
        source?.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
          setActiveCluster({
            coordinates: feature.geometry.coordinates,
            features
          });
        });
      }
    },
    [map, srcId]
  );

  const removeCluster = useCallback(() => {
    setActiveCluster(undefined);
  }, []);

  const onClusterPinHover = useCallback(
    feature => {
      dispatch(updateHoveredAdminResult(feature));
    },
    [dispatch]
  );

  useEffect(() => {
    map?.on('mouseenter', pinsId, setHoveredResult);
    map?.on('mouseleave', pinsId, unsetHoveredResult);
    map?.on('zoom', unsetHoveredResult);
    return () => {
      map?.off('mouseenter', pinsId, setHoveredResult);
      map?.off('mouseleave', pinsId, unsetHoveredResult);
      map?.off('zoom', unsetHoveredResult);
    };
  }, [map, pinsId, setHoveredResult, unsetHoveredResult]);

  useEffect(() => {
    map?.on('mouseenter', clustersId, onClusterHover);
    map?.on('click', removeCluster);
    map?.on('zoom', removeCluster);
    return () => {
      map?.off('mouseenter', clustersId, onClusterHover);
      map?.off('click', removeCluster);
      map?.off('zoom', removeCluster);
    };
  }, [clustersId, map, onClusterHover, removeCluster]);

  return (
    <>
      {activeCluster && <ClusterLegs {...activeCluster} onPinHover={onClusterPinHover} type={type} />}

      <Source id={srcId} type="geojson" data={featureCollection} cluster clusterRadius={defaultClusterRadius}>
        <Layer
          type="symbol"
          id={clustersId}
          filter={['has', 'point_count']}
          layout={{
            ...defaultSymbolLayerLayoutProps,
            'icon-image': getPinImageKey(type, 'clusterImage'),
            'icon-offset': [0, 2],
            'text-field': '{point_count}',
            'text-allow-overlap': true,
            'text-ignore-placement': true
          }}
          paint={{ 'text-color': COLOR_TEXT_PRIMARY_INVERSE }}
        />

        <Layer
          type="symbol"
          id={pinsId}
          filter={['!', ['has', 'point_count']]}
          layout={{
            'icon-anchor': 'bottom',
            'icon-image': getPinImageKey(type, 'pinImage'),
            'icon-offset': [0, 8]
          }}
        />
      </Source>
    </>
  );
};

export default AdminClusteredPins;
