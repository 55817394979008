import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { GeolocateControl as ReactMapGLGeolocateControl, type GeolocateControlRef } from 'react-map-gl';
import IconButton from '@alltrails/denali/components/IconButton';
import Location from '@alltrails/denali/icons/Location';
import useMap from '../../hooks/useMap';

const GeolocateControl = () => {
  const intl = useIntl();
  const map = useMap();
  const geolocateControlRef = useRef<GeolocateControlRef>(null);

  return (
    <>
      {/* We hide the stock control, but still leverage its API. At time of
      writing leaning on this control is the simplest way to trigger the UX
      around "find me"  behavior and showing the user's blue dot.
      https://docs.mapbox.com/mapbox-gl-js/example/locate-user/ */}
      <ReactMapGLGeolocateControl ref={geolocateControlRef} style={{ display: 'none' }} />

      <IconButton
        icon={{ Component: Location }}
        onClick={() => {
          if (map && (map.getBearing() !== 0 || map.getPitch() !== 0)) {
            map.easeTo({ bearing: 0, pitch: 0 });
          } else {
            geolocateControlRef?.current?.trigger();
          }
        }}
        testId="current-location"
        title={intl.formatMessage({ defaultMessage: 'Current Location' })}
        variant="elevated"
      />
    </>
  );
};
export default GeolocateControl;
