import { useMemo, useCallback, useState, useRef, Dispatch, SetStateAction, RefObject } from 'react';
import { max, min, bisectCenter } from 'd3';
import { type CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { averageGrade } from '../utils/legacyGeoJSONConversions';
import { ElevationData, ElevationPoint, LngLat } from '../types/Geo';

type Args = {
  displayMetric?: boolean;
  handleMouseOver?: (coordinates: LngLat | null) => void;
  rawChartData: ElevationPoint[];
  setCurrentGrade: Dispatch<SetStateAction<string | number>>;
  shouldRenderMinimalTicks?: boolean;
  showEmptyData?: boolean;
  tooltipRef: RefObject<HTMLDivElement>;
};

const useElevationChart = ({
  rawChartData,
  displayMetric,
  showEmptyData,
  tooltipRef,
  shouldRenderMinimalTicks,
  setCurrentGrade,
  handleMouseOver
}: Args): {
  data: ElevationData;
  maxX: number;
  minX: number;
  xTicks: number[];
  yTicks: number[];
  tooltipXPosition: number;
  handleMouseMove: (chart: CategoricalChartState) => void;
} => {
  const gradeCache = useRef<Record<string, number>>({});
  const [tooltipXPosition, setTooltipXPosition] = useState<number>(0);

  const data = useMemo(
    () =>
      rawChartData?.length < 1
        ? ([{ distance: 0, elevation: 0, coordinates: [0, 0], index: 0 }] as ElevationData)
        : rawChartData?.map?.((dataPoint, index) => {
            const distance = dataPoint?.[0];
            const elevation = dataPoint?.[1];
            const coordinates = dataPoint?.[2];
            const currentTime = dataPoint?.[3]?.currentTime;
            const elapsedTime = dataPoint?.[3]?.elapsedTime;

            return {
              distance,
              elevation,
              coordinates,
              index,
              currentTime,
              elapsedTime
            };
          }),
    [rawChartData]
  );

  const [minX, maxX, xTicks, yTicks] = useMemo(() => {
    const minXToReturn = min(rawChartData, d => d?.[0]) || 0;
    const maxXToReturn = max(rawChartData, d => d?.[0]) || 0;
    const minY = min(rawChartData, d => d?.[1]) || 0;
    const maxYToReturn = max(rawChartData, d => d?.[1]) || 0;
    const distanceRange = maxXToReturn - minXToReturn;
    const elevationRange = maxYToReturn - minY;
    const arrayToBisect = rawChartData?.map(dataPoint => dataPoint?.[0]);
    const firstIndex = bisectCenter(arrayToBisect, distanceRange * 0.25);
    const secondIndex = bisectCenter(arrayToBisect, distanceRange * 0.5);
    const thirdIndex = bisectCenter(arrayToBisect, distanceRange * 0.75);
    const lastIndex = rawChartData?.length > 0 ? rawChartData.length - 1 : 0;
    const minYTick = minY - elevationRange * 0.1;
    const maxYTick = maxYToReturn + elevationRange * 0.075;
    const xTicksToReturn = shouldRenderMinimalTicks
      ? [rawChartData?.[0]?.[0] ?? 0, rawChartData?.[secondIndex]?.[0] ?? 0.5, rawChartData?.[lastIndex]?.[0] ?? 1.0]
      : [
          rawChartData?.[0]?.[0] as number,
          rawChartData?.[firstIndex]?.[0] as number,
          rawChartData?.[secondIndex]?.[0] as number,
          rawChartData?.[thirdIndex]?.[0] as number,
          rawChartData?.[rawChartData.length - 1]?.[0] as number
        ];
    const defaultXTicks = [0, 100];
    const defaultYTicks = displayMetric ? [-15, 15] : [-50, 50];
    const yTicksToReturn = [minYTick ?? -50, maxYTick ?? 50];
    return [minXToReturn, maxXToReturn, showEmptyData ? defaultXTicks : xTicksToReturn, showEmptyData ? defaultYTicks : yTicksToReturn];
  }, [rawChartData, shouldRenderMinimalTicks, displayMetric, showEmptyData]);

  const handleMouseMove = useCallback(
    (chart: CategoricalChartState) => {
      const coordinates = chart?.activePayload?.[0]?.payload?.coordinates;
      const cursorX = chart?.chartX || 0;
      const offset = 16;
      const tooltipWidth = tooltipRef.current?.clientWidth ?? 100;
      // position the tooltip to the left of the cursor when there is enough space to do so:
      const tooltipX = cursorX > tooltipWidth + offset ? cursorX - tooltipWidth - offset : cursorX + offset;
      setTooltipXPosition(tooltipX);

      if (!coordinates) {
        setCurrentGrade('');
        handleMouseOver?.(null);
      } else {
        const index = chart?.activeTooltipIndex;
        const cacheKey = JSON.stringify(index);
        if (index && rawChartData?.length > 3 && index > -1 && index < rawChartData.length - 1) {
          const grade = gradeCache?.current?.[cacheKey] || averageGrade(rawChartData, index, displayMetric);
          gradeCache.current[cacheKey] = grade;
          setCurrentGrade(grade ?? null);
        } else {
          setCurrentGrade('');
        }

        handleMouseOver?.([coordinates?.[1], coordinates?.[0]]);
      }
    },
    [tooltipRef, setCurrentGrade, handleMouseOver, rawChartData, displayMetric]
  );

  return { data, minX, maxX, xTicks, yTicks, tooltipXPosition, handleMouseMove };
};

export default useElevationChart;
