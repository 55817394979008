import { MultiPolygon } from 'geojson';
import Alert from '@alltrails/shared/types/alert';
import { Group } from '@alltrails/groups';

export type SearchResult = {
  type: 'project' | 'geo_boundary_trail_filter';
  id: number;
  name: string;
  creatorId: number | null;
  updatedAt: string;
  projectId: number | null;
};

export type Project = {
  id?: number;
  name: string;
  creatorId: number;
  updatedAt: string;
  geoBoundaryTrailFilters: GeoBoundaryTrailFilter[];
};

export type GeoBoundary = {
  id: number;
  boundaries: MultiPolygon;
  readOnly?: boolean;
};

export enum AssignmentType {
  GROUP = 'group',
  ALERT = 'alert',
  AREA = 'area'
}
export type AssignmentObject = Group | Alert;

export type GeoBoundaryTrailFilter = {
  id: number;
  name: string;
  creatorId: number;
  projectId?: number;
  trailheadIn: boolean | null;
  percentageHigh: number;
  percentageLow: number;
  exclusionIds: number[];
  assignmentId?: number;
  assignmentType: AssignmentType;
  updatedAt?: string;
  geoBoundary: GeoBoundary;
  project?: Project;
  assignmentObject?: AssignmentObject;
};

export type IntersectingTrail = {
  id: number; // this is the trail_id
  route: string;
  percentage: number;
  trailHeadWithinBoundary: boolean;
  trailData: string;
  meetsFilterCriteria?: boolean;
};
