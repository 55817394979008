import { Expression, LngLatBounds } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Feature } from 'geojson';
import isEqual from 'lodash.isequal';
import useMap from '../../hooks/useMap';
import { geoBoundariesOverlayId, geoBoundariesOverlayOutlineId } from '../../utils/layers';
import useGeoBoundariesSearch from '../../hooks/useGeoBoundariesSearch';
import geoBoundarySearchResultsToFeatureCollection from '../../utils/geoBoundarySearchResultsToFeatureCollection';
import { BoundaryFilterType } from '../../types/Styles';

type GeoBoundariesOverlayProps = {
  bounds?: LngLatBounds;
};

// These are one-off colors for this overlay specifically. No tokens exist.
const colorExpression: Expression = [
  'match',
  ['get', 'type'],
  BoundaryFilterType.osm,
  '#86570D',
  BoundaryFilterType.groupPermissions,
  '#0779FF',
  BoundaryFilterType.alertClosure,
  '#DD2712',
  BoundaryFilterType.alertNonClosure,
  '#FFC900',
  '#5E7A8C' /* other */
];

const GeoBoundariesOverlay = ({ bounds }: GeoBoundariesOverlayProps) => {
  const map = useMap();
  const results = useGeoBoundariesSearch({ bounds });
  const geoBoundariesCollection = useMemo(() => geoBoundarySearchResultsToFeatureCollection(results), [results]);
  const [hoveredResults, setHoveredResults] = useState<Feature[]>([]);

  const onMouseEnter = useCallback(
    e => {
      const currentFeatureIds = e.features.map(feature => feature.id);
      const hoveredFeatureIds = hoveredResults.map(feature => feature.id);

      if (!isEqual(currentFeatureIds, hoveredFeatureIds)) {
        setHoveredResults(e.features);
      }
    },
    [hoveredResults]
  );

  // handle boundary hovered results https://alltrails.atlassian.net/browse/DISCO-10804
  // loggin results for QA
  useEffect(() => {
    console.log(hoveredResults);
  }, [hoveredResults]);

  const onMouseLeave = useCallback(e => {
    setHoveredResults([]);
  }, []);

  useEffect(() => {
    map?.on('mousemove', geoBoundariesOverlayId, onMouseEnter);
    map?.on('mouseleave', geoBoundariesOverlayId, onMouseLeave);
    return () => {
      map?.off('mousemove', geoBoundariesOverlayId, onMouseEnter);
      map?.off('mouseleave', geoBoundariesOverlayId, onMouseLeave);
    };
  }, [map, onMouseEnter, onMouseLeave]);

  return geoBoundariesCollection ? (
    <Source type="geojson" data={geoBoundariesCollection}>
      <Layer
        id={geoBoundariesOverlayId}
        type="fill"
        paint={{
          'fill-color': colorExpression,
          'fill-opacity': ['case', ['in', ['get', 'id'], ['literal', hoveredResults.map(feature => feature.id)]], 0.3, 0.2]
        }}
      />
      <Layer
        id={geoBoundariesOverlayOutlineId}
        beforeId={geoBoundariesOverlayId}
        type="line"
        paint={{
          'line-color': colorExpression,
          'line-width': ['case', ['in', ['get', 'id'], ['literal', hoveredResults.map(feature => feature.id)]], 4, 3]
        }}
      />
    </Source>
  ) : null;
};

export default GeoBoundariesOverlay;
