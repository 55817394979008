import { useMemo } from 'react';
import { lineString } from '@turf/helpers';
import { COLOR_GREEN_700, COLOR_UI_HIGHLIGHT } from '@alltrails/denali/tokens';
import { LngLat } from '../../types/Geo';
import PolylineSource from './PolylineSource';

type Props = {
  coordinates: LngLat[];
  lineColor?: string;
  lineOutlineColor?: string;
  lineProgress?: number;
  uniqueId?: string;
};

/**
 * Polyline renders a route for the currently selected result
 *
 * Because this is dynamically mounted it will naturally appear on top of pins,
 * which is the desired design. Any selected Marker will naturally appear on top
 * of this Polyline, which is also the desired design.
 *
 * @returns
 */
const Polyline = ({ coordinates, lineColor = COLOR_UI_HIGHLIGHT, lineOutlineColor = COLOR_GREEN_700, lineProgress = 1, uniqueId }: Props) => {
  // lineString requires multiple points, otherwise it throws an error
  const data = useMemo(() => {
    if (coordinates.length > 1) {
      return lineString(coordinates);
    }
    return null;
  }, [coordinates]);

  return data ? (
    <PolylineSource data={data} lineColor={lineColor} lineOutlineColor={lineOutlineColor} lineProgress={lineProgress} uniqueId={uniqueId} />
  ) : null;
};

export default Polyline;
