import { COLOR_NEUTRAL_WHITE } from '@alltrails/denali/tokens';

export const circleShadowId = 'circle-shadow';
export const circleShadowDef = `<radialGradient id="${circleShadowId}">
  <stop stop-color="#000000" stop-opacity="0.3"/>
  <stop offset="70%" stop-color="#000000" stop-opacity="0.3"/>
  <stop offset="100%" stop-color="#000000" stop-opacity="0"/>
</radialGradient>`;

type CreateCircleMarkerArgs = {
  additionalDefs?: string;
  borderFill?: string;
  innerDiameter: number;
  innerFill: string;
};
export const createCircleMarker = ({ additionalDefs, borderFill = COLOR_NEUTRAL_WHITE, innerDiameter, innerFill }: CreateCircleMarkerArgs) => {
  const borderWidth = 2;
  const shadowWidth = 2;
  const size = innerDiameter + 2 * (borderWidth + shadowWidth);
  const halfSize = size / 2;

  return `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      ${circleShadowDef}
      ${additionalDefs}
    </defs>
    <circle cx="${halfSize}" cy="${halfSize}" r="${halfSize}" fill="url(#${circleShadowId})" />
    <circle cx="${halfSize}" cy="${halfSize}" r="${halfSize - shadowWidth}" fill="${borderFill}" />
    <circle cx="${halfSize}" cy="${halfSize}" r="${halfSize - shadowWidth - borderWidth}" fill="${innerFill}"  />
  </svg>`;
};

type CreatePinMarkerArgs = {
  borderFill?: string;
  innerFill: string;
  renderInnerDot?: boolean;
};
export const createPinMarker = ({ borderFill = COLOR_NEUTRAL_WHITE, innerFill, renderInnerDot }: CreatePinMarkerArgs) => {
  const dot = renderInnerDot ? `<circle cx="15" cy="15.25" r="3" fill="${borderFill}"/>` : '';

  return `<svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <radialGradient id="pin-shadow" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15 32) rotate(90) scale(3.5 10.5)">
        <stop stop-opacity="0.24"/>
        <stop offset="1" stop-color="#8F8F8F" stop-opacity="0"/>
      </radialGradient>
      <filter id="pin-drop-shadow" x="-0.25" y="-0.25" width="30.5" height="33.5825" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="0.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="blend-result"/>
        <feBlend mode="normal" in="SourceGraphic" in2="blend-result" result="shape"/>
      </filter>
    </defs>
    <path d="M27 32C27 34.2091 21.6274 36 15 36C8.37258 36 3 34.2091 3 32C3 29.7909 8.37258 28 15 28C21.6274 28 27 29.7909 27 32Z" fill="url(#pin-shadow)"/>
    <g filter="url(#pin-drop-shadow)">
      <path d="M28.25 15C28.25 17.784 27.3914 20.3674 25.9245 22.5C24.0682 25.1988 18.4997 29.2892 16.1096 30.9785C15.4419 31.4504 14.5581 31.4504 13.8904 30.9785C11.5003 29.2892 5.93184 25.1988 4.07547 22.5C2.60861 20.3674 1.75 17.784 1.75 15C1.75 7.68223 7.68223 1.75 15 1.75C22.3178 1.75 28.25 7.68223 28.25 15Z" fill="${innerFill}" stroke="${borderFill}" stroke-width="2"/>
    </g>
    ${dot}
  </svg>`;
};
export const pinMarkerHeight = 36;
export const pinMarkerWidth = 30;
export const pinMarkerShadowShadowHeight = 4;
