import { useSelector } from '../redux';

const useHoveredAdminResult = () => {
  const { hoveredAdminResult } = useSelector(state => ({
    hoveredAdminResult: state.map.hoveredAdminResult
  }));

  return hoveredAdminResult;
};

export default useHoveredAdminResult;
