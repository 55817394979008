'use client';

import logDirectionsButtonTapped from '@alltrails/analytics/events/logDirectionsButtonTapped';
import logPrintMapButtonClicked from '@alltrails/analytics/events/logPrintMapButtonClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import MapLocation from '@alltrails/analytics/enums/MapLocation';
import IconButton from '@alltrails/denali/components/IconButton';
import Car from '@alltrails/denali/icons/Car';
import Print from '@alltrails/denali/icons/Print';
import Share from '@alltrails/denali/icons/Share';
import PlusBadge from '@alltrails/denali/components/PlusBadge';
import { useIntl } from '@alltrails/shared/react-intl';
import { useLanguageRegionCode } from '@alltrails/language';
import { useUser } from '@alltrails/context';
import useRoadblock from '@alltrails/layout/hooks/useRoadblock';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { ShareObject } from '@alltrails/sharing';
import { useModal } from '../../ShareModal/ShareModalContext';
import styles from './DesktopButtons.module.scss';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';

type Props = {
  directionsLink?: string;
  printLink?: string;
  shareObject?: ShareObject;
  roadblockPrefix?: LocationDetailsResponseType;
  id: number;
  pageType?: PageType;
};

const DesktopButtons = ({ directionsLink, printLink, shareObject, roadblockPrefix, id, pageType }: Props) => {
  const { openModal } = useModal();
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => setCurrentUrl(`${pathname}?${searchParams}`), [pathname, searchParams]);

  const onRoadblock = useRoadblock({
    afterRoadblock: directionsLink || '',
    eventName: `${roadblockPrefix}_directions`,
    languageRegionCode,
    returnToUrl: currentUrl,
    user,
    setIsInterstitialOpen: () => false, // this will never render at desktop widths
    openInNewTab: true
  });

  const roadblockDirections = !user;

  const onDirectionsClick = useCallback(() => {
    logDirectionsButtonTapped({ id, page_type: pageType });
    if (roadblockDirections) {
      onRoadblock();
    } else {
      return undefined;
    }
  }, [id]);

  return (
    <div className={styles.desktopButtonsContainer}>
      {printLink && (
        <div className={styles.iconContainer}>
          <IconButton
            icon={{ Component: Print }}
            linkInfo={{ href: printLink, prefetch: false }}
            onClick={() => logPrintMapButtonClicked({ map_location: MapLocation.LocationPage, trail_id: id })}
            title={intl.formatMessage({ defaultMessage: 'Print map' })}
            testId="location-page-print"
            size="md"
          />
          <PlusBadge className={styles.plusBadge} size="sm" />
        </div>
      )}
      {shareObject && (
        <IconButton
          icon={{ Component: Share }}
          onClick={openModal}
          title={intl.formatMessage({ defaultMessage: 'Share' })}
          testId="location-page-share"
          size="md"
        />
      )}
      {directionsLink && (
        <IconButton
          icon={{ Component: Car }}
          linkInfo={roadblockDirections ? undefined : { href: directionsLink, target: '_blank', rel: ['noopener', 'noreferrer'], prefetch: false }}
          onClick={onDirectionsClick}
          title={intl.formatMessage({ defaultMessage: 'Directions' })}
          testId="location-page-directions"
          size="md"
        />
      )}
    </div>
  );
};

export default DesktopButtons;
