import Icon from '../components/Icon';

const MapsThreeD = Icon(
  'maps-three-d',
  <>
    <path d="M7.336 11.367c.53.128 1.1.384 1.579.776.638.523 1.116 1.29 1.116 2.316 0 1.088-.481 2.028-1.35 2.692-.865.663-2.111 1.049-3.64 1.049-2.35 0-4.106-.935-4.99-2.096L0 16.038l1.254-1.371.074.084c.822.94 2.176 1.57 3.653 1.57.908 0 1.621-.205 2.105-.561.48-.354.74-.861.74-1.49 0-.663-.281-1.125-.794-1.428-.521-.308-1.287-.453-2.252-.453-.545 0-1.24 0-1.434.018l-.109.01v-1.935l.109.009c.214.018.91.018 1.434.018.803 0 1.52-.14 2.033-.432.507-.29.813-.726.813-1.335 0-.603-.298-1.064-.793-1.378-.497-.317-1.193-.485-1.973-.485-1.374 0-2.452.498-3.418 1.425l-.074.071L.176 7.063l.06-.068C1.257 5.861 2.868 5 5.041 5c1.377 0 2.57.32 3.424.914.856.597 1.366 1.471 1.366 2.563 0 .912-.413 1.608-.992 2.102a4.094 4.094 0 0 1-1.503.788Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.638 5.092h4.853c2.099 0 3.852.69 5.08 1.852 1.23 1.162 1.929 2.792 1.929 4.662 0 1.878-.7 3.504-1.929 4.66-1.229 1.155-2.982 1.834-5.08 1.834h-4.853V5.092ZM16.49 16.21c1.513 0 2.7-.52 3.507-1.35.809-.831 1.245-1.981 1.245-3.254 0-1.294-.422-2.449-1.223-3.28-.8-.83-1.985-1.344-3.53-1.344h-2.655v9.228h2.655Z"
    />
  </>
);

export default MapsThreeD;
