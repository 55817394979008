import { type MapRef, type ViewState } from 'react-map-gl';
import { loadImages } from '../../utils/images';
import { Images } from '../../types/Images';

export function onMoveEndHandler(viewState: ViewState, mapRef?: MapRef, onMoveEnd?: ({ center, bounds, zoom }) => void) {
  onMoveEnd?.({ center: mapRef?.getCenter(), bounds: mapRef?.getBounds(), zoom: viewState.zoom });
}

// Mapbox behavior around style changes is historically finnicky. We must use
// this event for the sake of image loading, but use it vary sparingly otherwise
//
// * https://github.com/mapbox/mapbox-gl-js/issues/8660
// * https://github.com/mapbox/mapbox-gl-js/issues/2268
// * https://github.com/mapbox/mapbox-gl-js/issues/3970
// * https://github.com/mapbox/mapbox-gl-js/issues/8691
// * https://github.com/mapbox/mapbox-gl-js/issues/4006
// * https://github.com/mapbox/mapbox-gl-js/issues/9779
//
// Note that if called multiple times in quick succession this function will load images multiple times
// This handler would load images more quickly if we passed the event instead of mapRef and accessed event.target
// but doing so leads to even more duplicate image loading since we eliminate the delay of mapRef being set
export function onStyleDataHandler(
  images: Images,
  mapRef: MapRef | undefined,
  isInitialized: boolean,
  setIsInitialized: (isInitialized: boolean) => void
) {
  if (!mapRef) {
    return;
  }

  // Since this is the only place images are loaded assume that if any of the images are already loaded all images are either already loaded or are currently loading
  // This reduces the chance of images being loaded multiple times if this handler is called multiple times in quick succession
  if (!isInitialized && Object.keys(images).some(imageName => mapRef.hasImage(imageName))) {
    return;
  }

  // Although this eventually invokes map.addImage() the image is being
  // set on a given style. We must re-add images per-style as needed if
  // the style is swapped out.
  loadImages(images, mapRef, setIsInitialized);
}
