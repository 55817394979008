import { AdminPinImageConfig } from '../../../types/Images';
import clusterImageSelected from '../../trail/clusterSelected.png';
import pinImageSelected from './pinSelected.png';
import pinImage from './pin.png';
import clusterImage from './cluster.png';

const mapImages: AdminPinImageConfig = {
  pinImage: pinImage.src,
  pinImageSelected: pinImageSelected.src,
  clusterImage: clusterImage.src,
  clusterImageSelected: clusterImageSelected.src
};

export default mapImages;
