'use client';

import { AlgoliaTrail } from '@alltrails/trails';
import { useReduxListItems } from '@alltrails/lists';
import { useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/denali/components/Button';
import { useCallback, useRef } from 'react';
import logExploreMoreTrailsClicked from '@alltrails/analytics/events/logExploreMoreTrailsClicked';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import TopTrailsCard from './TopTrailsCard';
import styles from './TopResults.module.scss';
import { getAnalyticsCardLocation } from '@/utils/getAnalyticsCardLocation';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';
import { getAnalyticsPageType } from '@/utils/getAnalyticsPageType';

type Props = {
  trails: AlgoliaTrail[];
  exploreLink?: string;
  type?: LocationDetailsResponseType;
};

const TopTrails = ({ trails, exploreLink, type }: Props) => {
  const pageType = getAnalyticsPageType(type);
  const { isFavorite, handleFavoriteClick, listModal, signUpModal } = useReduxListItems(undefined, undefined, {
    page: PageStrings.SEO_PAGE,
    detailedCardLocation: getAnalyticsCardLocation(type)
  });
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.Highlights, pageType, { threshold: 0.1 });

  const handleClickExploreMore = useCallback(() => {
    if (pageType) {
      logExploreMoreTrailsClicked({
        page_type: pageType
      });
    }
  }, [pageType]);

  return (
    <div ref={ref} className={styles.topResultsContainer}>
      {trails?.map((trail, idx) => {
        return (
          <TopTrailsCard
            key={trail.ID}
            trail={trail}
            ranking={idx + 1}
            showBorder={idx !== trails.length - 1}
            isFavorite={isFavorite('trail', trail.ID)}
            onFavoriteClick={handleFavoriteClick}
          />
        );
      })}
      {exploreLink && (
        <Button
          className={styles.exploreMore}
          text={intl.formatMessage({ defaultMessage: 'Explore more trails' })}
          testId="trail-list-explore-more"
          linkInfo={{ href: exploreLink, outsideOfMugen: true }}
          onClick={handleClickExploreMore}
        />
      )}
      {listModal}
      {signUpModal}
    </div>
  );
};

export default TopTrails;
