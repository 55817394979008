'use client';

import { useRef } from 'react';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import PhotoSlash from '@alltrails/denali/icons/PhotoSlash';
import { apiKey } from '@alltrails/networking';
import logPhotoCarouselSwiped from '@alltrails/analytics/events/logPhotoCarouselSwiped';
import PhotoCarouselType from '@alltrails/analytics/enums/PhotoCarouselType';
import PageType from '@alltrails/analytics/enums/PageType';
import SwiperCarousel from '@alltrails/shared/components/SwiperCarousel';
import { useMediaQuery } from '@alltrails/core';
import { BREAKPOINT_SM_SIZE } from '@alltrails/denali/tokens';
import { useLightbox } from '../Lightbox/LightboxContext';
import styles from './PhotoHeader.module.scss';
import LocationPhotoCard from './LocationPhotoCard';
import { PhotoHash } from '@/types/shared';

type Props = {
  photos: PhotoHash[];
  locationDetails: any;
  id?: number;
  pageType?: PageType;
};

const PhotoHeader = ({ photos, locationDetails, id, pageType }: Props) => {
  const { openLightbox } = useLightbox();
  const swiper = useRef(false);
  const { type, locationSlug, name, city, state, country } = locationDetails;
  const getImageUrl = (id: number, photoHash: string) => {
    if (id && photoHash) {
      return getPhotoUrl({ id, photoHash }, PhotoSize.LargerWide, true);
    }
    return `/api/alltrails/photos/${id}/image?size=large&key=${apiKey}`;
  };
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_SM_SIZE}px)`);

  if (photos.length === 0 && !isMobile) {
    return <div className={styles.emptyPhoto} />;
  }

  return (
    <div className={styles.photoHeaderContainer}>
      {photos.length > 0 ? (
        <SwiperCarousel
          className={styles.carouselContainer}
          uniqueId="locations-photos-carousel"
          items={photos}
          hasPaginationGradient
          renderItem={(photo, photoIndex) => (
            <LocationPhotoCard
              photoCount={photos.length}
              photoId={(photo as PhotoHash).id}
              key={(photo as PhotoHash).id}
              index={photoIndex}
              url={getImageUrl((photo as PhotoHash).id, (photo as PhotoHash).photoHash)}
              openLightbox={openLightbox}
              locationSlug={locationSlug}
              locationDetails={{ type, city, name, state, country }}
              pageType={pageType}
            />
          )}
          getItemKey={(photo: any) => String(photo.id)}
          showPaginationDots={photos.length > 1}
          hideSlideOverflow
          arrowStyle="subtle-with-standard-arrows"
          scrollMode={photos.length < 2}
          onSlideChange={() => {
            if (!swiper.current && pageType && id) {
              logPhotoCarouselSwiped({ carousel_item_id: String(id), page_type: pageType, photo_carousel_type: PhotoCarouselType.HeroImage });
              swiper.current = true;
            }
          }}
        />
      ) : (
        <div className={styles.noImage}>
          <PhotoSlash color="--color-text-secondary" size="lg" />
        </div>
      )}
    </div>
  );
};

export default PhotoHeader;
