import { useEffect } from 'react';
import { LngLatBounds } from 'mapbox-gl';
import useAlgoliaIndex from '@alltrails/search/hooks/useAlgoliaIndex';
import { useSelector } from '../../redux';
import HeatmapLayer from './HeatmapLayer';
import { getBoundingBoxParams } from './algoliaUtils';
import useFilteredResults from './useFilteredResults';

type HeatmapOverlayProps = {
  bounds?: LngLatBounds;
  maxResultCount?: number;
  minimumDate?: Date;
};

const HeatmapOverlay = ({ bounds, maxResultCount, minimumDate }: HeatmapOverlayProps) => {
  const algoliaConfigs = useSelector(state => state.algoliaConfigs);
  const index = useAlgoliaIndex(algoliaConfigs, ['trail']);
  const [results, setResults] = useFilteredResults(maxResultCount, minimumDate);

  useEffect(() => {
    if (!bounds) {
      return;
    }
    const getResults = async () => {
      const result = await index.search('', {
        hitsPerPage: 500,
        facetFilters: ['type:trail'],
        ...getBoundingBoxParams(bounds)
      });
      setResults(result.hits);
    };
    getResults();
  }, [bounds, index, setResults]);

  return <HeatmapLayer results={results} type="trail" />;
};

export default HeatmapOverlay;
