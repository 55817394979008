import Icon from '../components/Icon';

const Layers = Icon(
  'layers',
  <>
    <path
      fillRule="evenodd"
      d="M10.859 1.497a2.75 2.75 0 0 1 2.282 0l8.586 3.909a1.75 1.75 0 0 1 0 3.198l-8.576 3.898a2.75 2.75 0 0 1-2.282 0L2.293 8.594a1.75 1.75 0 0 1 0-3.198zM12 2.75a1.25 1.25 0 0 0-.519.112L2.903 6.766a.25.25 0 0 0 0 .458l.008.003 8.58 3.91a1.25 1.25 0 0 0 1.038 0l8.588-3.903a.25.25 0 0 0 0-.458l-.008-.003-8.59-3.91A1.25 1.25 0 0 0 12 2.75"
      clipRule="evenodd"
    />
    <path d="M1.317 17.34a.75.75 0 0 1 .993-.373l9.171 4.16a1.25 1.25 0 0 0 1.038 0l9.171-4.16a.75.75 0 1 1 .62 1.366l-9.17 4.16a2.75 2.75 0 0 1-2.28 0l-9.17-4.16a.75.75 0 0 1-.373-.993" />
    <path d="M2.31 11.967a.75.75 0 1 0-.62 1.366l9.17 4.16a2.75 2.75 0 0 0 2.28 0l9.17-4.16a.75.75 0 0 0-.62-1.366l-9.171 4.16a1.25 1.25 0 0 1-1.038 0z" />
  </>
);

export default Layers;
