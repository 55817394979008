import { Layer, Source } from 'react-map-gl';
import { useAuthorization } from '@alltrails/context';
import type { StaticStyleId } from '../../types/Styles';
import { getStaticStyle } from '../../utils/getStyle';
import { useSelector } from '../../redux';

type Props = {
  styleId: StaticStyleId;
};

const StaticStyle = ({ styleId }: Props) => {
  const mapboxStyleIds = useSelector(state => state.map.mapboxStyleIds);
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });
  const isPLP = hasPermission({ permission: 'public_lands:manage' });
  const hideCustomAttribution = isPLP && !isAdmin;
  const { adminStyleSettings } = useSelector(state => ({
    adminStyleSettings: state.map.adminStyleSettings
  }));

  const mapStyle = getStaticStyle({ adminStyleSettings, styleId, hideCustomAttribution, mapboxStyleIds });

  // Deconstruct the Mapbox style so that we can manually specify the source and layer.
  const source = mapStyle.sources[styleId];
  const layer = mapStyle.layers[0];

  return (
    <Source {...source}>
      <Layer {...layer} />
    </Source>
  );
};

export default StaticStyle;
