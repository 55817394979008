import { ComponentProps } from 'react';
import { Marker } from 'react-map-gl';

type MapMarkerProps = Pick<ComponentProps<typeof Marker>, 'anchor' | 'children' | 'offset' | 'latitude' | 'longitude'>;

const MapMarker = (props: MapMarkerProps) => {
  return <Marker {...props} />;
};

export default MapMarker;
