import { type Position, type Properties, lineString } from '@turf/helpers';
import turfAlong from '@turf/along';
import turfBearing from '@turf/bearing';
import turfDistance from '@turf/distance';
import turfLength from '@turf/length';
import { AllTrailsBounds } from '../../types/Geo';

export type CameraPosition = {
  bearing: number;
  location: Position;
  pathPercentage: number;
};

export type Path = ReturnType<typeof lineString<Properties>>;

const averageTrailLengthKm = 15.77;
const stdDevTrailLengthKm = 46.08;

export const minZoom = 13;
export const maxZoom = 17;
export const maxBearingVel = 25;

// Calculated from animating a marker over [Snow Lake Trail](https://www.alltrails.com/trail/us/washington/snow-lake-trail) in 40 seconds
export const defaultSpeed = 252.38996618; // m/s

/**
 * Gets the speed in which the marker should travel.
 *
 * @param km distance of the route
 * @returns speed (in m/s)
 */
export const getAnimationSpeed = (km: number) => {
  let speed = defaultSpeed;

  if (km < 0.804) {
    // half mile
    return 50;
  } else if (km < 4.828) {
    // 3 miles
    return 150;
  }

  return speed;
};

export const getBounds = (atMap: any): AllTrailsBounds | undefined => {
  if (atMap.bounds) {
    return {
      longitudeTopLeft: Number(atMap.bounds.longitudeTopLeft),
      latitudeTopLeft: Number(atMap.bounds.latitudeTopLeft),
      longitudeBottomRight: Number(atMap.bounds.longitudeBottomRight),
      latitudeBottomRight: Number(atMap.bounds.latitudeBottomRight),
      padding: 20
    };
  }
  return undefined;
};

/**
 * Gets a mapbox zoom level for the flyover animation based on the distance of the route.
 * Applies z-scale normalization to get a relative value based on route length, then cube root transforms the value as
 * our trails skews towards shorter trails.
 *
 * More info: https://medium.com/@TheDataGyan/day-8-data-transformation-skewness-normalization-and-much-more-4c144d370e55
 *
 * @param km distance of the route
 * @returns zoom level between 13-17. If there is not a distance, returns the maximum zoom level.
 */
export const getInitialZoom = (km: number) => {
  if (km <= 0) {
    return maxZoom;
  }

  const zScale = (km - averageTrailLengthKm) / stdDevTrailLengthKm;
  const normalizedZoom = Math.cbrt(zScale) + (maxZoom - (maxZoom - minZoom) / 2);
  const clampedZoom = Math.min(Math.max(normalizedZoom, minZoom), maxZoom);
  return maxZoom - (clampedZoom - minZoom);
};

/**
 * Computes a series of positions based off of a given path.
 * @param cameraPath the camera path
 * @returns an array of CameraPosition objects based on each coordinate inside the cameraPath
 * (coordinates, bearing to the next coordinate, percentage)
 */
export const getCameraPositions = (cameraPath: Path): CameraPosition[] => {
  const cameraPathDistKm = turfLength(cameraPath);
  let prevPercentage = 0;
  const { coordinates } = cameraPath.geometry;
  const cameraPositions = [] as CameraPosition[];

  if (!coordinates.length || !cameraPathDistKm) {
    return cameraPositions;
  }

  coordinates.forEach((coordinate: Position, i: number) => {
    if (i === coordinates.length - 1) {
      return;
    }

    const pointA = coordinate;
    const pointB = coordinates[i + 1];
    const bearing = turfBearing(pointA, pointB);
    const dist = turfDistance(pointA, pointB);
    const pathPercentage = dist / cameraPathDistKm;

    cameraPositions.push({ location: coordinate, bearing, pathPercentage: prevPercentage });
    prevPercentage = pathPercentage;
  });

  cameraPositions.push({
    location: coordinates[coordinates.length - 1],
    bearing: cameraPositions[cameraPositions.length - 1].bearing,
    pathPercentage: prevPercentage
  });

  return cameraPositions;
};

/**
 * Given a pre-calculated set of positions & a percentage complete, determines the next camera position.
 * Example: if we have 3 camera positions at progresses [0, 0.4, 1.0], a progressPercentage of 0.2 would return the position at progress of 0.4.
 * @param cameraPositions array of all positions to animate over
 * @param progressPercentage percentage complete of the animation
 * @returns the proceeding camera position based off the percentage
 */
export const getNextCameraPosition = (cameraPositions: CameraPosition[], progressPercentage: number): CameraPosition | undefined => {
  if (cameraPositions.length < 1) {
    return undefined;
  }

  let targetPosition = cameraPositions[cameraPositions.length - 1];
  let accumulatedPercentage = 0;

  cameraPositions.every(position => {
    accumulatedPercentage += position.pathPercentage;
    targetPosition = position;
    return accumulatedPercentage <= progressPercentage;
  });

  return targetPosition;
};

/**
 * Given two bearings, calculates the shortest degree difference.
 * @param fromBearing bearing value (in degrees) between (-180, 180]
 * @param toBearing bearing value (in degrees) between (-180, 180]
 * @returns shortest degree difference between from & to bearings
 */
export const shortestAngularDiff = (fromBearing: number, toBearing: number): number => {
  const bearingDiff = ((toBearing - fromBearing + 180) % 360) - 180;
  return bearingDiff < -180 ? bearingDiff + 360 : bearingDiff;
};

/**
 * An easing function to return a value along a cos wave between [0, 1]
 * @param val a numeric value
 * @returns a clamped value between [0, 1] to a cos value
 */
const easeInOut = (val: number): number => {
  return 0.5 * (1 - Math.cos(val * Math.PI));
};

/**
 * Interpolates between bearings given a normalized value between [0, 1].
 * @param fromBearing starting bearing
 * @param toBearing destination bearing
 * @param percentageComplete value normalized between [0, 1], indicative of the percentage progress made over the whole animation
 * @param percentageSegment percentage of the total distance the current pathing segment is
 * @param totalDuration duration of the whole animation (in seconds)
 * @returns an interpolated bearing value between the fromBearing & toBearing
 */
export const interpolateBearing = (
  fromBearing: number,
  toBearing: number,
  percentageComplete: number,
  percentageSegment: number,
  totalDuration: number
): number => {
  const angularDiff = shortestAngularDiff(fromBearing, toBearing);
  const adjustedToBearing = fromBearing + angularDiff;

  const valueToStartMove = -(Math.abs(angularDiff) / (maxBearingVel * totalDuration * percentageSegment) - 1);

  if (percentageComplete < valueToStartMove) return fromBearing;

  const maxStartVal = Math.max(0, valueToStartMove);
  const adjustedVal = (percentageComplete - maxStartVal) / (1 - maxStartVal);
  const easedVal = easeInOut(adjustedVal);
  const easedValClamped = Math.min(1, Math.max(0, easedVal));

  return fromBearing * (1 - easedValClamped) + adjustedToBearing * easedValClamped;
};

/**
 * Linearly interpolates between positions given a normalized value between [0, 1].
 * @param fromPosition starting bearing
 * @param toPosition destination bearing
 * @param normalizedVal value normalized between [0, 1]
 * @returns an interpolated position value between the fromPosition & toPosition
 */
export const interpolatePosition = (fromPosition: Position, toPosition: Position, normalizedVal: number): Position => {
  const clampedVal = Math.min(1, Math.max(0, normalizedVal));
  const remainderVal = 1 - clampedVal;
  const interpolatedLon = fromPosition[0] * remainderVal + toPosition[0] * clampedVal;
  const interpolatedLat = fromPosition[1] * remainderVal + toPosition[1] * clampedVal;
  return [interpolatedLon, interpolatedLat];
};

/**
 * Interpolates the camera position & bearing.
 * @param prevPosition previous camera position
 * @param nextPosition next camera position target
 * @param prevProgress progress percentage when the next position was changed
 * @param percentageComplete current progress percentage over the whole animation
 * @param totalDuration total duration of the animation (in seconds)
 * @returns interpolated camera position
 */
export function getInterpolatedCameraPosition(
  prevTarget: CameraPosition,
  nextTarget: CameraPosition,
  prevTargetProgress: number,
  progressPercentage: number,
  totalDuration: number
): CameraPosition {
  const interpolatedVal = (progressPercentage - prevTargetProgress) / nextTarget.pathPercentage;
  const clampedInterpolationVal = Math.min(1, Math.max(0, interpolatedVal));
  const location = interpolatePosition(prevTarget.location, nextTarget.location, clampedInterpolationVal);
  const bearing = interpolateBearing(prevTarget.bearing, nextTarget.bearing, clampedInterpolationVal, nextTarget.pathPercentage, totalDuration);

  return {
    location,
    bearing,
    pathPercentage: progressPercentage
  };
}

/**
 * Given pathing information, calculates the position along the path along with the bearing of it relative to another point.
 * @param path line string representing a path in which to calculate position values from
 * @param pathDistance the total distance of the path
 * @param percentageComplete a value from 0 to 1 indicating a percentage along the path
 * @param previousPosition a separate position used to calculate bearing
 * @returns {object} an object with a `bearing`, `lngLat` of the position, and a position
 */
export const computePositionValues = (path: Path, pathDistance: number, percentageComplete: number, previousPosition?: Position) => {
  // Get the new latitude and longitude by sampling along the path
  const position = turfAlong(path, pathDistance * percentageComplete).geometry.coordinates;
  const lngLat = {
    lng: position[0],
    lat: position[1]
  };

  const bearing = previousPosition ? turfBearing(previousPosition, position) : null;

  return {
    bearing,
    lngLat,
    position
  };
};
