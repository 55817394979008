import { useEffect, useRef } from 'react';

/**
 * A hook to use requestAnimationFrame inside the react lifecycle.
 * - Initializes animation calls in a useEffect.
 * - On each frame, calls the provided `animationCallback` with the time delta between the current call and previous call.
 *
 * **NOTE:** when dealing with react states, typically update states with callbacks to assure the correct previous state is used.
 *
 * _Source:_ https://css-tricks.com/using-requestanimationframe-with-react-hooks/
 * @param animationCallback the function to run on each animation frame.
 * @param isPaused a boolean to denote whether the animation should be played or paused.
 * `false` will trigger another call to requestAnimationFrame.
 * `true` will call cancelAnimationFrame.
 * @param isPausedRef a ref to hold the value of isPaused - used as a safety net in the off chance that isPaused is not updated in time.
 * @param onAnimationCancelled a function to run immediately after the last & most recent frame (triggered by pausing or unmounting)
 * Use as a method to clean up or handle any side effects that might happen outside of the animation loop.
 * @param memoizedDeps a dependency array for the useEffect that triggers the beginning of the animation frame calls.
 */
const useAnimationFrame = (
  animationCallback: (time: DOMHighResTimeStamp) => void,
  isPaused = false,
  isPausedRef: React.MutableRefObject<boolean> = { current: false },
  onAnimationCancelled: () => void = () => {},
  memoizedDeps: React.DependencyList = []
) => {
  // Use useRef for mutable variables that we want to persist without triggering a re-render on their change
  const animation = useRef(0);
  const previousTimeRef = useRef<number>();

  const animationFunc = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      animationCallback(time);
    }
    previousTimeRef.current = time;

    if (!isPaused && !isPausedRef.current) {
      animation.current = requestAnimationFrame(animationFunc);
    }
  };

  useEffect(() => {
    if (!isPaused && !isPausedRef.current) {
      animation.current = requestAnimationFrame(animationFunc);
    } else {
      cancelAnimationFrame(animation.current);
      onAnimationCancelled();
    }

    return () => {
      cancelAnimationFrame(animation.current);
      onAnimationCancelled();
    };
  }, [isPaused, ...memoizedDeps]);
};

export default useAnimationFrame;
