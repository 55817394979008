import { useState, ComponentProps } from 'react';
import classNames from 'classnames';
import { useAuthorization } from '@alltrails/context';
import ElevationChart from '../ElevationChart';
import MinimizationButton from './MinimizationButton';
import styles from './styles/styles.module.scss';

export type LegacyElevationChartProps = {
  className?: string;
} & Pick<ComponentProps<typeof ElevationChart>, 'handleMouseOver' | 'map' | 'polylines'>;

const LegacyElevationChart = ({ className, ...elvationChartProps }: LegacyElevationChartProps): JSX.Element | null => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });

  return (
    <>
      {isAdmin && <MinimizationButton isHidden={isHidden} setIsHidden={setIsHidden} />}
      <div
        className={classNames(styles.chartContainer, className, {
          [styles.isHidden]: isHidden
        })}
        data-testid="elevation-chart"
      >
        <ElevationChart {...elvationChartProps} />
      </div>
    </>
  );
};

export default LegacyElevationChart;
