import { AlertId, AlertType, ObjectType } from '@alltrails/shared/types/alert';
import { Group, GroupId } from '@alltrails/groups';

export enum AlgoliaObjectType {
  Area = 'area',
  Trail = 'trail',
  Alert = 'alert'
}

export type GroupSearchParams = {
  indexId: string;
  groups: Group[];
  query?: string;
  filters?: string;
};

export const or = (filters: string[]) => `(${filters.join(' OR ')})`;
export const and = (filters: string[]) => filters.join(' AND ');
export const getObjectIdFilters = (type: AlgoliaObjectType, ids: number[] | string[]) => or(ids.map(id => `objectID:${type}-${id}`));
export const getNotObjectIdFilters = (type: AlgoliaObjectType, ids: number[] | string[]) => and(ids.map(id => `NOT objectID:${type}-${id}`));
export const getTypeFilters = (types: AlgoliaObjectType[]) => or(types.map(type => `type:${type}`));
export const getGroupFilters = (groups: Group[]) => or(groups.map(group => `group_ids:${group.id}`));
export const getGroupIdFilters = (groupIds: GroupId[]) => or(groupIds.map(groupId => `group_ids:${groupId}`));
export const getAlertIdFilters = (alertIds: AlertId[]) => or(alertIds.map(alertId => `alert_ids:${alertId}`));
export const getAreaIdFilters = (areaId: number) => `area_id=${areaId}`;
export const getStatusFilters = (statuses: string[]) => or(statuses.map(status => `status:${status}`));
export const getAlertObjectTypeFilters = (objectTypes: ObjectType[]) => or(objectTypes.map(objectType => `object_type:${objectType}`));
export const getLengthFilters = (lengths: [number, number]) =>
  lengths[1] === -1 ? `(length>=${lengths[0]})` : `(length:${lengths[0]} TO ${lengths[1]})`;
export const getDifficultyFilters = (difficultyRatings: number[]) => or(difficultyRatings.map(alertType => `difficulty_rating:${alertType}`));
export const getRatingFilters = (rating: number) => `(avg_rating>=${rating})`;
export const getAlertTypeFilters = (alertTypes: AlertType[]) => or(alertTypes.map(alertType => `alert_types:${alertType}`));
