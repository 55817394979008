import Icon from '../components/Icon';

const MapsTwoD = Icon(
  'maps-two-d',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.414 16.433V18.1H10v-1.89H3.992c1.721-1.284 3.178-2.472 4.218-3.638 1.088-1.22 1.73-2.429 1.73-3.712 0-1.31-.58-2.305-1.468-2.968-.884-.661-2.07-.992-3.285-.992-1.88 0-3.728.719-4.85 2.072l-.056.068 1.315 1.392.072-.083c.8-.919 1.99-1.54 3.54-1.54.633 0 1.26.16 1.726.495.461.332.77.84.77 1.556 0 .967-.55 1.926-1.744 3.114-1.192 1.187-3.008 2.584-5.505 4.429l-.04.03ZM16.49 5.092h-4.852V18.1h4.853c2.098 0 3.851-.68 5.08-1.835 1.23-1.155 1.929-2.78 1.929-4.66 0-1.869-.7-3.499-1.928-4.661-1.23-1.163-2.982-1.852-5.081-1.852Zm3.508 9.768c-.808.83-1.994 1.35-3.508 1.35h-2.655V6.982h2.655c1.545 0 2.73.515 3.53 1.345.801.83 1.223 1.985 1.223 3.279 0 1.273-.436 2.423-1.245 3.254Z"
    />
  </>
);

export default MapsTwoD;
