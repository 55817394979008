// Docs: https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md

import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Feature, MultiPolygon as MultiPolygonType } from 'geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { COLOR_PLUS_600 } from '@alltrails/denali/tokens';
import { type LngLat } from 'react-map-gl';
import useMap from '../../hooks/useMap';
import { updateCursor } from '../../redux/reducer';
import MapMarker from '../MapMarker';

const styles = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': COLOR_PLUS_600,
      'line-width': 2
    }
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': COLOR_PLUS_600,
      'fill-outline-color': COLOR_PLUS_600,
      'fill-opacity': 0.15
    }
  },
  // polygon mid points
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': COLOR_PLUS_600
    }
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': COLOR_PLUS_600,
      'line-width': 3
    }
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': ['match', ['get', 'active'], 'true', 9, 7],
      'circle-color': '#FFF'
    }
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': ['match', ['get', 'active'], 'true', 6, 5],
      'circle-color': COLOR_PLUS_600
    }
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#000',
      'line-width': 3
    }
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    paint: {
      'fill-color': '#000',
      'fill-outline-color': '#000',
      'fill-opacity': 0.1
    }
  },
  // polygon outline
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#000',
      'line-width': 3
    }
  }
];

type Mode = 'draw_polygon' | 'simple_select';

type DrawControlsProps = {
  boundaries?: MultiPolygonType;
  onCreate?: (e: { features: Feature<MultiPolygonType>[] }) => void;
  onUpdate?: (e: { features: Feature<MultiPolygonType>[] }) => void;
  onModeChange?: (e: { mode: Mode }) => void;
  onSelectionChange?: (e: { features: Feature<MultiPolygonType>[] }) => void;
  mode?: Mode;
  startTooltip?: ReactNode;
};

const DrawControls = ({
  mode = 'draw_polygon',
  boundaries,
  onCreate,
  onUpdate,
  onModeChange,
  onSelectionChange,
  startTooltip
}: DrawControlsProps) => {
  const dispatch = useDispatch();
  const map = useMap();
  const [tooltipCoordinates, setTooltipCoordinates] = useState<LngLat | undefined>(undefined);
  const [hasStartedDrawing, setHasStartedDrawing] = useState(false);
  const [draw] = useState(
    new MapboxDraw({
      styles,
      displayControlsDefault: false,
      // starting mode. Clicking the feature will change mode to `polygon_edit`
      defaultMode: mode,
      // Must specify controls to enable key commands. Controls are hidden with css.
      controls: { trash: true, point: false, line_string: false, polygon: true, combine_features: false, uncombine_features: false }
    })
  );

  const onClick = useCallback(() => setHasStartedDrawing(true), []);

  useEffect(() => {
    map?.addControl(draw);
    draw.changeMode(mode);

    // allow mapbox draw to apply cursor styles
    dispatch(updateCursor('unset'));
    return () => {
      dispatch(updateCursor('auto'));
      map?.removeControl(draw);
    };
  }, [draw, map, dispatch, mode]);

  useEffect(() => {
    if (map?.hasControl(draw) && boundaries) {
      draw.deleteAll();
      draw.add(boundaries);
    }
  }, [draw, map, boundaries]);

  useEffect(() => {
    if (onCreate) {
      map?.on('draw.create', onCreate);
    }

    if (onUpdate) {
      map?.on('draw.update', onUpdate);
    }

    if (onModeChange) {
      map?.on('draw.modechange', onModeChange);
    }

    if (onSelectionChange) {
      map?.on('draw.selectionchange', onSelectionChange);
    }

    map?.on('click', onClick);

    return () => {
      if (onUpdate) {
        map?.off('draw.update', onUpdate);
      }
      if (onCreate) {
        map?.off('draw.create', onCreate);
      }
      if (onModeChange) {
        map?.off('draw.modechange', onModeChange);
      }
      if (onSelectionChange) {
        map?.off('draw.selectionchange', onSelectionChange);
      }
      map?.off('click', onClick);
    };
  }, [map, onCreate, onUpdate, onModeChange, onClick, onSelectionChange]);

  const mouseMove = useCallback(({ lngLat }) => startTooltip && setTooltipCoordinates(lngLat), [startTooltip]);
  const mouseOut = useCallback(() => startTooltip && setTooltipCoordinates(undefined), [startTooltip]);

  useEffect(() => {
    map?.on('mousemove', mouseMove);
    map?.on('mouseout', mouseOut);

    return () => {
      map?.off('mousemove', mouseMove);
      map?.off('mouseout', mouseOut);
    };
  }, [map, mouseMove, mouseOut]);

  return tooltipCoordinates && !hasStartedDrawing ? (
    <MapMarker longitude={tooltipCoordinates.lng} latitude={tooltipCoordinates.lat} anchor="left" offset={[20, 0]}>
      {startTooltip}
    </MapMarker>
  ) : null;
};

export default DrawControls;
