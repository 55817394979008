import { useIntl } from 'react-intl';
import { useAuthorization } from '@alltrails/context';
import { ButtonConfig } from '../../hooks/useAdminFooterCTAs';
import { AdminMapHit, AdminTrackHit } from './utils';

/**
 * This component currently only implements a subset of the functionality from the monolith.
 * See the method below for implementation of missing links:
 * https://github.com/alltrails/alltrails/blob/a00887a87114a5dd6a3fe0b2b6cb35db81eef537/app/javascript/application/javascripts/react_components/mixins/map/map_mixin.js#L647
 */

const useAdminTrackLinks = (track?: AdminTrackHit | AdminMapHit) => {
  const intl = useIntl();
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });
  const isPLP = hasPermission({ permission: 'public_lands:manage' });

  if (!isAdmin && !isPLP) {
    return;
  }

  if (!track) {
    return;
  }

  const linksConfig: ButtonConfig[] = [];
  // This check means this CTA is available to both Admins and PLP users
  // while currently duplicative this check is left in to indicate that such a check should be added for each CTA added to this hook since some will be admin-only
  if (!track.private && (isAdmin || isPLP)) {
    linksConfig.push({
      label: intl.formatMessage({ defaultMessage: 'Add as new trail' }),
      handleClick() {
        window.open(`/trail/new?recording=${track.ID}`, '_blank');
      }
    });
  }
  return linksConfig;
};

export default useAdminTrackLinks;
