import { useRef, useState, useEffect, useMemo } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, ReferenceLine } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { type Context } from '@alltrails/context';
import { COLOR_TEXT_PRIMARY, COLOR_BORDER_SEPARATOR, FONT_SIZE_50, FONT_FAMILY_PRIMARY, SPACE_200, SPACE_300 } from '@alltrails/denali/tokens';
import { useAuthorization } from '@alltrails/context';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import useElevationChart from '../../hooks/useElevationChart';
import useElevationData from '../../hooks/useElevationData';
import type { ActivityWithTracks, ElevationPoint, LngLat, MapWithRoutes, Polylines } from '../../types/Geo';
import ElevationTooltip from './ElevationTooltip';
import { XAxisTick, YAxisTick } from './Ticks';

export type ElevationChartProps = {
  handleMouseOver?: (coordinates: LngLat | null) => void;
  map?: Partial<ActivityWithTracks> | Partial<MapWithRoutes>; // The partials aren't great here but with the use of legacyGeoJSONConversions, its hard to pin down exactly what's needed
  polylines?: Polylines;
};

const ElevationChart = ({ handleMouseOver, map, polylines }: ElevationChartProps): JSX.Element | null => {
  const [rawChartData, setRawChartData] = useState<ElevationPoint[]>([]);
  const [showEmptyData, setShowEmptyData] = useState<boolean>(true);
  const [currentGrade, setCurrentGrade] = useState<number | string>('');
  const [referenceLineIndices, setReferenceLineIndices] = useState<number[] | null>(null);
  const isMobile = useIsMobileSizedScreen();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });

  const { displayMetric } = useSelector((state: { context: Context }) => ({
    displayMetric: state.context.displayMetric
  }));

  const { data, minX, maxX, xTicks, yTicks, tooltipXPosition, handleMouseMove } = useElevationChart({
    displayMetric,
    handleMouseOver,
    rawChartData,
    setCurrentGrade,
    shouldRenderMinimalTicks: isMobile,
    showEmptyData,
    tooltipRef
  });

  useElevationData({
    displayMetric,
    map,
    polylines,
    setRawChartData,
    setReferenceLineIndices
  });

  const noData = (
    <text
      x="50%"
      y="35%"
      dy={12}
      style={{ fontSize: FONT_SIZE_50, fill: COLOR_TEXT_PRIMARY, fontFamily: FONT_FAMILY_PRIMARY }}
      width={200}
      textAnchor="middle"
    >
      <FormattedMessage defaultMessage="No elevation data" />
    </text>
  );

  useEffect(() => {
    if (rawChartData?.length < 1) {
      setShowEmptyData(true);
    } else {
      setShowEmptyData(false);
    }
  }, [rawChartData]);

  const lineMargin = useMemo(() => {
    const responsiveMargin = isMobile ? SPACE_200 : SPACE_300;

    return {
      top: SPACE_200,
      right: responsiveMargin,
      bottom: 0,
      left: responsiveMargin
    };
  }, [isMobile]);

  return (
    <ResponsiveContainer>
      <LineChart data={data} margin={lineMargin} onMouseMove={handleMouseMove}>
        <XAxis
          dataKey="distance"
          tickLine={false}
          axisLine={{
            stroke: COLOR_BORDER_SEPARATOR,
            strokeWidth: 2
          }}
          tick={<XAxisTick maxX={maxX ?? null} displayMetric={displayMetric} minX={minX} />}
          tickFormatter={value => value.toFixed(1)}
          ticks={xTicks}
          interval={0}
          type="number"
          domain={[0, 'dataMax']}
        />
        <YAxis
          dataKey="elevation"
          orientation="right"
          axisLine={false}
          tickLine={false}
          tick={<YAxisTick maxY={yTicks[1]} displayMetric={displayMetric} />}
          domain={([dataMin, dataMax]) => {
            const percentOfRange = (dataMax - dataMin) * 0.15;
            return [dataMin - percentOfRange, dataMax + percentOfRange];
          }}
          ticks={yTicks}
          mirror
        />
        {showEmptyData && noData}
        <Tooltip
          content={<ElevationTooltip ref={tooltipRef} grade={currentGrade} displayMetric={displayMetric} isAdmin={isAdmin} />}
          isAnimationActive={false}
          position={{ x: tooltipXPosition, y: 40 }}
        />
        {referenceLineIndices?.map((dataIndex, index) => (
          <ReferenceLine
            data-testid={`reference-line-${index}`}
            key={dataIndex}
            x={dataIndex}
            stroke={COLOR_BORDER_SEPARATOR}
            strokeWidth={1}
            strokeDasharray="2 2"
          />
        ))}
        <Line
          hide={showEmptyData} // hide the line but show the axes and ticks
          type="linear"
          dataKey="elevation"
          stroke={COLOR_TEXT_PRIMARY}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ElevationChart;
