import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useAuthorization } from '@alltrails/context';
import { FormattedMessage } from 'react-intl';
import { Modal, useMultiStepModal } from '@alltrails/modal';
import TextInput from '@alltrails/denali/components/TextInput';
import { Typography } from '@alltrails/core';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import StyleSelectionCard from '../StyleSelectionCard';
import { useDispatch, useSelector } from '../../redux';
import { resetMapStyles, updateAdminStyleSettings } from '../../redux/reducer';
import { BaseStyleId, DynamicStyleId, StaticStyleId, StyleId } from '../../types/Styles';
import styles from './styles/styles.module.scss';
import filterHiddenStyles from './filterHiddenStyles';

const adminSectionStyles: (StaticStyleId | DynamicStyleId)[] = [
  'nearbyTrails',
  'geoBoundaries',
  'heatmap',
  'recordingsAll',
  'recordingsPop',
  'after',
  'limit',
  'pendingTrails',
  'waypoints',
  'photos',
  'mapsAll',
  'clusterTrails',
  'contribs',
  'distanceMarkers',
  'adminOverlay'
];

const mapTypeStyles: BaseStyleId[] = [
  'alltrailsOutdoorsV2',
  'roadmap',
  'alltrailsSatellite',
  'topo',
  'worldtopo',
  'worldparks',
  'openstreet',
  'opencycle',
  'googleSatellite',
  'adminBasemap'
];

const mapDetailStyles: (StaticStyleId | DynamicStyleId)[] = [
  'waypoints',
  'photos',
  'nearbyTrails',
  'distanceMarkers',
  'heatmap',
  'weather',
  'airQuality',
  'lightPollution',
  'pollen',
  'waymarkedCycling',
  'waymarkedHiking',
  'waymarkedMTB'
];

// PLP styles augments mapDetailStyles since PLP styles are based off of user styles
const plpStyles = mapDetailStyles.concat(['recordingsAll', 'after', 'limit', 'adminOverlay']);

type Props = {
  isOpen: boolean;
  onCloseRequest: () => void;
} & Pick<ComponentProps<typeof StyleSelectionCard>, 'onMapBaseLayerTapped' | 'onMapOverlayTapped' | 'disableAnalytics'>;

const StyleSelectorModal = ({ isOpen, onCloseRequest, onMapBaseLayerTapped, onMapOverlayTapped, disableAnalytics }: Props) => {
  const isMobile = useIsMobileSizedScreen();
  const dispatch = useDispatch();
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });
  const isPLP = hasPermission({ permission: 'public_lands:manage' });
  const { gpsiesUser, adminStyleSettings, is3dActive, supportedDynamicStyleIds } = useSelector(state => ({
    gpsiesUser: state.context.gpsiesUser,
    adminStyleSettings: state.map.adminStyleSettings,
    is3dActive: state.map.is3dActive,
    supportedDynamicStyleIds: state.map.supportedDynamicStyleIds
  }));
  const isGpsiesUser = Boolean(gpsiesUser);

  const [adminStyleSourceUrlId, setAdminStyleSourceUrlId] = useState<StyleId>();
  const { activeModal, goBack, navigateTo } = useMultiStepModal<'cards' | 'url-input'>('cards');

  const renderCards = useCallback(
    (ids: StyleId[]) => {
      return ids.map(id => (
        <StyleSelectionCard
          key={`card-${id}`}
          onUrlButtonClick={() => {
            setAdminStyleSourceUrlId(id);
            navigateTo('url-input');
          }}
          styleId={id}
          onMapBaseLayerTapped={onMapBaseLayerTapped}
          onMapOverlayTapped={onMapOverlayTapped}
          disableAnalytics={disableAnalytics}
        />
      ));
    },
    [navigateTo, onMapBaseLayerTapped, onMapOverlayTapped, disableAnalytics]
  );

  const adminSectionCards = useMemo(() => {
    if (!isAdmin) {
      return undefined;
    }
    return renderCards(filterHiddenStyles({ ids: adminSectionStyles, isAdmin, isPLP, isGpsiesUser, is3dActive, supportedDynamicStyleIds }));
  }, [isAdmin, isPLP, isGpsiesUser, is3dActive, renderCards, supportedDynamicStyleIds]);

  const mapTypeCards = useMemo(
    () => renderCards(filterHiddenStyles({ ids: mapTypeStyles, isAdmin, isPLP, isGpsiesUser, is3dActive, supportedDynamicStyleIds })),
    [isAdmin, isPLP, isGpsiesUser, is3dActive, renderCards, supportedDynamicStyleIds]
  );

  const mapDetailCards = useMemo(() => {
    const allStyles = isPLP ? plpStyles : mapDetailStyles;
    const uniqueIds = allStyles.filter(id => {
      if (isAdmin && adminSectionStyles.includes(id)) {
        return false; // Don't want to duplicate cards in this section that were bumped up to the admin-only section
      }
      return true;
    });

    return renderCards(filterHiddenStyles({ ids: uniqueIds, isAdmin, isPLP, isGpsiesUser, is3dActive, supportedDynamicStyleIds }));
  }, [isAdmin, isPLP, isGpsiesUser, is3dActive, renderCards, supportedDynamicStyleIds]);

  const isAdminModal = isAdmin && !isMobile;

  return (
    <Modal
      allowOutsideClicks={isAdminModal}
      buttons={{
        flatButton:
          activeModal === 'cards'
            ? {
                onClick: () => {
                  dispatch(resetMapStyles());
                },
                testId: 'map-selector-reset',
                text: <FormattedMessage defaultMessage="Reset" />
              }
            : undefined,
        primaryButton: {
          onClick: activeModal === 'cards' ? onCloseRequest : goBack,
          testId: 'map-selector-apply',
          text: <FormattedMessage defaultMessage="Apply" />
        }
      }}
      isOpen={isOpen}
      onBackRequest={goBack}
      onCloseComplete={() => {
        navigateTo('cards'); // Reset the modal when it closes
      }}
      onCloseRequest={onCloseRequest}
      modalClassName={isAdminModal ? styles.adminModal : undefined}
      size="lg"
    >
      {activeModal === 'cards' ? (
        <div className={styles.cardsContainer}>
          {adminSectionCards && <div className={styles.cardsGrid}>{adminSectionCards}</div>}
          <Typography variant={isAdminModal ? 'heading300' : 'heading500'}>
            <FormattedMessage defaultMessage="Map types" />
          </Typography>
          <div className={styles.cardsGrid}>{mapTypeCards}</div>
          <Typography variant={isAdminModal ? 'heading300' : 'heading500'}>
            <FormattedMessage defaultMessage="Map details" />
          </Typography>
          <div className={styles.cardsGrid}>{mapDetailCards}</div>
        </div>
      ) : (
        <TextInput
          helperText="URL must be for a WMS raster tile source, containing {'{x}, {y} and {z}'} placeholders"
          labelText={`Source URL (${adminStyleSourceUrlId})`}
          onChange={url =>
            dispatch(
              updateAdminStyleSettings({
                ...adminStyleSettings,
                styleSourceUrls: { ...adminStyleSettings?.styleSourceUrls, [adminStyleSourceUrlId!]: url }
              })
            )
          }
          testId="source-url"
          value={adminStyleSettings?.styleSourceUrls?.[adminStyleSourceUrlId!]}
        />
      )}
    </Modal>
  );
};

export default StyleSelectorModal;
