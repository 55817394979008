import classNames from 'classnames';
import IconButton from '@alltrails/denali/components/IconButton';
import Chevron from '@alltrails/denali/icons/Chevron';
import styles from './styles/styles.module.scss';

type MinimizationButtonProps = {
  isHidden: boolean;
  setIsHidden: (isHidden: boolean) => void;
};

const MinimizationButton = ({ isHidden, setIsHidden }: MinimizationButtonProps): JSX.Element => (
  <div
    className={classNames(styles.expandButton, {
      [styles.isHidden]: isHidden
    })}
  >
    <IconButton
      title={isHidden ? 'Show elevation chart' : 'Hide elevation chart'} // We do not translate as this is only used for admins
      testId="minimize-elevation-chart"
      icon={{ Component: Chevron, orientation: isHidden ? 'up' : 'down' }}
      onClick={() => setIsHidden(!isHidden)}
      size="sm"
      variant="flat"
    />
  </div>
);

export default MinimizationButton;
