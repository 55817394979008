import { ComponentProps } from 'react';
import classNames from 'classnames';
import Avatar from '@alltrails/denali/components/Avatar';
import User from '@alltrails/shared/types/User';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import MapMarker from '../MapMarker';
import styles from './styles/styles.module.scss';

type AvatarMarkerProps = Pick<ComponentProps<typeof MapMarker>, 'latitude' | 'longitude'> & {
  status: 'online' | 'offline';
  testId: string;
  user?: User;
};

const AvatarMarker = ({ latitude, longitude, status, testId, user }: AvatarMarkerProps) => {
  return (
    <MapMarker latitude={latitude} longitude={longitude}>
      <div className={styles.circle}>
        {user ? (
          <Avatar
            bordered
            className={classNames(styles.avatar, styles[status])}
            hasPlus={false}
            size="sm"
            stopPropagation
            testId={testId}
            userId={user.id}
            userName={fullName(user)}
          />
        ) : (
          <div className={classNames(styles.noUser, styles[status])} data-testid={testId} />
        )}
        <div
          className={classNames(styles.beacon, styles[status], {
            [styles.noUser]: !user
          })}
        />
      </div>
    </MapMarker>
  );
};

export default AvatarMarker;
