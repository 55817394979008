import { COLOR_NEUTRAL_WHITE } from '@alltrails/denali/tokens';
import { circleShadowDef, circleShadowId } from '../../utils/svgMarkerComponents';

const curatedWaypointSvg = `<svg width="46" height="30" viewBox="0 0 46 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <defs>
    ${circleShadowDef}
  </defs>
  <rect x="0" y="0" width="46" height="30" rx="12" fill="url(#${circleShadowId})" />
  <rect x="3" y="3" width="40" height="24" rx="12" fill="${COLOR_NEUTRAL_WHITE}" />
</svg>`;

export default curatedWaypointSvg;
