import { ReactNode } from 'react';
import { IntlShape } from '@alltrails/shared/react-intl';
import { getHoursAndMinutes } from './dateTimeConverters';
import { wrapFormattedPartsUnitInSpan } from './utils';

/**
 * Formats time as HH:mm:ss
 * @param totalSeconds Time, in seconds
 * @returns Time formatted as HH:mm:ss
 */
export const formatTimeHhMmSs = (totalSeconds: number) => {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const hours = Math.floor(totalSeconds / (60 * 60));

  const secondsString = String(seconds).padStart(2, '0');
  const minutesString = String(minutes).padStart(2, '0');

  if (hours) {
    return `${hours}:${minutesString}:${secondsString}`;
  }
  return `${minutesString}:${secondsString}`;
};
/**
 * Converts seconds to hours and minutes and wraps the units in spans so they can be styled differently than the numeric values
 * @param intl Intl object
 * @param seconds Seconds value
 * @param unitSpace Include a space before the unit
 * @returns A fragment with the converted time values and the units, wrapped in spans
 */
export const getFormattedHoursMinutesWithUnitsInSpans = (intl: IntlShape, seconds: number, unitSpace?: boolean) => {
  const { hours, minutes } = getHoursAndMinutes(seconds);
  if (hours > 999) {
    return wrapFormattedPartsUnitInSpan(intl.formatNumberToParts(hours, { style: 'unit', unit: 'hour', unitDisplay: 'narrow' }), unitSpace);
  }
  return intl.formatMessage(
    { defaultMessage: '{hours}<span>h</span> {minutes}<span>m</span>' },
    { span: (text: ReactNode) => <span key={text?.toString()}>{text}</span>, hours, minutes }
  );
};
