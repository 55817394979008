import { useEffect, useMemo } from 'react';
import { LngLatBounds } from 'mapbox-gl';
import useAlgoliaIndex from '@alltrails/search/hooks/useAlgoliaIndex';
import { FeatureCollection } from 'geojson';
import { useSelector } from '../../redux';
import AdminClusteredPins from '../AdminClusteredPins';
import { pointItemsToGeojson } from '../../utils/legacyGeoJSONConversions';
import HeatmapLayer from './HeatmapLayer';
import { attributesToRetrieve, getBoundingBoxParams } from './algoliaUtils';
import useFilteredResults from './useFilteredResults';

type AllMapsOverlayProps = {
  bounds?: LngLatBounds;
  maxResultCount?: number;
  minimumDate?: Date;
  showHeatmap?: boolean;
};

const AllMapsOverlay = ({ bounds, maxResultCount, minimumDate, showHeatmap }: AllMapsOverlayProps) => {
  const { algoliaConfigs } = useSelector(state => ({
    algoliaConfigs: state.algoliaConfigs
  }));
  const index = useAlgoliaIndex(algoliaConfigs, ['map']);
  const [results, setResults] = useFilteredResults(maxResultCount, minimumDate);

  useEffect(() => {
    if (!bounds) {
      return;
    }
    const getResults = async () => {
      const result = await index.search('', {
        attributesToRetrieve,
        hitsPerPage: 500,
        facetFilters: ['type:map'],
        ...getBoundingBoxParams(bounds)
      });
      setResults(result.hits);
    };
    getResults();
  }, [bounds, index, setResults]);

  const geoJson = useMemo(() => {
    return results?.length ? pointItemsToGeojson(results) : undefined;
  }, [results]);

  return (
    <>
      {geoJson ? <AdminClusteredPins featureCollection={geoJson as FeatureCollection} type="map" id="mapsAll" /> : null}
      {showHeatmap && <HeatmapLayer results={results} type="map" uniqueId="mapsAll" />}
    </>
  );
};

export default AllMapsOverlay;
