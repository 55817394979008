function safeJsonParse(obj) {
  let result = null;
  try {
    result = JSON.parse(obj);
  } catch {
    result = obj;
  }
  return result;
}

export function parseProperties(properties) {
  return Object.entries(properties).reduce((acc, [key, value]) => {
    acc[key] = safeJsonParse(value);
    return acc;
  }, {});
}
