import { useMemo } from 'react';
import { type LngLat } from '../types/Geo';

const validLngLat = (lngLat: LngLat): boolean => {
  return lngLat[0] >= -180 && lngLat[0] <= 180 && lngLat[1] >= -90 && lngLat[1] <= 90;
};

const useQueryParams = searchParams => {
  const center = useMemo<LngLat | undefined>(() => {
    const latitude = searchParams.get('latitude');
    const longitude = searchParams.get('longitude');
    if (latitude && longitude) {
      const lngLat = [parseFloat(longitude), parseFloat(latitude)] as LngLat;
      return validLngLat(lngLat) ? lngLat : undefined;
    } else {
      return undefined;
    }
  }, [searchParams]);

  const zoom = useMemo<number | undefined>(() => {
    const zoom = searchParams.get('zoom');
    if (zoom) {
      return parseFloat(zoom) || undefined;
    } else if (!!center) {
      return 12.5;
    } else {
      return undefined;
    }
  }, [searchParams, center]);

  return { zoom, center };
};

export default useQueryParams;
