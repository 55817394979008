import { ComponentProps, ReactNode } from 'react';
import useIsMinimumMapHeight from '@alltrails/shared/hooks/useIsMinimumMapHeight';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import TooltipFor3dMap from '../TooltipFor3dMap';
import CompassControl from '../CompassControl';
import GeolocateControl from '../GeolocateControl';
import ZoomControls from '../ZoomControls';
import StyleControls from '../StyleControls';
import DistanceScale from '../DistanceScale';
import ControlContainer from '../ControlContainer';

export type Props = {
  additionalControls?: ReactNode;
  hideGeolocateControl?: boolean;
} & Pick<
  ComponentProps<typeof StyleControls>,
  'on3dButtonTapped' | 'onLayerModalLaunched' | 'onStyleModalChange' | 'onMapBaseLayerTapped' | 'onMapOverlayTapped' | 'disableAnalytics'
>;

/**
 * BaseControlMap represents our typical controls to be used on a map
 *
 * @param {props}: Props
 * @returns
 */

const BaseControls = ({
  additionalControls,
  hideGeolocateControl = false,
  on3dButtonTapped,
  onLayerModalLaunched,
  onStyleModalChange,
  onMapBaseLayerTapped,
  onMapOverlayTapped,
  disableAnalytics
}: Props) => {
  const isMobile = useIsMobileSizedScreen();
  const isMinimumMapHeight = useIsMinimumMapHeight();

  return (
    <>
      {/* Helpful hints for map users */}
      <TooltipFor3dMap />
      <ControlContainer position="bottomLeft">
        {!!additionalControls && additionalControls}
        {!isMobile && <ZoomControls />}
        {/* The arrowhead icon that is used to show the blue dot for a user's current location */}
        {isMinimumMapHeight && !hideGeolocateControl && <GeolocateControl />}
      </ControlContainer>
      {isMinimumMapHeight && (
        <ControlContainer position="topRight">
          <StyleControls
            on3dButtonTapped={on3dButtonTapped}
            onLayerModalLaunched={onLayerModalLaunched}
            onStyleModalChange={onStyleModalChange}
            onMapBaseLayerTapped={onMapBaseLayerTapped}
            onMapOverlayTapped={onMapOverlayTapped}
            disableAnalytics={disableAnalytics}
          />

          {/* The compass icon that indicates the current map bearing/direction */}
          <CompassControl />
        </ControlContainer>
      )}
      <DistanceScale position="bottom-right" maxWidth={80} />
    </>
  );
};

export default BaseControls;
