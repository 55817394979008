const nationalParkSvg = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
  <ellipse cx="15" cy="32" rx="12" ry="4" fill="url(#a)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.517 26.735C22.937 25.58 27 20.764 27 15c0-6.627-5.373-12-12-12S3 8.373 3 15c0 5.764 4.064 10.579 9.483 11.735l2.392 2.991c.043.053.064.08.09.09a.1.1 0 0 0 .07 0c.026-.01.047-.037.09-.09l2.392-2.99Z" fill="#FDF3E2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.483 26.735C7.063 25.58 3 20.764 3 15 3 8.373 8.373 3 15 3s12 5.373 12 12c0 5.764-4.064 10.579-9.483 11.735l-1.948 2.436-.444.555c-.043.053-.064.08-.09.09a.1.1 0 0 1-.07 0c-.026-.01-.047-.037-.09-.09l-.444-.555-1.948-2.436Zm6.459 2.222C25.034 27.24 29.5 21.644 29.5 15 29.5 6.992 23.008.5 15 .5S.5 6.992.5 15c0 6.644 4.466 12.24 10.558 13.957l1.867 2.333.053.066a2.603 2.603 0 0 0 1.11.801 2.6 2.6 0 0 0 2.934-.801l.055-.068 1.865-2.33Z" fill="#86570D"/>
  <g clip-path="url(#b)">
    <path d="M16.897 12.69s1.22 1.138 2.275 1.138c.57 0 1.138-.606 1.138-1.138 0-1.324-3.392-2.508-4.39-5.184a.779.779 0 0 0-1.46 0c-.998 2.676-4.391 3.86-4.391 5.184 0 .532.569 1.138 1.138 1.138 1.055 0 2.276-1.138 2.276-1.138 0 3.413-3.983 3.982-3.983 5.69 0 .635.563 1.137 1.138 1.137.663 0 1.363-.404 1.952-.832.467-.34 1.123.003 1.123.58V23.5h2.954v-4.235c0-.577.655-.92 1.122-.58.59.428 1.29.832 1.952.832.575 0 1.138-.502 1.138-1.138 0-1.707-3.982-2.276-3.982-5.69Z" fill="#86570D"/>
  </g>
  <defs>
    <radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0 3.5 -10.5 0 15 32)">
      <stop stop-opacity=".24"/>
      <stop offset="1" stop-color="#8F8F8F" stop-opacity="0"/>
    </radialGradient>
    <clipPath id="b">
      <path fill="#fff" transform="translate(6.75 7)" d="M0 0h16.5v16.5H0z"/>
    </clipPath>
  </defs>
</svg>`;

export default nationalParkSvg;
