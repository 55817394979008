import type { AllTrailsResult } from '../../types/Results';
import type { PinType } from '../../types/Images';
import { getClusteredPinsId, getUnclusteredPinsId } from '../../utils/layers';
import useMapMouseMoveEvents from '../../hooks/useMapMouseMoveEvents';
import { useSelector } from '../../redux';
import { updateCursor } from '../../redux/reducer';
import ClusteredMarkers from '../ClusteredMarkers';
import useMap from '../../hooks/useMap';

export type Props = {
  pinTypes: PinType[];
  onMarkerDoubleClick?: (result: AllTrailsResult) => void;
  shouldCluster?: boolean;
};

const BaseClusters = ({ pinTypes, onMarkerDoubleClick, shouldCluster }: Props) => {
  const map = useMap();
  const { hoveredSerializedCoordinates, allowClickEvents } = useSelector(state => ({
    hoveredSerializedCoordinates: state.map.hoveredSerializedCoordinates,
    allowClickEvents: state.map.allowClickEvents
  }));

  useMapMouseMoveEvents({
    map,
    layerIds: pinTypes.flatMap(pinType => [getUnclusteredPinsId(pinType), getClusteredPinsId(pinType)]),
    hoveredSerializedCoordinates,
    updateCursor,
    allowClickEvents
  });

  return (
    <>
      {pinTypes.map(pinType => (
        <ClusteredMarkers shouldCluster={shouldCluster} key={pinType} pinType={pinType} onMarkerDoubleClick={onMarkerDoubleClick} />
      ))}
    </>
  );
};

export default BaseClusters;
