import { FeatureCollection, MultiPolygon } from 'geojson';
import { AssignmentType } from '@alltrails/geo-service';
import { AlertType, AlgoliaAlert } from '@alltrails/shared/types/alert';
import { DecoratedGeoBoundarySearchResult, DecoratedGeoBoundaryTrailFilter } from '../hooks/useGeoBoundariesSearch';
import { BoundaryFilterType } from '../types/Styles';

const getGeoBoundaryDisplayType = ({ geoBoundaryTrailFilters }: { geoBoundaryTrailFilters: DecoratedGeoBoundaryTrailFilter[] }) => {
  const isAreaBoundary = geoBoundaryTrailFilters.some(f => f.assignmentType === AssignmentType.AREA);
  if (isAreaBoundary) {
    return BoundaryFilterType.osm;
  }

  const isClosureAlertBoundary = geoBoundaryTrailFilters.some(f => {
    // in rare cases, the alert record may not be found in algolia, leaving an undefined assignmentObject
    if (!f.assignmentObject) {
      return false;
    }
    const { alert_type } = f.assignmentObject as AlgoliaAlert;
    return f.assignmentType === AssignmentType.ALERT && alert_type === AlertType.CLOSURE;
  });
  if (isClosureAlertBoundary) {
    return BoundaryFilterType.alertClosure;
  }

  const isNonClosureAlertBoundary = geoBoundaryTrailFilters.some(f => f.assignmentType === AssignmentType.ALERT);
  if (isNonClosureAlertBoundary) {
    return BoundaryFilterType.alertNonClosure;
  }
  return BoundaryFilterType.groupPermissions;
};

const getProperties = (boundary: DecoratedGeoBoundarySearchResult) => {
  return { id: boundary.id, type: getGeoBoundaryDisplayType(boundary) };
};

const geoBoundarySearchResultsToFeatureCollection = (results: DecoratedGeoBoundarySearchResult[]): FeatureCollection<MultiPolygon> => {
  const features = results.map(boundary => ({
    type: 'Feature' as const,
    geometry: JSON.parse(boundary.simplifiedBoundaries),
    id: boundary.id,
    properties: getProperties(boundary)
  }));
  return { type: 'FeatureCollection', features };
};

export default geoBoundarySearchResultsToFeatureCollection;
