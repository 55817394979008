import { Layer, Source } from 'react-map-gl';
import useSvgImage from '../../utils/useSvgImage';
import { currentMarkerId, defaultSymbolLayerLayoutProps, getLayerBeforeId } from '../../utils/layers';
import useMap from '../../hooks/useMap';
import currentSvg from './currentSvg';

const svgSize = 24;

type CurrentMarkerProps = {
  latitude: number;
  longitude: number;
};

const CurrentMarker = ({ latitude, longitude }: CurrentMarkerProps) => {
  const map = useMap();
  const imageIsLoaded = useSvgImage(currentMarkerId, currentSvg, svgSize, svgSize);

  if (!imageIsLoaded) {
    return null;
  }

  return (
    <Source
      type="geojson"
      data={{
        type: 'Point',
        coordinates: [longitude, latitude]
      }}
    >
      <Layer
        id={currentMarkerId}
        beforeId={getLayerBeforeId(map, currentMarkerId)}
        type="symbol"
        layout={{
          ...defaultSymbolLayerLayoutProps,
          'icon-image': currentMarkerId
        }}
      />
    </Source>
  );
};

export default CurrentMarker;
