import { useState } from 'react';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import type { AllTrailsResult, SerializedCoordinates } from '../types/Results';
import { useSelector } from '../redux';
import { coordinatesToSerializedCoordinates } from '../utils/serializedCoordinates';
import useMap from './useMap';
import useUnclusteredMarkers from './useUnclusteredMarkers';
import useCurrentResult from './useCurrentResult';

type Args = {
  hoveredResult?: AllTrailsResult;
  isPageWithListHovering?: boolean;
};

function isTrailhead({
  result,
  resultIdsBySerializedCoordinates
}: {
  result?: AllTrailsResult;
  resultIdsBySerializedCoordinates: Record<SerializedCoordinates, string[]>;
}) {
  const resultCoordinates = result?._cluster_geoloc ? result?._cluster_geoloc : result?._geoloc;
  const serializedCoords = resultCoordinates && coordinatesToSerializedCoordinates(resultCoordinates);
  return serializedCoords && resultIdsBySerializedCoordinates[serializedCoords]?.length > 1;
}

function hasValidLatLong({ result }: { result?: AllTrailsResult }) {
  const resultCoordinates = result?._cluster_geoloc ? result?._cluster_geoloc : result?._geoloc;
  const latitude = resultCoordinates?.lng;
  const longitude = resultCoordinates?.lat;

  return Boolean(latitude && longitude);
}

/**
 * useSelectedPinCategory encompasses all the business logic to determine if we
 * do or do not show a marker representing a selected pin, and which flavor of
 * that component we show
 *
 * @param Args
 * @returns
 */
export default function useSelectedPinCategory({ hoveredResult, isPageWithListHovering }: Args): 'unclustered' | 'trailhead' | 'standard' | null {
  const isMobile = useIsMobileSizedScreen();
  const map = useMap();
  const currentResult = useCurrentResult();
  const [clusterId, setClusterId] = useState<number | undefined>(undefined);

  const { trailheadResults, isHoveringListViewResult, resultIdsBySerializedCoordinates } = useSelector(state => ({
    trailheadResults: state.map.trailheadResults,
    isHoveringListViewResult: state.map.isHoveringListViewResult,
    resultIdsBySerializedCoordinates: state.map.resultIdsBySerializedCoordinates
  }));

  // We use this hook to get the clusterId of the hovered result
  useUnclusteredMarkers({
    clusterId,
    hoveredResult,
    map,
    updateClusterId: setClusterId,
    isPageWithListHovering
  });

  // We are hovering over a result from list view and it has a valid lat, lng.
  // We do not bother checking isMobile since the hybrid list + map view only
  // exists on desktop web anyway.
  if (hasValidLatLong({ result: hoveredResult }) && isHoveringListViewResult) {
    return 'unclustered';
  }

  if (
    // The complex trailhead pin (with navigation arrows) is not shown on mobile.
    // On mobile we show the simple pin that has a number inside. We only show
    // the trailhead pin on desktop web.
    !isMobile &&
    // We selected something on the map and it has a valid lat, lng
    hasValidLatLong({ result: currentResult }) &&
    // There are trailhead results in our state right now
    trailheadResults &&
    // This selected pin is part of a trailhead
    isTrailhead({ result: currentResult, resultIdsBySerializedCoordinates })
  ) {
    return 'trailhead';
  }

  // We selected something on the map and it has a valid lat, lng. It might be
  // a "normal" pin or a trailhead pin (without navigation arrows). This is the
  // sort of standard/normal/simple pin that is shown on desktop and mobile.
  if (hasValidLatLong({ result: currentResult })) {
    return 'standard';
  }

  return null;
}
