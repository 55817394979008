import { forwardRef } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { format } from 'd3';
import { formatTimeHhMmSs } from '@alltrails/data-formatting';
import styles from './styles/styles.module.scss';

const formatGrade = format(',.0f');

type ElevationTooltipProps = {
  payload?: {
    payload?: {
      distance: number;
      elevation: number;
      currentTime?: number;
      elapsedTime?: number;
    };
  }[];
  grade: number | string;
  displayMetric?: boolean;
  isAdmin?: boolean;
};

const ElevationTooltip = forwardRef<HTMLDivElement, ElevationTooltipProps>(({ payload, grade, displayMetric, isAdmin }, ref): JSX.Element | null => {
  const data = payload?.[0]?.payload;
  if (!data) {
    return null;
  }
  const { distance, elevation, currentTime, elapsedTime } = data;
  const fractionDigits = distance > 50 || distance === 0 ? 0 : 1;
  const displayGrade = `${formatGrade(Number(grade))}%`;

  const date = currentTime && isAdmin ? new Date(currentTime * 1000) : null;

  const formattedElapsedTime = elapsedTime && isAdmin ? formatTimeHhMmSs(elapsedTime) : null;

  return (
    <div className={styles.tooltipContainer} ref={ref}>
      <div>
        <FormattedMessage defaultMessage="Distance:" />{' '}
        <FormattedNumber
          style="unit"
          unit={displayMetric ? 'kilometer' : 'mile'}
          notation="compact"
          minimumFractionDigits={fractionDigits}
          maximumFractionDigits={fractionDigits}
          value={distance}
        />
      </div>
      <div>
        <FormattedMessage defaultMessage="Elevation:" />{' '}
        <FormattedNumber style="unit" unit={displayMetric ? 'meter' : 'foot'} maximumFractionDigits={0} value={elevation} />
      </div>
      <div>
        <FormattedMessage defaultMessage="Grade:" /> {displayGrade}
      </div>
      {date && (
        <div>
          <FormattedMessage defaultMessage="UTC:" /> {date.toUTCString()}
        </div>
      )}
      {formattedElapsedTime && (
        <div>
          <FormattedMessage defaultMessage="Elapsed time:" /> {formattedElapsedTime}
        </div>
      )}
    </div>
  );
});

export default ElevationTooltip;
