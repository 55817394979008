import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { SearchRequestOptions } from '../types/algoliaResultTypes';
import AlgoliaIndex from './AlgoliaIndex';

const indices: { [key: string]: AlgoliaIndex } = {};

const getAlgoliaIndex = (
  indexId: string | null = null,
  providedLanguageRegionCode: LanguageRegionCode | null = null,
  algoliaConfigs: AlgoliaConfigs
): AlgoliaIndex => {
  let applicationConfig = { applicationId: '', apiKey: '' };
  const index = Object.values(algoliaConfigs.indexes).find(i => i.id == indexId);
  if (index) {
    applicationConfig = algoliaConfigs.applications[index.application];
  }

  const algoliaIndex = indexId ? indexId : algoliaConfigs.indexes.primaryIndex.id;
  const languageRegionCode: LanguageRegionCode = providedLanguageRegionCode || LanguageRegionCode.English;
  const cacheKey: string = [algoliaIndex, languageRegionCode].join('-');
  indices[cacheKey] = indices[cacheKey] || new AlgoliaIndex(applicationConfig.applicationId, applicationConfig.apiKey, algoliaIndex);
  return indices[cacheKey];
};

/**
 * @deprecated This uses old logic ported from the monolith that has been refactored in AlgoliaIndex/useAlgoliaIndex
 */
export const search = <TObject>(indexId: string, query: string, algoliaConfigs: AlgoliaConfigs, options: SearchRequestOptions) => {
  const index = getAlgoliaIndex(indexId, LanguageRegionCode.English, algoliaConfigs);
  return index.search<TObject>(query, options);
};
