import { type MapRef } from 'react-map-gl';
import type { Images, PinImageConfig, AdminPinImageConfig, PinImageKey, PinType, AdminPinType } from '../types/Images';
import chevronImage from '../assets/chevron.png';
import trailImages from '../assets/trail';
import mapImages from '../assets/map';
import trackImages from '../assets/track';
import adminTrailImages from '../assets/admin/trail';
import adminMapImages from '../assets/admin/map';
import adminTrackImages from '../assets/admin/track';
import adminPendingTrailImages from '../assets/admin/pendingTrail';

function loadImageSource(
  map: MapRef,
  imageNames: string[],
  imageSource: string,
  onLoadImage: (imageName: string) => void,
  onLoadComplete: () => void
) {
  const imageAlreadyLoaded = imageNames.reduce((result, imageName) => {
    if (map.hasImage(imageName)) {
      onLoadImage(imageName);
      return result && true;
    }

    return false;
  }, true);

  // If all images were already loaded there is nothing to do.
  if (imageAlreadyLoaded) {
    onLoadComplete();
    return;
  }

  map.loadImage(imageSource, (error, image) => {
    if (error) {
      console.error(error);
      // Don't prevent initialization on error since subsequent attempts on other style changes may succeed
      imageNames.forEach(imageName => {
        onLoadImage(imageName);
      });
      return;
    }

    if (!image) {
      // Don't prevent initialization on empty image
      imageNames.forEach(imageName => {
        onLoadImage(imageName);
      });
      return;
    }

    imageNames.forEach(imageName => {
      // This check may look redundant, but if we somehow kicked off multiple
      // requests at once this would prevent any collisions during a race.
      if (!map.hasImage(imageName)) {
        map.addImage(imageName, image, { pixelRatio: 2 });
      }

      onLoadImage(imageName);
    });

    onLoadComplete();
  });
}

export function loadImages(images: Images, map: MapRef, setIsInitialized: (isInitialized: boolean) => void) {
  const loadedImages = {};
  const onLoadImage = (imageName: string) => {
    loadedImages[imageName] = true;
  };
  const onLoadComplete = () => {
    if (Object.values(loadedImages).every(loaded => loaded)) {
      setIsInitialized?.(true);
    }
  };
  // Group images by source to prevent loading the same source multiple times
  const imageSources: Record<string, string[]> = {};
  Object.entries(images).forEach(([imageName, imageSource]) => {
    loadedImages[imageName] = false;

    if (!(imageSource in imageSources)) {
      imageSources[imageSource] = [];
    }

    imageSources[imageSource].push(imageName);
  });

  Object.entries(imageSources).forEach(([imageSource, imageNames]) => {
    loadImageSource(map, imageNames, imageSource, onLoadImage, onLoadComplete);
  });
}

export const chevronImageKey = 'chevron';

export const chevronImages: Images = { [chevronImageKey]: chevronImage.src };

export const pinImageConfigs: Record<PinType, PinImageConfig> = {
  trail: trailImages,
  map: mapImages,
  track: trackImages
};

export const adminPinImageConfigs: Record<AdminPinType, AdminPinImageConfig> = {
  trail: adminTrailImages,
  pendingTrail: adminPendingTrailImages,
  map: adminMapImages,
  track: adminTrackImages
};

export const getPinType = (type?: string) => {
  switch (type) {
    case 'map':
      return 'map';
    case 'track':
      return 'track';
    default:
      return 'trail';
  }
};

export const getPinImageKey = (pinType: PinType | AdminPinType, imageKey: PinImageKey) => `${pinType}-${imageKey}`;

export const getPinImage = (pinType: PinType, imageKey: PinImageKey) => pinImageConfigs[pinType][imageKey];

export const getAdminPinImage = (pinType: AdminPinType, imageKey: PinImageKey) => adminPinImageConfigs[pinType][imageKey];

export const getPinImages = (imageKeys: PinImageKey[]) => {
  const images: Images = {};
  (Object.keys(pinImageConfigs) as PinType[]).forEach(pinType => {
    imageKeys.forEach(imageKey => {
      images[getPinImageKey(pinType, imageKey)] = getPinImage(pinType, imageKey);
    });
  });

  return images;
};

export const getAdminPinImages = (imageKeys: PinImageKey[]) => {
  const images: Images = {};
  (Object.keys(adminPinImageConfigs) as AdminPinType[]).forEach(pinType => {
    imageKeys.forEach(imageKey => {
      images[getPinImageKey(pinType, imageKey)] = getAdminPinImage(pinType, imageKey);
    });
  });

  return images;
};
