import { Layer, Source } from 'react-map-gl';
import { atmosphereId } from '../../utils/layers';

const AtmosphereStyle = () => {
  return (
    <Source id="mapbox-terrain" type="raster-dem" url="mapbox://mapbox.mapbox-terrain-dem-v1" tileSize={512} maxzoom={14}>
      <Layer
        id={atmosphereId}
        type="sky"
        paint={{
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15,
          'sky-atmosphere-color': 'rgba(85, 151, 210, 0.5)'
        }}
      />
    </Source>
  );
};

export default AtmosphereStyle;
