import { Layer, Source } from 'react-map-gl';
import { FeatureCollection, LineString } from 'geojson';
import { COLOR_PLUS_600 } from '@alltrails/denali/tokens';
import { trailCollectionLinesId } from '../../utils/layers';

type Props = {
  featureCollection: FeatureCollection<LineString>;
  lineColor?: string;
};

const TrailCollection = ({ featureCollection, lineColor = COLOR_PLUS_600 }: Props) => {
  return (
    <Source type="geojson" data={featureCollection}>
      <Layer
        id={trailCollectionLinesId}
        type="line"
        layout={{
          'line-join': 'round',
          'line-cap': 'round'
        }}
        paint={{
          'line-color': ['coalesce', ['get', 'color'], lineColor],
          'line-width': 4
        }}
      />
    </Source>
  );
};

export default TrailCollection;
