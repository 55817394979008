import MapBaseLayer from '@alltrails/analytics/enums/MapBaseLayer';
import MapOverlay from '@alltrails/analytics/enums/MapOverlay';
import { BaseStyleId, DynamicStyleId, StaticStyleId } from '../types/Styles';

export const getMapBaseLayer = (styleId: BaseStyleId) => {
  switch (styleId) {
    case 'adminBasemap':
      return MapBaseLayer.Custom;
    case 'alltrailsOutdoorsV2':
      return MapBaseLayer.Alltrailsv2;
    case 'alltrailsSatellite':
    case 'alltrailsSatelliteMinimal':
    case 'googleSatellite':
      return MapBaseLayer.Satellite;
    case 'opencycle':
      return MapBaseLayer.Ocm;
    case 'openstreet':
      return MapBaseLayer.Osm;
    case 'roadmap':
      return MapBaseLayer.Road;
    case 'topo':
      return MapBaseLayer.Topo;
    case 'worldparks':
      return MapBaseLayer.Worldparks;
    case 'worldtopo':
      return MapBaseLayer.Worldtopo;
  }
};

export const getMapOverlay = (styleId: StaticStyleId | DynamicStyleId) => {
  switch (styleId) {
    case 'adminOverlay':
      return MapOverlay.CustomOverlay;
    case 'after':
      return MapOverlay.RecordingsLast60Days;
    case 'airQuality':
      return MapOverlay.Airquality;
    case 'heatmap':
      return MapOverlay.Heatmap;
    case 'lightPollution':
      return MapOverlay.LightPollution;
    case 'nearbyTrails':
      return MapOverlay.NearbyTrails;
    case 'photos':
      return MapOverlay.Photos;
    case 'pollen':
      return MapOverlay.Pollen;
    case 'recordingsAll':
      return MapOverlay.RecordingsAll;
    case 'waypoints':
      return MapOverlay.Waypoints;
    case 'weather':
      return MapOverlay.Weather;
    default:
      return undefined;
  }
};
