import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Feature, Geometry } from 'geojson';
import { Waypoint } from '@alltrails/waypoints';
import { useGroupHover } from '@alltrails/core';
import { COLOR_TEXT_PRIMARY } from '@alltrails/denali/tokens';
import { useLanguageRegionCode } from '@alltrails/language';
import logMapDetailsWayPointHovered from '@alltrails/analytics/events/logMapDetailsWayPointHovered';
import WaypointPopup from '../WaypointPopup';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import { curatedWaypointsId, defaultSymbolLayerLayoutProps, getLayerBeforeId } from '../../utils/layers';
import curatedWaypointSvg from './curatedWaypointSvg';

const svgSize = 32;
const svgSizeWithoutShadow = 28;
const imageName = 'curated-waypoint';

// If we just used the normal Waypoint type for the feature properties, the nested objects (like location) get stringified making it more complex
// to parse those values back from mapbox. So, flatten any nested fields at the time of feature creation to simplify the rest of the code.
type FeatureProperties = Waypoint & {
  latitude: number;
  longitude: number;
};

type CuratedWaypointsProps = { trailId: number; waypoints: Waypoint[] };

const CuratedWaypoints = ({ trailId, waypoints }: CuratedWaypointsProps) => {
  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);
  const languageRegionCode = useLanguageRegionCode();
  const map = useMap();
  const hasLoggedHoverEvent = useRef(false);

  const imageIsLoaded = useSvgImage(imageName, curatedWaypointSvg, svgSize, svgSize);

  const features = useMemo(() => {
    return waypoints.map((waypoint, index) => {
      const { latitude, longitude } = waypoint.location;
      const order = waypoint.order ?? index + 1; // Waypoints from the server occasionally don't have an order - ensure that they do
      const feature: Feature<Geometry, FeatureProperties> = {
        geometry: { type: 'Point', coordinates: [longitude, latitude] },
        properties: { ...waypoint, order, latitude: waypoint.location.latitude, longitude: waypoint.location.longitude },
        type: 'Feature'
      };
      return feature;
    });
  }, [waypoints]);

  const [hoveredWaypoint, setHoveredWaypoint] = useState<FeatureProperties>();

  const onHover = useCallback((waypoint: FeatureProperties) => {
    setHoveredWaypoint(waypoint);
  }, []);

  const onHoverEnd = useCallback(() => {
    setHoveredWaypoint(undefined);
  }, []);

  const { onMouseEnter, onMouseLeave } = useGroupHover(onHover, onHoverEnd, true);

  useEffect(() => {
    map?.on('mousemove', curatedWaypointsId, e => {
      if (onMouseEnter && e.features) {
        if (!hasLoggedHoverEvent.current) {
          logMapDetailsWayPointHovered({ trail_id: trailId });
          hasLoggedHoverEvent.current = true;
        }

        const waypoint = e.features[0].properties as FeatureProperties;
        onMouseEnter(waypoint);
      }
    });

    map?.on('mouseleave', curatedWaypointsId, e => {
      onMouseLeave?.();
    });
  }, [map, onMouseEnter, onMouseLeave, trailId]);

  const popup = useMemo(() => {
    if (!hoveredWaypoint) {
      return null;
    }

    const { description, description_lang, description_original, latitude, longitude, name, name_lang, name_original } = hoveredWaypoint;
    const nameIsTranslatable = name_lang !== languageRegionCode && name !== name_original;
    const descriptionIsTranslatable = description_lang !== languageRegionCode && description !== description_original;
    const isTranslatable = nameIsTranslatable || descriptionIsTranslatable;
    return (
      <WaypointPopup
        anchorSize={svgSizeWithoutShadow}
        description={showOriginalLanguage ? description_original : description}
        latitude={latitude}
        longitude={longitude}
        onMouseEnter={() => onMouseEnter?.(hoveredWaypoint)} // Just prevents the popup from closing when the mouse leaves the map layer
        onMouseLeave={onMouseLeave}
        title={showOriginalLanguage ? name_original : name}
        translationInfo={
          isTranslatable
            ? {
                isTranslated: !showOriginalLanguage,
                onTranslateClick: () => {
                  setShowOriginalLanguage(!showOriginalLanguage);
                }
              }
            : undefined
        }
      />
    );
  }, [hoveredWaypoint, languageRegionCode, onMouseEnter, onMouseLeave, showOriginalLanguage]);

  if (!imageIsLoaded) {
    return null;
  }

  // Markers were moving around in 3d so using layers instead
  return (
    <>
      <Source
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features
        }}
      >
        <Layer
          id={curatedWaypointsId}
          beforeId={getLayerBeforeId(map, curatedWaypointsId)}
          type="symbol"
          layout={{
            ...defaultSymbolLayerLayoutProps,
            'icon-image': imageName,
            'text-anchor': 'center',
            'text-field': ['get', 'order']
          }}
          paint={{
            'text-color': COLOR_TEXT_PRIMARY
          }}
        />
      </Source>
      {popup}
    </>
  );
};

export default CuratedWaypoints;
