import { HTMLInputTypeAttribute, useEffect, useRef } from 'react';
import classNames from 'classnames';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import { IconRendererProps } from '../IconRenderer/IconRenderer';
import InputField from '../InputField';
import { InputBaseProps, Size } from '../../types';
import styles from './styles/styles.module.scss';
import getErrorMessage from './getErrorMessage';

type TextInputProps = InputBaseProps & {
  autoComplete?: string; // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
  characterLimit?: number;
  className?: string;
  inputMode?: 'decimal' | 'email' | 'numeric' | 'tel' | 'text';
  isFocused?: boolean;
  leadingIcon?: IconDefinition<'orientation'>;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  testId: string;
  trailingIcon?: IconDefinition<'orientation'>;
  type?: HTMLInputTypeAttribute;
  value?: string;
  size?: Size<'sm' | 'md'>;
};

const TextInput = ({
  autoComplete,
  className,
  disabled,
  errorText,
  helperText,
  hideLabel,
  inputMode,
  labelText,
  leadingIcon,
  onChange,
  placeholder,
  required = false,
  testId,
  trailingIcon,
  type = 'text',
  value,
  isFocused,
  characterLimit,
  size = 'md'
}: TextInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const iconDefaults: IconRendererProps['defaults'] = { color: disabled ? '--color-text-disabled' : '--color-text-secondary', size: 'md' };

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const errorMessage = getErrorMessage(errorText, value, required, characterLimit);

  return (
    <InputField
      className={className}
      disabled={disabled}
      errorText={errorMessage}
      helperText={helperText}
      labelText={hideLabel ? '' : labelText}
      characterLimitText={value && characterLimit ? `${value.length} / ${characterLimit}` : undefined}
      size={size}
    >
      <div className={classNames(styles.inputContainer, styles[size], { [styles.disabled]: disabled, [styles.hasError]: Boolean(errorMessage) })}>
        {leadingIcon ? <IconRenderer icon={leadingIcon} defaults={iconDefaults} /> : null}
        <input
          ref={inputRef}
          aria-label={hideLabel ? labelText : undefined}
          autoComplete={autoComplete}
          className={classNames(styles.input, styles[size], { [styles.hasLeadingIcon]: !!leadingIcon, [styles.hasTrailingIcon]: !!trailingIcon })}
          disabled={disabled}
          inputMode={inputMode}
          onChange={e => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          required={required}
          type={type}
          value={value}
          data-testid={testId}
        />
        {trailingIcon ? <IconRenderer icon={trailingIcon} defaults={iconDefaults} /> : null}
      </div>
    </InputField>
  );
};

export default TextInput;
