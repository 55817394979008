import { useEffect } from 'react';
import type { GeoJSONSource, MapLayerMouseEvent, MapRef } from 'react-map-gl';
import { useDispatch } from '../redux';

type Args = {
  activeClusterId?: number;
  layerId: string;
  map?: MapRef;
  sourceId: string;
  allowClickEvents?: boolean;
};

export default function useClusterLayerMouseClickEvents({ activeClusterId, layerId, map, sourceId, allowClickEvents }: Args) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map || !allowClickEvents) {
      return;
    }

    const onClick = (event: MapLayerMouseEvent) => {
      const clusterId = event.features?.[0].properties?.cluster_id;

      const features = map.queryRenderedFeatures(event.point, {
        layers: [layerId]
      });

      const cluster = features[0] as GeoJSON.Feature<GeoJSON.Point>;

      // Move the camera to the point where the given cluster would break apart.
      (map.getSource(sourceId) as GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          console.error('error determining cluster zoom level:', err);
          return;
        }

        map.easeTo({
          center: cluster.geometry.coordinates as [number, number],
          zoom
        });
      });
    };

    map?.on('click', layerId, onClick);

    // eslint-disable-next-line consistent-return
    return () => {
      map?.off('click', layerId, onClick);
    };
  }, [allowClickEvents, activeClusterId, dispatch, layerId, map, sourceId]);
}
