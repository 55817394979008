/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import { Feature } from 'geojson';
import { PinType } from '../types/Images';
import { useSelector } from '../redux';
import { coordinatesToSerializedCoordinates } from '../utils/serializedCoordinates';

export default function useFeatures(pinType: PinType) {
  const { allCoordinates, resultIdsBySerializedCoordinates, resultsById } = useSelector(state => ({
    allCoordinates: state.map.allCoordinates,
    resultIdsBySerializedCoordinates: state.map.resultIdsBySerializedCoordinates,
    resultsById: state.map.resultsById
  }));

  return useMemo(() => {
    const features: Feature[] = [];

    allCoordinates.forEach((coords, index) => {
      const { lat, lng } = coords;

      const serializedCoordinates = coordinatesToSerializedCoordinates(coords);
      // Look up the results for these coordinates from the store.
      const resultIds = resultIdsBySerializedCoordinates[serializedCoordinates];
      // Optional text content to display in the feature.
      const resultsCount = resultIds.length > 1 ? resultIds.length : 1;

      const feature = {
        id: index,
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [lng, lat] },
        // Stash some data on the feature for user in clustering and the UI.
        properties: { serializedCoordinates, resultsCount }
      } as Feature;

      const resultsAtCoords = resultIds?.map(resultId => resultsById[resultId]);
      if (resultsAtCoords.some(result => result?.type === pinType)) {
        features.push(feature);
      }
    });

    return features;
  }, [allCoordinates, resultIdsBySerializedCoordinates, resultsById, pinType]);
}
