import { useMemo } from 'react';
import { Source } from 'react-map-gl';
import { Feature, Point } from 'geojson';
import { useSelector } from '../../redux';
import { serializedCoordinatesToCoordinates } from '../../utils/serializedCoordinates';
import TrailClusters from './TrailClusters';
import TrailPins from './TrailPins';
import TrailResultProperties from './TrailResultProperties';
import ActiveTrailPolylines from './ActiveTrailPolylines';

const sourceId = 'trail-results-source';

export type TrailResultsProps = {
  clusterMaxZoom?: number;
  clusterRadius?: number;
  preventClustering?: boolean;
};

// TrailResults is responsible for rendering trail pins on a map, based on the trailResults state variable.
// Ultimately, we render 2 layers of trail-related markers: TrailClusters (based on mapbox clustering)
// and TrailPins, which represent both single-trail trailheads and multi-trail trailheads.
// These two layers cannot be cleanly split because the mousemove handling gets messy when we have to consider multiple layers.
// While a more generic component may cut down on some lines of code if we ever need to support the same behavior
// for maps or recordings, I would advise caution in making the switch since having the non-generic components
// allows us to interact with redux without having to check the type of a generic "result" object, and cleanly divide
// the different map layers since we have a nicely defined Properties type used in the feature collection.
const TrailResults = ({ clusterMaxZoom = 11, clusterRadius = 40, preventClustering }: TrailResultsProps) => {
  const { trailResults } = useSelector(state => ({ trailResults: state.map.trailResults }));

  const features = useMemo(() => {
    if (!trailResults) {
      return [];
    }
    return Object.keys(trailResults).reduce<Feature<Point, TrailResultProperties>[]>((allFeatures, serializedCoordinates) => {
      const trails = trailResults[serializedCoordinates];
      if (!trails) {
        return allFeatures;
      }
      const { lat, lng } = serializedCoordinatesToCoordinates(serializedCoordinates);
      return [
        ...allFeatures,
        {
          geometry: { type: 'Point', coordinates: [lng, lat] },
          properties: { serializedCoordinates, trailCount: trails.length },
          type: 'Feature'
        }
      ];
    }, []);
  }, [trailResults]);

  return (
    <>
      <Source
        id={sourceId}
        type="geojson"
        data={{ type: 'FeatureCollection', features }}
        cluster={preventClustering ? false : true}
        clusterMaxZoom={clusterMaxZoom} // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson-clusterMaxZoom
        clusterRadius={clusterRadius} // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson-clusterRadius
      >
        <TrailPins sourceId={sourceId} />
        {!preventClustering && <TrailClusters sourceId={sourceId} />}
      </Source>
      <ActiveTrailPolylines />
    </>
  );
};

export default TrailResults;
