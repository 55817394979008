import { PinImageConfig } from '../../types/Images';
import pinImage from '../map/pin.png';
import pinImageSelected from '../map/pinSelected.png';
import pinTrailheadImage1 from '../map/pinActivity1.png';
import pinTrailheadImage2 from '../map/pinActivity2.png';
import pinTrailheadImage3 from '../map/pinActivity3.png';
import pinTrailheadImage4 from '../map/pinActivity4.png';
import pinTrailheadImage5 from '../map/pinActivity5.png';
import pinTrailheadImage6 from '../map/pinActivity6.png';
import pinTrailheadImage7 from '../map/pinActivity7.png';
import pinTrailheadImage8 from '../map/pinActivity8.png';
import pinTrailheadImage9 from '../map/pinActivity9.png';
import pinTrailheadImageSelected1 from '../map/pinActivitySelected1.png';
import pinTrailheadImageSelected2 from '../map/pinActivitySelected2.png';
import pinTrailheadImageSelected3 from '../map/pinActivitySelected3.png';
import pinTrailheadImageSelected4 from '../map/pinActivitySelected4.png';
import pinTrailheadImageSelected5 from '../map/pinActivitySelected5.png';
import pinTrailheadImageSelected6 from '../map/pinActivitySelected6.png';
import pinTrailheadImageSelected7 from '../map/pinActivitySelected7.png';
import pinTrailheadImageSelected8 from '../map/pinActivitySelected8.png';
import pinTrailheadImageSelected9 from '../map/pinActivitySelected9.png';
import pinTrailheadTransparent from '../trail/pinTrailheadTransparent.png';
import pinTransparent from '../trail/pinTransparent.png';
import clusterImage from '../map/clusterActivity.png';
import clusterImageSelected from '../map/clusterActivitySelected.png';

const trackImages: PinImageConfig = {
  pinImage: pinImage.src,
  pinImageSelected: pinImageSelected.src,
  pinTrailheadImage1: pinTrailheadImage1.src,
  pinTrailheadImage2: pinTrailheadImage2.src,
  pinTrailheadImage3: pinTrailheadImage3.src,
  pinTrailheadImage4: pinTrailheadImage4.src,
  pinTrailheadImage5: pinTrailheadImage5.src,
  pinTrailheadImage6: pinTrailheadImage6.src,
  pinTrailheadImage7: pinTrailheadImage7.src,
  pinTrailheadImage8: pinTrailheadImage8.src,
  pinTrailheadImage9: pinTrailheadImage9.src,
  pinTrailheadImageSelected1: pinTrailheadImageSelected1.src,
  pinTrailheadImageSelected2: pinTrailheadImageSelected2.src,
  pinTrailheadImageSelected3: pinTrailheadImageSelected3.src,
  pinTrailheadImageSelected4: pinTrailheadImageSelected4.src,
  pinTrailheadImageSelected5: pinTrailheadImageSelected5.src,
  pinTrailheadImageSelected6: pinTrailheadImageSelected6.src,
  pinTrailheadImageSelected7: pinTrailheadImageSelected7.src,
  pinTrailheadImageSelected8: pinTrailheadImageSelected8.src,
  pinTrailheadImageSelected9: pinTrailheadImageSelected9.src,
  pinTrailheadTransparent: pinTrailheadTransparent.src,
  pinTransparent: pinTransparent.src,
  clusterImage: clusterImage.src,
  clusterImageSelected: clusterImageSelected.src
};

export default trackImages;
