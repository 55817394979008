'use client';

import Carousel from '@alltrails/denali/components/Carousel';
import logTopModuleSwiped from '@alltrails/analytics/events/logTopModuleSwiped';
import PageType from '@alltrails/analytics/enums/PageType';
import { useWindowSize } from '@alltrails/core';
import Button from '@alltrails/denali/components/Button';
import { useIntl } from 'react-intl';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { slidesPerViewBreakpoints, slidesPerViewMobile } from '../carouselConstants';
import TopModuleCard from './TopModuleCard';
import styles from './TopModule.module.scss';
import { River, RiverItem } from '@/types/shared';

type Props = {
  topModule: River;
  locationName?: string;
  id: number;
  pageType?: PageType;
};

const TopModuleSection = forwardRef<HTMLDivElement, Props>(({ topModule, locationName, id, pageType }, ref) => {
  const intl = useIntl();
  const carousel = useRef(false);
  const { width } = useWindowSize();
  const shouldHideOverflow = width >= 1920;

  const { items, type, last } = topModule;
  const titleToRender = locationName
    ? intl.formatMessage({ defaultMessage: '{topParksTitle} in {locationName}' }, { topParksTitle: topModule.title, locationName })
    : topModule.title;
  const isMobile = useIsMobileSizedScreen();

  return (
    <div className={styles.topModuleContainer} ref={ref}>
      <div className={styles.titleAndButtonContainer}>
        <h2 className={styles.moduleTitle}>{titleToRender}</h2>
        <div className={styles.desktopShowMore}>
          <Button
            text={intl.formatMessage({ defaultMessage: 'Show all' })}
            testId="show-all-top-module-top"
            linkInfo={{ href: last.url, target: '_blank' }}
          />
        </div>
      </div>
      <Carousel
        className={styles.carousel}
        slidesPerViewMobile={slidesPerViewMobile}
        slidesPerViewBreakpoints={slidesPerViewBreakpoints}
        uniqueId={`top-module-${type}`}
        items={items}
        showPaginationDots={false}
        showOverflow={!shouldHideOverflow}
        renderItem={(item: RiverItem): JSX.Element => <TopModuleCard item={item} id={id} pageType={pageType} />}
        getItemKey={(item: RiverItem) => item?.id?.toString()}
        cardAspectRatio={isMobile ? '3/4' : '2/3'}
        onSlideChange={() => {
          if (!carousel.current && pageType) {
            logTopModuleSwiped({ id: String(id), page_type: pageType });
            carousel.current = true;
          }
        }}
      />
      <div className={styles.mobileShowMore}>
        <Button
          text={intl.formatMessage({ defaultMessage: 'Show all' })}
          testId="show-all-top-module"
          linkInfo={{ href: last.url, target: '_blank' }}
        />
      </div>
    </div>
  );
});

export default TopModuleSection;
