import { useMemo, useState } from 'react';
import { AlgoliaHit } from '@alltrails/search/types/algoliaResultTypes';

export type AdminAlgoliaHit = AlgoliaHit & {
  date?: Date;
  created_at?: string;
  metadata?: { created?: string };
  area_name?: string;
  park_name?: string;
};

const getResultDate = (result: AdminAlgoliaHit) => {
  if (result.created_at) {
    return new Date(result.created_at);
  }
  if (result.metadata?.created) {
    return new Date(result.metadata.created);
  }
  return new Date();
};

const useFilteredResults = (maxCount?: number, minimumDate?: Date) => {
  const [results, setResults] = useState<AdminAlgoliaHit[]>([]);

  const filteredResults = useMemo(() => {
    if (!results?.length) {
      return [];
    }

    if (!maxCount && !minimumDate) {
      return results;
    }

    let updatedResults = results.reduce((allResults, result) => {
      const date = getResultDate(result);
      if (minimumDate && date < minimumDate) {
        // Result is too old, do nothing
      } else {
        allResults.push({ ...result, date });
      }

      return allResults;
    }, [] as AdminAlgoliaHit[]);

    if (maxCount) {
      updatedResults = updatedResults.sort((a, b) => b.date!.getTime() - a.date!.getTime()).slice(0, maxCount);
    }

    return updatedResults;
  }, [maxCount, minimumDate, results]);

  return useMemo(() => [filteredResults, setResults] as const, [filteredResults]);
};

export default useFilteredResults;
