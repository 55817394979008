import { Layer, Source } from 'react-map-gl';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/denali/tokens';
import type { PinType } from '../../types/Images';
import type { AllTrailsResult } from '../../types/Results';
import useMap from '../../hooks/useMap';
import useMarkerLayerMouseClickEvents from '../../hooks/useMarkerLayerMouseClickEvents';
import useFeatures from '../../hooks/useFeatures';
import { useSelector } from '../../redux';
import { defaultSymbolLayerLayoutProps, getClusteredPinsId, getUnclusteredPinsId } from '../../utils/layers';
import useClusterLayerMouseClickEvents from '../../hooks/useClusterLayerMouseClickEvents';
import { getPinImageKey } from '../../utils/images';
import {
  expressionAggregateResultsCount,
  expressionIsNotSelectedTrail,
  expressionIsSelectedTrail,
  expressionIsSelectedTrailhead,
  expressionIsNotSelectedTrailhead
} from './expressions';

// Max zoom on which to cluster points if clustering is enabled. Clusters are re-evaluated at integer zoom levels so setting clusterMaxZoom to 14 means the clusters will be displayed until z15.
// https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson-clusterMaxZoom
const CLUSTER_MAX_ZOOM = 10;
// Radius of each cluster if clustering is enabled. A value of 512 indicates a radius equal to the width of a tile.
// https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson-clusterRadius
const CLUSTER_RADIUS = 40;

type Props = {
  pinType: PinType;
  shouldCluster?: boolean;
  onMarkerDoubleClick?: (result: AllTrailsResult) => void;
};

const ClusteredMarkers = ({ pinType, shouldCluster = true, onMarkerDoubleClick }: Props) => {
  const map = useMap();
  const isMobile = useIsMobileSizedScreen();
  const features = useFeatures(pinType);

  const sourceId = `${pinType}-source`;

  const { activeClusterId, clickedResult, hoveredSerializedCoordinates, clickedSerializedCoordinates, trailheadResults, allowClickEvents } =
    useSelector(state => ({
      activeClusterId: state.map.activeClusterId,
      clickedResult: state.map.clickedResult,
      hoveredSerializedCoordinates: state.map.hoveredSerializedCoordinates,
      clickedSerializedCoordinates: state.map.clickedSerializedCoordinates,
      trailheadResults: state.map.trailheadResults,
      allowClickEvents: state.map.allowClickEvents
    }));

  const handleMarkerDoubleClick = () => {
    if (clickedResult && onMarkerDoubleClick && allowClickEvents) {
      onMarkerDoubleClick(clickedResult);
    }
  };

  useMarkerLayerMouseClickEvents({
    map,
    onMarkerDoubleClick: handleMarkerDoubleClick,
    hoveredSerializedCoordinates,
    clickedSerializedCoordinates,
    trailheadResults,
    allowClickEvents,
    layerIds: [getUnclusteredPinsId(pinType)]
  });

  useClusterLayerMouseClickEvents({
    layerId: getClusteredPinsId(pinType),
    map,
    sourceId,
    activeClusterId,
    allowClickEvents
  });

  return (
    <Source
      id={sourceId}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features
      }}
      cluster={shouldCluster}
      clusterMaxZoom={CLUSTER_MAX_ZOOM}
      clusterRadius={CLUSTER_RADIUS}
      clusterProperties={{
        totalCount: expressionAggregateResultsCount()
      }}
    >
      {/* Non-clustered pins */}
      <Layer
        type="symbol"
        id={getUnclusteredPinsId(pinType)}
        filter={['!', ['has', 'totalCount']]}
        layout={{
          ...defaultSymbolLayerLayoutProps,
          'text-allow-overlap': true,
          'text-ignore-placement': true,

          'icon-anchor': 'bottom',
          'icon-image': [
            'case',

            // For performance reasons the unselected pins, which are the vast
            // majority of pins on a search map at any given time, are rendered
            // using a layer. Individual selected pins are rendered separately
            // using the <Marker /> component.

            // "normal" trail results when not in the selected state:
            expressionIsNotSelectedTrail(hoveredSerializedCoordinates, clickedSerializedCoordinates),
            getPinImageKey(pinType, 'pinImage'),

            // Selected pins for "normal" results: render transparent
            // rectangle so that mousemove events can be triggered in `useMapMouseMoveEvents`:
            expressionIsSelectedTrail(hoveredSerializedCoordinates, clickedSerializedCoordinates),
            getPinImageKey(pinType, 'pinTransparent'),

            // "Normal" trailhead results when not in the selected state:
            expressionIsNotSelectedTrailhead(hoveredSerializedCoordinates, clickedSerializedCoordinates),
            ['concat', pinType, '-pinTrailheadImage', ['min', ['get', 'resultsCount'], 9]],

            // Selected trailhead results: render transparent
            // rectangle so that mousemove events can be triggered in `useMapMouseMoveEvents`:
            expressionIsSelectedTrailhead(hoveredSerializedCoordinates, clickedSerializedCoordinates, isMobile),
            getPinImageKey(pinType, 'pinTrailheadTransparent'),

            ''
          ],

          'icon-offset': [0, 8]
        }}
      />
      {/*
       * Clustered circles. Note that a Mapbox cluster uses the same source as
       * the "normal" pins, but the filters are mutually exclusive
       */}
      <Layer
        type="symbol"
        id={getClusteredPinsId(pinType)}
        filter={['has', 'totalCount']}
        layout={{
          ...defaultSymbolLayerLayoutProps,
          'text-allow-overlap': true,
          'text-ignore-placement': true,

          'icon-image': [
            'case',

            // Clicked image for clusters.
            ['==', ['get', 'cluster_id'], activeClusterId || null],
            getPinImageKey(pinType, 'clusterImageSelected'),

            // Standard cluster images.
            getPinImageKey(pinType, 'clusterImage')
          ],

          'text-field': '{totalCount}',
          'icon-offset': [0, 2]
        }}
        paint={{ 'text-color': COLOR_TEXT_PRIMARY_INVERSE }}
      />
    </Source>
  );
};

export default ClusteredMarkers;
