import { PinImageConfig } from '../../types/Images';
import pinImage from './pin.png';
import pinImageSelected from './pinSelected.png';
import pinTrailheadImage1 from './pinTrailhead1.png';
import pinTrailheadImage2 from './pinTrailhead2.png';
import pinTrailheadImage3 from './pinTrailhead3.png';
import pinTrailheadImage4 from './pinTrailhead4.png';
import pinTrailheadImage5 from './pinTrailhead5.png';
import pinTrailheadImage6 from './pinTrailhead6.png';
import pinTrailheadImage7 from './pinTrailhead7.png';
import pinTrailheadImage8 from './pinTrailhead8.png';
import pinTrailheadImage9 from './pinTrailhead9.png';
import pinTrailheadImageSelected1 from './pinTrailheadSelected1.png';
import pinTrailheadImageSelected2 from './pinTrailheadSelected2.png';
import pinTrailheadImageSelected3 from './pinTrailheadSelected3.png';
import pinTrailheadImageSelected4 from './pinTrailheadSelected4.png';
import pinTrailheadImageSelected5 from './pinTrailheadSelected5.png';
import pinTrailheadImageSelected6 from './pinTrailheadSelected6.png';
import pinTrailheadImageSelected7 from './pinTrailheadSelected7.png';
import pinTrailheadImageSelected8 from './pinTrailheadSelected8.png';
import pinTrailheadImageSelected9 from './pinTrailheadSelected9.png';
import pinTrailheadTransparent from './pinTrailheadTransparent.png';
import pinTransparent from './pinTransparent.png';
import clusterImage from './cluster.png';
import clusterImageSelected from './clusterSelected.png';

const trailImages: PinImageConfig = {
  pinImage: pinImage.src,
  pinImageSelected: pinImageSelected.src,
  pinTrailheadImage1: pinTrailheadImage1.src,
  pinTrailheadImage2: pinTrailheadImage2.src,
  pinTrailheadImage3: pinTrailheadImage3.src,
  pinTrailheadImage4: pinTrailheadImage4.src,
  pinTrailheadImage5: pinTrailheadImage5.src,
  pinTrailheadImage6: pinTrailheadImage6.src,
  pinTrailheadImage7: pinTrailheadImage7.src,
  pinTrailheadImage8: pinTrailheadImage8.src,
  pinTrailheadImage9: pinTrailheadImage9.src,
  pinTrailheadImageSelected1: pinTrailheadImageSelected1.src,
  pinTrailheadImageSelected2: pinTrailheadImageSelected2.src,
  pinTrailheadImageSelected3: pinTrailheadImageSelected3.src,
  pinTrailheadImageSelected4: pinTrailheadImageSelected4.src,
  pinTrailheadImageSelected5: pinTrailheadImageSelected5.src,
  pinTrailheadImageSelected6: pinTrailheadImageSelected6.src,
  pinTrailheadImageSelected7: pinTrailheadImageSelected7.src,
  pinTrailheadImageSelected8: pinTrailheadImageSelected8.src,
  pinTrailheadImageSelected9: pinTrailheadImageSelected9.src,
  pinTrailheadTransparent: pinTrailheadTransparent.src,
  pinTransparent: pinTransparent.src,
  clusterImage: clusterImage.src,
  clusterImageSelected: clusterImageSelected.src
};

export default trailImages;
