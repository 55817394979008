import { useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { FormattedMessage } from 'react-intl';
import WaypointPopup from '../WaypointPopup';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import { defaultSymbolLayerLayoutProps, endMarkerId, getLayerBeforeId, startEndMarkerId, startMarkerId } from '../../utils/layers';
import startSvg from './startSvg';
import endSvg from './endSvg';
import startEndSvg from './startEndSvg';

const svgSize = 24;
const svgSizeWithoutShadow = 20;

type StartEndMarkerProps = {
  latitude: number;
  longitude: number;
  variant: 'start' | 'end' | 'start-end';
};

const StartEndMarker = ({ latitude, longitude, variant }: StartEndMarkerProps) => {
  const map = useMap();

  const layerId = useMemo(() => {
    switch (variant) {
      case 'start':
        return startMarkerId;
      case 'end':
        return endMarkerId;
      case 'start-end':
        return startEndMarkerId;
    }
  }, [variant]);

  const svgSrc = useMemo(() => {
    switch (variant) {
      case 'start':
        return startSvg;
      case 'end':
        return endSvg;
      case 'start-end':
        return startEndSvg;
    }
  }, [variant]);

  const imageIsLoaded = useSvgImage(variant, svgSrc, svgSize, svgSize);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    map?.on('mouseenter', layerId, () => {
      setShowPopup(true);
    });

    map?.on('mouseleave', layerId, () => {
      setShowPopup(false);
    });
  }, [map, layerId]);

  const title = useMemo(() => {
    switch (variant) {
      case 'start':
        return <FormattedMessage defaultMessage="Start" />;
      case 'end':
        return <FormattedMessage defaultMessage="End" />;
      case 'start-end':
        return <FormattedMessage defaultMessage="Start / End" />;
    }
  }, [variant]);

  if (!imageIsLoaded) {
    return null;
  }

  return (
    <>
      <Source
        type="geojson"
        data={{
          type: 'Point',
          coordinates: [longitude, latitude]
        }}
      >
        <Layer
          id={layerId}
          beforeId={getLayerBeforeId(map, layerId)}
          type="symbol"
          layout={{
            ...defaultSymbolLayerLayoutProps,
            'icon-image': variant
          }}
        />
      </Source>
      {showPopup && <WaypointPopup anchorSize={svgSizeWithoutShadow} latitude={latitude} longitude={longitude} title={title} />}
    </>
  );
};

export default StartEndMarker;
