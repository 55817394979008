import { type FeatureCollection } from '@turf/helpers';
import { LngLat } from '../types/Geo';

const getCoordinatesFromFeatures = (featureCollection?: FeatureCollection | null) => {
  const geometry = featureCollection?.features?.[0]?.geometry;
  if (geometry?.type === 'MultiLineString') {
    return geometry?.coordinates?.[0] as LngLat[] | undefined;
  }

  return undefined;
};

export default getCoordinatesFromFeatures;
