import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Feature, Geometry } from 'geojson';
import { Waypoint, getTrailWaypoints } from '@alltrails/waypoints';
import { useGroupHover } from '@alltrails/core';
import WaypointPopup from '../WaypointPopup';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import { defaultSymbolLayerLayoutProps, getLayerBeforeId, userWaypointsId } from '../../utils/layers';
import userWaypointSvg from './userWaypointSvg';

const svgSize = 24;
const svgSizeWithoutShadow = 20;
const imageName = 'user-waypoint';

// If we just used the normal Waypoint type for the feature properties, the nested objects (like location) get stringified making it more complex
// to parse those values back from mapbox. So, flatten any nested fields at the time of feature creation to simplify the rest of the code.
type FeatureProperties = Waypoint & {
  latitude: number;
  longitude: number;
};

type UserWaypointsProps = { trailId: number };

const UserWaypoints = ({ trailId }: UserWaypointsProps) => {
  const [waypoints, setWaypoints] = useState<Waypoint[]>();
  const map = useMap();

  const imageIsLoaded = useSvgImage(imageName, userWaypointSvg, svgSize, svgSize);

  useEffect(() => {
    const fetchWaypoints = async () => {
      getTrailWaypoints(trailId)
        .then(setWaypoints)
        .catch(e => {
          console.log('Error fetching waypoints');
        });
    };
    fetchWaypoints();
  }, [trailId]);

  const features = useMemo(() => {
    if (!waypoints?.length) {
      return [];
    }
    return waypoints.map(waypoint => {
      const { latitude, longitude } = waypoint.location;
      const feature: Feature<Geometry, FeatureProperties> = {
        geometry: { type: 'Point', coordinates: [longitude, latitude] },
        properties: { ...waypoint, latitude, longitude },
        type: 'Feature'
      };
      return feature;
    });
  }, [waypoints]);

  const [hoveredWaypoint, setHoveredWaypoint] = useState<FeatureProperties>();

  const onHover = useCallback((waypoint: FeatureProperties) => {
    setHoveredWaypoint(waypoint);
  }, []);

  const onHoverEnd = useCallback(() => {
    setHoveredWaypoint(undefined);
  }, []);

  const { onMouseEnter, onMouseLeave } = useGroupHover(onHover, onHoverEnd, true);

  useEffect(() => {
    map?.on('mousemove', userWaypointsId, e => {
      if (onMouseEnter && e.features) {
        const waypoint = e.features[0].properties as FeatureProperties;
        onMouseEnter(waypoint);
      }
    });

    map?.on('mouseleave', userWaypointsId, e => {
      onMouseLeave?.();
    });
  }, [map, onMouseEnter, onMouseLeave]);

  const popup = useMemo(() => {
    if (!hoveredWaypoint) {
      return null;
    }

    const { description, latitude, longitude, name } = hoveredWaypoint;
    return (
      <WaypointPopup
        anchorSize={svgSizeWithoutShadow}
        description={description}
        latitude={latitude}
        longitude={longitude}
        onMouseEnter={() => onMouseEnter?.(hoveredWaypoint)} // Just prevents the popup from closing when the mouse leaves the map layer
        onMouseLeave={onMouseLeave}
        title={name}
      />
    );
  }, [hoveredWaypoint, onMouseEnter, onMouseLeave]);

  if (!imageIsLoaded) {
    return null;
  }

  return (
    <>
      <Source type="geojson" data={{ type: 'FeatureCollection', features }}>
        <Layer
          id={userWaypointsId}
          beforeId={getLayerBeforeId(map, userWaypointsId)}
          type="symbol"
          layout={{ ...defaultSymbolLayerLayoutProps, 'icon-image': imageName }}
        />
      </Source>
      {popup}
    </>
  );
};

export default UserWaypoints;
