import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles/styles.module.scss';

type ControlContainerProps = {
  children: ReactNode;
  className?: string;
  position: 'bottomCenter' | 'bottomLeft' | 'bottomRight' | 'topCenter' | 'topLeft' | 'topRight';
};

const ControlContainer = ({ children, className, position }: ControlContainerProps) => {
  return <div className={classNames(styles.container, styles[position], className)}>{children}</div>;
};

export default ControlContainer;
