import { DynamicStyleId, StyleId } from '../../types/Styles';
import { baseStyleCardConfigs, dynamicStyleCardConfigs, staticStyleCardConfigs } from '../../utils/styleCardConfigs';
import { isBaseStyle, isDynamicStyle, isStaticStyle } from '../../utils/styleIdHelpers';

const isAdminOrPLPRequiredAndAuthorized = (requiresAdmin?: boolean, requiresPLP?: boolean, isAdmin?: boolean, isPLP?: boolean) => {
  if (requiresAdmin && !isAdmin) {
    // Some admin styles are available to PLP users
    if (!requiresPLP || (requiresPLP && !isPLP)) {
      return false;
    }
  }

  if (requiresPLP && !isPLP) {
    // Some PLP styles are available to admin users
    if (!requiresAdmin || (requiresAdmin && !isAdmin)) {
      return false;
    }
  }

  return true;
};

type FilterHiddenStylesArgs = {
  ids: StyleId[];
  isAdmin?: boolean;
  isPLP?: boolean;
  isGpsiesUser?: boolean;
  is3dActive?: boolean;
  supportedDynamicStyleIds?: DynamicStyleId[];
};

const filterHiddenStyles = ({ ids, isAdmin, isPLP, isGpsiesUser, is3dActive, supportedDynamicStyleIds = [] }: FilterHiddenStylesArgs) => {
  return ids.filter(id => {
    if (isBaseStyle(id)) {
      const requiresAdmin = baseStyleCardConfigs[id].requiresAdmin;
      const requiresPLP = baseStyleCardConfigs[id].requiresPLP;
      return isAdminOrPLPRequiredAndAuthorized(requiresAdmin, requiresPLP, isAdmin, isPLP);
    }

    let hideFromPLP, requires2d, requiresAdmin, requiresPLP, requiresGPSies;
    if (isStaticStyle(id)) {
      const config = staticStyleCardConfigs[id];
      requires2d = config.requires2d;
      requiresAdmin = config.requiresAdmin;
      requiresPLP = config.requiresPLP;
      requiresGPSies = config.requiresGPSies;
    } else if (isDynamicStyle(id)) {
      if (!supportedDynamicStyleIds.includes(id)) {
        return false;
      }
      const config = dynamicStyleCardConfigs[id];
      hideFromPLP = config.hideFromPLP;
      requires2d = config.requires2d;
      requiresAdmin = config.requiresAdmin;
      requiresPLP = config.requiresPLP;
    }

    if (hideFromPLP && isPLP) {
      return false;
    }

    if (!isAdminOrPLPRequiredAndAuthorized(requiresAdmin, requiresPLP, isAdmin, isPLP)) {
      return false;
    }

    if (requires2d && is3dActive) {
      return false;
    }

    if (requiresGPSies && !isGpsiesUser && !isAdmin) {
      return false;
    }

    return true;
  });
};

export default filterHiddenStyles;
