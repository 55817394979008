import Icon from '../components/Icon';

const ZoomOut = Icon(
  'zoom-out',
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.75 11.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z" />
  </>
);

export default ZoomOut;
