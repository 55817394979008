import { AdminPinImageConfig } from '../../../types/Images';
import pinImage from '../../trail/pin.png';
import pinImageSelected from '../../trail/pinSelected.png';
import clusterImage from '../../trail/cluster.png';
import clusterImageSelected from '../../trail/clusterSelected.png';

const trailImages: AdminPinImageConfig = {
  pinImage: pinImage.src,
  pinImageSelected: pinImageSelected.src,
  clusterImage: clusterImage.src,
  clusterImageSelected: clusterImageSelected.src
};

export default trailImages;
