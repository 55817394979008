import { MouseEventHandler, ReactNode } from 'react';
import { Popup } from 'react-map-gl';
import { SPACE_50 } from '@alltrails/denali/tokens';
import Link from '@alltrails/denali/components/Link';
import Translation from '@alltrails/denali/icons/Translation';
import { FormattedMessage } from 'react-intl';
import styles from './styles/styles.module.scss';

type TranslationInfo = {
  isTranslated: boolean;
  onTranslateClick: () => void;
};

type WaypointPopupProps = {
  anchorSize: number;
  description?: ReactNode;
  latitude: number;
  longitude: number;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  title?: ReactNode;
  translationInfo?: TranslationInfo;
};

const WaypointPopup = ({ anchorSize, description, latitude, longitude, onMouseEnter, onMouseLeave, title, translationInfo }: WaypointPopupProps) => {
  return (
    <Popup
      closeButton={false}
      closeOnClick={false}
      focusAfterOpen={false}
      latitude={latitude}
      longitude={longitude}
      maxWidth="none"
      offset={anchorSize / 2 + SPACE_50}
    >
      <div className={styles.container} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        {translationInfo && (
          <div className={styles.translation}>
            <Link icon={{ Component: Translation }} noUnderline onClick={translationInfo.onTranslateClick} size="sm" variant="secondary">
              {translationInfo.isTranslated ? (
                <FormattedMessage defaultMessage="Show original" />
              ) : (
                <FormattedMessage defaultMessage="Show translation" />
              )}
            </Link>
          </div>
        )}
        <div className={styles.location}>
          {latitude}, {longitude}
        </div>
      </div>
    </Popup>
  );
};

export default WaypointPopup;
