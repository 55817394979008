import { getMapboxAccessToken } from '@alltrails/core';
import { AdminStyleSettings, BaseStyleId, StaticStyleId } from '../types/Styles';
import createRasterStyle from './createRasterStyle';
import type { MapboxStyleIds } from './getMapboxStyleIds';

export const getBaseStyle = ({
  adminStyleSettings,
  styleId,
  hideCustomAttribution,
  mapboxStyleIds
}: {
  adminStyleSettings?: AdminStyleSettings;
  styleId: BaseStyleId;
  hideCustomAttribution: boolean;
  mapboxStyleIds: Pick<MapboxStyleIds, 'mapboxAllTrailsStyleId' | 'mapboxSatelliteStyleId' | 'mapbox3DBaseMapStyleId' | 'mapboxTerrainStyleId'>;
}) => {
  const mapboxAccessToken = getMapboxAccessToken();

  switch (styleId) {
    case 'adminBasemap':
      return createRasterStyle(styleId, adminStyleSettings?.styleSourceUrls?.adminBasemap || '', {
        tileSize: 256,
        maxzoom: 19,
        attribution: hideCustomAttribution ? '' : `Custom Basemap ${adminStyleSettings?.styleSourceUrls?.adminBasemap}`
      });
    case 'alltrailsOutdoorsV2':
      return `https://api.mapbox.com/styles/v1/alltrails/${mapboxStyleIds.mapboxAllTrailsStyleId}?access_token=${mapboxAccessToken}`;
    case 'alltrailsSatellite':
      return `https://api.mapbox.com/styles/v1/alltrails/${mapboxStyleIds.mapboxSatelliteStyleId}?access_token=${mapboxAccessToken}`;
    case 'alltrailsSatelliteMinimal':
      return `https://api.mapbox.com/styles/v1/alltrails/${mapboxStyleIds.mapbox3DBaseMapStyleId}?access_token=${mapboxAccessToken}`;
    case 'googleSatellite':
      return createRasterStyle(styleId, 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        attribution: 'Map data ©2023',
        tileSize: 256,
        maxzoom: 19
      });
    case 'opencycle':
      return createRasterStyle(styleId, 'https://tiles-ocm.alltrails.com/{z}/{x}/{y}.png', {
        attribution: '© <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        tileSize: 256,
        maxzoom: 19,
        minzoom: 4
      });
    case 'openstreet':
      return createRasterStyle(styleId, 'https://tiles-osm.alltrails.com/{z}/{x}/{y}.png', {
        attribution: '© <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        tileSize: 256,
        maxzoom: 19,
        minzoom: 4
      });
    case 'roadmap':
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v10?access_token=${mapboxAccessToken}`;
    case 'topo':
      return createRasterStyle(styleId, 'https://us-topo.alltrails.com/{z}/{x}/{y}.png', {
        attribution: '© 2020 AllTrails',
        tileSize: 256,
        maxzoom: 16,
        minzoom: 4
      });
    case 'worldparks':
      return `https://api.mapbox.com/styles/v1/alltrails/${mapboxStyleIds.mapboxTerrainStyleId}?access_token=${mapboxAccessToken}`;
    case 'worldtopo':
      return createRasterStyle(styleId, 'https://tiles-wtopo.alltrails.com/{z}/{y}/{x}.png', {
        attribution: 'Copyright © 2013 ESRI',
        tileSize: 256,
        maxzoom: 19
      });
  }
};

export const getStaticStyle = ({
  adminStyleSettings,
  styleId,
  hideCustomAttribution,
  mapboxStyleIds
}: {
  adminStyleSettings?: AdminStyleSettings;
  styleId: StaticStyleId;
  hideCustomAttribution: boolean;
  mapboxStyleIds: Pick<MapboxStyleIds, 'mapboxLightPollutionStyleId'>;
}) => {
  const mapboxAccessToken = getMapboxAccessToken();
  const date = new Date().getTime();
  const fifteenMin = 1000 * 60 * 15;
  const fifteenFloorTimestamp = new Date(Math.floor(date / fifteenMin) * fifteenMin).getTime() / 1000;
  const fifteenCeilTimestamp = new Date(Math.ceil(date / fifteenMin) * fifteenMin).getTime() / 1000;

  switch (styleId) {
    case 'adminOverlay':
      return createRasterStyle(
        styleId,
        adminStyleSettings?.styleSourceUrls?.adminOverlay || '',
        {
          tileSize: 256,
          maxzoom: 19,
          attribution: hideCustomAttribution ? '' : `Custom Overlay ${adminStyleSettings?.styleSourceUrls?.adminOverlay}`
        },
        { paint: { 'raster-opacity': 0.5 } }
      );
    case 'airQuality':
      return createRasterStyle(
        styleId,
        'https://air-quality.alltrails.com/{z}/{x}/{y}',
        {
          attribution: "<a target='_blank' href='https://breezometer.com'>BreezoMeter</a>",
          maxzoom: 16,
          tileSize: 256
        },
        { paint: { 'raster-opacity': 0.8 } }
      );
    case 'lightPollution':
      return createRasterStyle(
        styleId,
        `https://api.mapbox.com/styles/v1/alltrails/${mapboxStyleIds.mapboxLightPollutionStyleId}/tiles/512/{z}/{x}/{y}@2x?access_token=${mapboxAccessToken}`,
        {
          attribution: "<a target='_blank' href='https://www.ngdc.noaa.gov'>NOAA</a>",
          tileSize: 512
        },
        { paint: { 'raster-opacity': 0.5 } }
      );
    case 'pollen':
      return createRasterStyle(
        styleId,
        `https://pollen-daily.alltrails.com/{z}/{x}/{y}`,
        {
          attribution: "<a target='_blank' href='https://breezometer.com'>BreezoMeter</a>",
          maxzoom: 16,
          tileSize: 256
        },
        { paint: { 'raster-opacity': 0.8 } }
      );
    case 'waymarkedCycling':
      return createRasterStyle(
        styleId,
        'https://waymarked-cycling.alltrails.com/{z}/{x}/{y}',
        {
          attribution: "<a target='_blank' href='https://http://www.waymarkedtrails.org/'>Waymarked Trails</a>",
          tileSize: 256,
          maxzoom: 16
        },
        { paint: { 'raster-opacity': 0.8 } }
      );
    case 'waymarkedHiking':
      return createRasterStyle(
        styleId,
        'https://waymarked-hiking.alltrails.com/{z}/{x}/{y}',
        {
          attribution: "<a target='_blank' href='https://www.waymarkedtrails.org/'>Waymarked Trails</a>",
          tileSize: 256,
          maxzoom: 16
        },
        { paint: { 'raster-opacity': 0.8 } }
      );
    case 'waymarkedMTB':
      return createRasterStyle(
        styleId,
        'https://waymarked-mtb.alltrails.com/{z}/{x}/{y}',
        {
          attribution: "<a target='_blank' href='https://www.waymarkedtrails.org/'>Waymarked Trails</a>",
          tileSize: 256,
          maxzoom: 16
        },
        { paint: { 'raster-opacity': 0.8 } }
      );
    case 'weather':
      /*
       * Weather tiles are served through a Cloudfront Distribution, which can be access by the subdomain weather-radar.alltrails.com.
       * The Cloudfront distribution redirects the request to the internal AllTrails API,
       * which uses Ngnix reverse-proxy to send the final request to the Weather Channel API.
       *
       * The tiles are cached for 15 minutes by CloudFront
       * based on the timestamp (in 15 minute increments) and the X,Y,Z coordinates requested in the query string.
       *
       * Cloudfront Distribution:
       *  - Origin: api-v5.alltrails.com/tiles/v4/radarSatellite
       *  - Cache:
       *     - Query string:
       *        - ts
       *        - fts
       *        - xyz
       *     - TTL: 15 minutes
       *  - Configurations: https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=us-west-2#/distributions/E115DZ1OVR175S
       *
       * Route53:
       *  - Configurations: https://us-east-1.console.aws.amazon.com/route53/v2/hostedzones?region=us-east-1#ListRecordSets/Z2KA02DV082R8Y
       *
       * Ngnix:
       *  - Configurations: https://github.com/alltrails/alltrails-nginx/blob/alpha/templates/map_layers_overlays.conf#L443
       */
      return createRasterStyle(
        styleId,
        `https://weather-radar.alltrails.com?ts=${fifteenFloorTimestamp}&fts=${fifteenCeilTimestamp}&xyz={x}:{y}:{z}`,
        {
          attribution: "<a target='_blank' href='https://www.weather.com/'>Weather.com</a>",
          tileSize: 256
        },
        { paint: { 'raster-opacity': 0.5 } }
      );
  }
};
