import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import IconButton from '@alltrails/denali/components/IconButton';
import ArrowSq from '@alltrails/denali/icons/ArrowSq';
import { useDispatch, useSelector } from '../../redux';
import type { AllTrailsResult } from '../../types/Results';
import { trailheadMarkerOffset } from '../../utils/constants';
import { updateClickedCoordinates } from '../../redux/reducer';
import styles from './styles/styles.module.scss';

type Props = {
  currentResult?: AllTrailsResult;
};

const TrailheadMarker = ({ currentResult }: Props) => {
  const dispatch = useDispatch();

  const { trailheadResults, clickedResult } = useSelector(state => ({
    trailheadResults: state.map.trailheadResults,
    clickedResult: state.map.clickedResult
  }));
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalResultSlides = useMemo(() => (trailheadResults ? trailheadResults.length : 0), [trailheadResults]);

  const [carouselPinClassName, iconButtonClassName] = useMemo(() => {
    if (trailheadResults && currentResult?.type) {
      const resultsAreTrails = currentResult?.type === 'trail';
      const resultsAreTracks = currentResult?.type === 'track';
      if (resultsAreTrails) {
        return [styles.trailCarouselPin, ''];
      }
      if (resultsAreTracks) {
        return [styles.activityCarouselPin, styles.trackIconButton];
      }
    }
    return [styles.trailCarouselPin, ''];
  }, [currentResult?.type, trailheadResults]);

  useEffect(
    () =>
      trailheadResults && currentResult && trailheadResults.indexOf(currentResult.ID) >= 0
        ? setCurrentIndex(trailheadResults.indexOf(currentResult.ID))
        : setCurrentIndex(0),
    [currentResult, trailheadResults]
  );

  const getCoordinates = (type: string, result?: AllTrailsResult) => {
    if (result) {
      if (result?._cluster_geoloc) {
        return result?._cluster_geoloc[type];
      }

      return result?._geoloc?.[type];
    }
    return null;
  };

  const clusterLongitude = getCoordinates('lng', currentResult);
  const clusterLatitude = getCoordinates('lat', currentResult);

  const normalizeIndex = useCallback(
    (newIndex: number) => {
      if (newIndex >= totalResultSlides) {
        return newIndex - totalResultSlides;
      }
      if (newIndex < 0) {
        return newIndex + totalResultSlides;
      }
      return newIndex;
    },
    [totalResultSlides]
  );

  const handleNavigate = useCallback(
    (newIndex: number, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => {
      event?.stopPropagation();

      const index = normalizeIndex(newIndex);

      if (index !== currentIndex || !clickedResult) {
        dispatch(updateClickedCoordinates?.({ index }));
      }
    },
    [currentIndex, clickedResult, dispatch, normalizeIndex]
  );

  return (
    <Marker latitude={clusterLatitude} longitude={clusterLongitude} offset={trailheadMarkerOffset}>
      <div
        className={classNames(styles.trailheadMarker)}
        onClick={event => handleNavigate(currentIndex, event)}
        onKeyDown={event => handleNavigate(currentIndex, event)}
        tabIndex={0}
        role="button"
      >
        <div className={carouselPinClassName}>
          <IconButton
            className={iconButtonClassName}
            icon={{ Component: ArrowSq, orientation: 'left' }}
            title="previous"
            onClick={event => handleNavigate(currentIndex - 1, event)}
            size="sm"
            testId="trailhead-previous"
            variant="primary"
          />
          <span className={styles.text}>
            {currentIndex + 1} <FormattedMessage defaultMessage="of" /> {trailheadResults?.length}
          </span>
          <IconButton
            className={iconButtonClassName}
            icon={{ Component: ArrowSq, orientation: 'right' }}
            title="next"
            onClick={event => handleNavigate(currentIndex + 1, event)}
            size="sm"
            testId="trailhead-next"
            variant="primary"
          />
        </div>
      </div>
    </Marker>
  );
};

export default memo(TrailheadMarker);
