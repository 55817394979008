import { AdminPinImageConfig } from '../../../types/Images';
import pinImage from '../../map/pin.png';
import pinImageSelected from '../../map/pinSelected.png';
import clusterImage from '../../map/clusterActivity.png';
import clusterImageSelected from '../../map/clusterActivitySelected.png';

const trackImages: AdminPinImageConfig = {
  pinImage: pinImage.src,
  pinImageSelected: pinImageSelected.src,
  clusterImage: clusterImage.src,
  clusterImageSelected: clusterImageSelected.src
};

export default trackImages;
