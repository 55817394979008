import type { MapboxStyle } from 'react-map-gl';
import type { LayerConfig, SourceConfig } from '../types/Styles';

const createRasterStyle = (id: string, url: string, sourceConfig: SourceConfig, layerConfig?: LayerConfig): MapboxStyle => ({
  sources: {
    [id]: {
      id,
      type: 'raster',
      tiles: [url],
      ...sourceConfig
    }
  },
  layers: [
    {
      id,
      source: id,
      type: 'raster',
      ...layerConfig
    }
  ],
  glyphs: 'mapbox://fonts/alltrails/{fontstack}/{range}.pbf',
  version: 8
});

export default createRasterStyle;
