import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Feature, Geometry } from 'geojson';
import turfDistance from '@turf/distance';
import { COLOR_TEXT_PRIMARY, SPACE_100, SPACE_50 } from '@alltrails/denali/tokens';
import { getUnitAbbreviation, milesToMeters } from '@alltrails/data-formatting';
import { useIntl } from 'react-intl';
import useSvgImage from '../../utils/useSvgImage';
import { useSelector } from '../../redux';
import { atMapsToGeojson } from '../../utils/legacyGeoJSONConversions';
import { LngLat } from '../../types/Geo';
import { defaultSymbolLayerLayoutProps, distanceMarkersId, getLayerBeforeId } from '../../utils/layers';
import useMap from '../../hooks/useMap';
import distanceMarkerSvg from './distanceMarkerSvg';

const svgWidth = 46;
const svgHeight = 30;
const svgShadowSize = 3;
const imageName = 'distance-marker';

const metersInAMile = milesToMeters(1);

const getTotalMeters = (coordinates: LngLat[]) => {
  return coordinates.reduce((meters, coordinate, index) => {
    if (index === 0) {
      return 0;
    }
    return meters + turfDistance(coordinates[index - 1], coordinate, { units: 'meters' });
  }, 0);
};

type DistanceMarkersProps = { atMap: any };

const DistanceMarkers = ({ atMap }: DistanceMarkersProps) => {
  const map = useMap();
  const intl = useIntl();
  const displayMetric = useSelector(state => state.context.displayMetric);

  const imageIsLoaded = useSvgImage(imageName, distanceMarkerSvg, svgWidth, svgHeight);

  const features = useMemo(() => {
    const geoJson = atMapsToGeojson([atMap]);
    if (!geoJson?.features?.[0]?.geometry?.coordinates?.length) {
      return [];
    }
    const coordinates = geoJson.features[0].geometry.coordinates[0] as LngLat[];
    const totalMeters = getTotalMeters(coordinates);

    let useTenths = false;
    if (displayMetric) {
      useTenths = totalMeters <= 1000;
    } else {
      useTenths = totalMeters <= metersInAMile;
    }

    let metersBetweenMarkers = 0;
    if (displayMetric) {
      metersBetweenMarkers = useTenths ? 100 : 1000;
    } else {
      metersBetweenMarkers = useTenths ? metersInAMile / 10 : metersInAMile;
    }

    const distanceMarkers: Feature<Geometry, { text: string }>[] = [];
    let distanceSinceLastMarker = 0;
    let markerNumber = 1;
    coordinates.forEach((coordinate, index) => {
      if (index === 0) {
        return;
      }

      const accumulatedDistance = distanceSinceLastMarker + turfDistance(coordinates[index - 1], coordinate, { units: 'meters' });
      if (accumulatedDistance >= metersBetweenMarkers) {
        const value = useTenths ? markerNumber / 10 : markerNumber;
        const unit = getUnitAbbreviation(intl, displayMetric ? 'kilometer' : 'mile').toLowerCase();
        distanceMarkers.push({
          geometry: { type: 'Point', coordinates: coordinate },
          properties: { text: `${value} ${unit}` },
          type: 'Feature'
        });

        distanceSinceLastMarker = 0;
        markerNumber += 1;
      } else {
        distanceSinceLastMarker = accumulatedDistance;
      }
    });

    return distanceMarkers;
  }, [atMap, displayMetric, intl]);

  if (!imageIsLoaded) {
    return null;
  }

  return (
    <Source type="geojson" data={{ type: 'FeatureCollection', features }}>
      <Layer
        id={distanceMarkersId}
        beforeId={getLayerBeforeId(map, distanceMarkersId)}
        type="symbol"
        layout={{
          ...defaultSymbolLayerLayoutProps,
          'icon-image': imageName,
          'icon-text-fit': 'both',
          'icon-text-fit-padding': [svgShadowSize + SPACE_50, svgShadowSize + SPACE_100, svgShadowSize + SPACE_50, svgShadowSize + SPACE_100],
          'text-anchor': 'center',
          'text-field': ['get', 'text']
        }}
        paint={{
          'text-color': COLOR_TEXT_PRIMARY
        }}
      />
    </Source>
  );
};

export default DistanceMarkers;
