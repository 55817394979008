import { getPinImages } from '../../utils/images';

// To be imported by any components using this component and passed as BaseMap props to prevent race conditions between Mapbox image and layer loading
const images = getPinImages([
  'pinImageSelected',
  'pinTrailheadImageSelected1',
  'pinTrailheadImageSelected2',
  'pinTrailheadImageSelected3',
  'pinTrailheadImageSelected4',
  'pinTrailheadImageSelected5',
  'pinTrailheadImageSelected6',
  'pinTrailheadImageSelected7',
  'pinTrailheadImageSelected8',
  'pinTrailheadImageSelected9'
]);

export default images;
