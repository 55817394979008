import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@alltrails/denali/components/IconButton';
import Link from '@alltrails/denali/components/Link';
import { useLanguageRegionCode } from '@alltrails/language';
import Close from '@alltrails/denali/icons/Close';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import { useSelector } from '../../redux';
import styles from './styles/styles.module.scss';

type Props = {
  isFlyoverEnabled?: boolean;
};

const TooltipFor3dMap = ({ isFlyoverEnabled = false }: Props) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const [isHidden, setIsHidden] = useState(false);

  const { is3dActive } = useSelector(state => ({
    is3dActive: state.map.is3dActive
  }));

  const faqUrl = useMemo(
    () => (isFlyoverEnabled ? getHelpCenterUrl(languageRegionCode, 13175186520340) : getHelpCenterUrl(languageRegionCode, 360058630271)),
    [isFlyoverEnabled, languageRegionCode]
  );

  if (isHidden || !is3dActive) {
    return null;
  }

  return (
    <div className={styles.tooltip3D}>
      <div className={styles.tooltip}>
        <span className={styles.textContent}>
          <span className={styles.title}>
            <FormattedMessage defaultMessage="3D tip" />:
          </span>
          <span className={styles.text}>
            <FormattedMessage defaultMessage={`Tilt and rotate by holding "control" and dragging up, down, left, or right.`} />
          </span>
          <Link className={styles.link} href={faqUrl} target="_blank" size="md" testId="tooltip-link">
            <FormattedMessage defaultMessage="Learn more" />
          </Link>
        </span>
        <IconButton
          icon={{ Component: Close }}
          onClick={() => {
            setIsHidden(true);
          }}
          testId="tooltip-close-button"
          title={intl.formatMessage({ defaultMessage: 'Close' })}
          variant="flat"
          size="sm"
        />
      </div>
    </div>
  );
};

export default TooltipFor3dMap;
