import { useEffect, useState } from 'react';
import { LngLatBounds } from 'mapbox-gl';
import useMap from '../../hooks/useMap';
import { useSelector } from '../../redux';
import HoveredResultCardAndPolyline from '../HoveredResultCardAndPolyline';
import HeatmapOverlay from './HeatmapOverlay';
import AllMapsOverlay from './AllMapsOverlay';
import PendingTrailsOverlay from './PendingTrailsOverlay';
import AllRecordingsOverlay from './AllRecordingsOverlay';
import PopularRecordingsOverlay from './PopularRecordingsOverlay';
import NearbyTrailsOverlay from './NearbyTrailsOverlay';
import GeoBoundariesOverlay from './GeoBoundariesOverlay';

type DynamicStyleOverlaysProps = {
  excludedTrailIds?: number[];
};

const daysAgoDate = (days: number) => {
  const nowInMs = new Date().getTime();
  const daysAgoInMs = days * (24 * 60 * 60 * 1000);
  return new Date(nowInMs - daysAgoInMs);
};

/**
 * Renders overlays for enabled dynamic map styles. Dynamic styles are ones that require some sort of work
 * (API request, Algolia search, etc) outside of just a layer from a static url.
 * Some dynamic styles are very specific to a trail map so they aren't included in this more general component.
 * Any other dynamic styles not listed here need implementation from the monolith.
 */
const DynamicStyleOverlays = ({ excludedTrailIds }: DynamicStyleOverlaysProps) => {
  const { dynamicStyleIds, heatmapStyleIds, supportedDynamicStyleIds } = useSelector(state => ({
    dynamicStyleIds: state.map.dynamicStyleIds,
    heatmapStyleIds: state.map.heatmapStyleIds,
    supportedDynamicStyleIds: state.map.supportedDynamicStyleIds
  }));
  const map = useMap();
  const [bounds, setBounds] = useState<LngLatBounds | undefined>(map?.getBounds());

  useEffect(() => {
    map?.on('moveend', e => {
      setBounds(e.target.getBounds());
    });
  }, [map]);

  return (
    <>
      {supportedDynamicStyleIds.map(id => {
        const isEnabled = dynamicStyleIds.includes(id);
        if (!isEnabled) {
          return null;
        }
        const maxResultCount = dynamicStyleIds.includes('limit') ? 50 : undefined;
        const minimumDate = dynamicStyleIds.includes('after') ? daysAgoDate(60) : undefined;
        const showHeatmap = heatmapStyleIds.includes(id);
        switch (id) {
          case 'heatmap':
            return <HeatmapOverlay key={id} bounds={bounds} maxResultCount={maxResultCount} minimumDate={minimumDate} />;
          case 'nearbyTrails':
            return <NearbyTrailsOverlay key={id} bounds={bounds} showHeatmap={showHeatmap} excludedTrailIds={excludedTrailIds} />;
          case 'mapsAll':
            return <AllMapsOverlay key={id} bounds={bounds} maxResultCount={maxResultCount} minimumDate={minimumDate} showHeatmap={showHeatmap} />;
          case 'pendingTrails':
            return (
              <PendingTrailsOverlay key={id} bounds={bounds} maxResultCount={maxResultCount} minimumDate={minimumDate} showHeatmap={showHeatmap} />
            );
          case 'recordingsAll':
            return (
              <AllRecordingsOverlay key={id} bounds={bounds} maxResultCount={maxResultCount} minimumDate={minimumDate} showHeatmap={showHeatmap} />
            );
          case 'recordingsPop':
            return (
              <PopularRecordingsOverlay
                key={id}
                bounds={bounds}
                maxResultCount={maxResultCount}
                minimumDate={minimumDate}
                showHeatmap={showHeatmap}
              />
            );
          case 'geoBoundaries':
            return <GeoBoundariesOverlay key={id} bounds={bounds} />;
          default:
            return null;
        }
      })}
      <HoveredResultCardAndPolyline />
    </>
  );
};

export default DynamicStyleOverlays;
