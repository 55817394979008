import { useEffect } from 'react';
import type { MapRef, PointLike } from 'react-map-gl';
import type { AllTrailsResult } from '../types/Results';
import type { PinType } from '../types/Images';
import { useDispatch } from '../redux';
import { clearActiveClusterId, clearHoveredCoordinates, setIsHoveringListViewResult, updateHoveredCoordinates } from '../redux/reducer';
import { getClusteredPinsId } from '../utils/layers';
import { coordinatesToSerializedCoordinates } from '../utils/serializedCoordinates';

type Args = {
  clusterId?: number;
  hoveredResult?: AllTrailsResult;
  map?: MapRef;
  updateClusterId: (id?: number) => void;
  isPageWithListHovering?: boolean;
};

export default function useUnclusteredMarkers({ clusterId, hoveredResult, map, updateClusterId, isPageWithListHovering }: Args): void {
  const dispatch = useDispatch();

  useEffect(() => {
    if (hoveredResult) {
      const resultCoordinates = hoveredResult?._cluster_geoloc ? hoveredResult._cluster_geoloc : hoveredResult?._geoloc;
      const newSerializedHoveredCoordinates = coordinatesToSerializedCoordinates(resultCoordinates);
      const point = map?.project(resultCoordinates);
      // Create a bbox around the point to determine which cluster the pin belongs to, if any:
      const bbox = point &&
        point?.x &&
        point?.y && [
          [point.x - 25, point.y - 25],
          [point.x + 25, point.y + 25]
        ];
      const pinType = hoveredResult.type as PinType;
      const layerId = getClusteredPinsId(pinType);
      const clustersAtPoint = bbox && map?.queryRenderedFeatures(bbox as [PointLike, PointLike], { layers: [layerId] });
      const newClusterId = clustersAtPoint?.[0]?.properties?.cluster_id;
      updateClusterId(newClusterId);
      if (isPageWithListHovering) {
        dispatch(setIsHoveringListViewResult());
      }
      dispatch(updateHoveredCoordinates({ coordinates: newSerializedHoveredCoordinates, index: undefined, resultId: hoveredResult?.ID }));
      if (!newClusterId) {
        dispatch(clearActiveClusterId());
      }
    } else {
      dispatch(clearHoveredCoordinates());
    }
  }, [dispatch, map, hoveredResult, clusterId, updateClusterId, isPageWithListHovering]);
}
