import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { FeatureCollection } from 'geojson';
import { useAuthorization } from '@alltrails/context';
import { post } from '@alltrails/shared/api';
import { COLOR_BLUE_700 } from '@alltrails/denali/tokens';
import { AlgoliaHit } from '@alltrails/search/types/algoliaResultTypes';
import { atMapsToGeojson, pointItemsToGeojson } from '../../utils/legacyGeoJSONConversions';
import { colorWithOpacityExpression } from '../../utils/colors';
import { getHeatmapPointsId, getHeatmapPolylinesId, getLayerBeforeId } from '../../utils/layers';
import useMap from '../../hooks/useMap';

type HeatmapLayerProps = {
  results: AlgoliaHit[];
  type: 'trail' | 'map';
  uniqueId?: string;
};

const HeatmapLayer = ({ results, type, uniqueId }: HeatmapLayerProps) => {
  const map = useMap();
  const [polylinesData, setPolylinesData] = useState<FeatureCollection>();
  const { hasPermission } = useAuthorization();

  useEffect(() => {
    const getPolylines = async () => {
      if (results.length === 0) {
        return;
      }

      const ids = results.map(result => result.ID);
      const payload = type === 'map' ? { map_ids: ids } : { trail_ids: ids };
      const data = await post<{ maps: unknown[] }>('/api/alltrails/maps/heatmap', payload);

      setPolylinesData(atMapsToGeojson(data.maps, true) as FeatureCollection);
    };
    getPolylines();
  }, [results, type]);

  const pointsData = pointItemsToGeojson(results) as FeatureCollection;

  if (!polylinesData) {
    return null;
  }

  const pointsLayerId = getHeatmapPointsId(uniqueId);
  const polylinesLayerId = getHeatmapPolylinesId(uniqueId);

  return (
    <>
      <Source type="geojson" data={polylinesData}>
        <Layer
          id={polylinesLayerId}
          beforeId={getLayerBeforeId(map, polylinesLayerId)}
          type="line"
          paint={
            hasPermission({ permission: 'trails:manage' }) && type === 'trail'
              ? { 'line-color': colorWithOpacityExpression('red', 0.5 / (1.0 + 0.5 * Math.log10(results.length || 0))), 'line-width': 12 }
              : {
                  'line-color': colorWithOpacityExpression(COLOR_BLUE_700, 0.3),
                  'line-width': 5
                }
          }
        />
      </Source>
      <Source type="geojson" data={pointsData}>
        <Layer beforeId={polylinesLayerId} id={pointsLayerId} type="heatmap" paint={{ 'heatmap-intensity': 0.8, 'heatmap-opacity': 0.3 }} />
      </Source>
      {/* 
        This wasn't doing anything so its just commented out in case we want to implement its functionality.
        From preview_lines.js in the monolith, it seems that hovering/clicking on trail pins injects data into the source
        so that a trail polyline renders as part of the heatmap styles. This logic wasn't brought over to mugen but if we want
        it to, we should route it through redux rather than directly interacting with the map source to match our new patterns.
        If this is added back in, make sure the chevron images are being passed in to BaseMap for maps that support this layer. 
       */}
      {/* <Source  type="geojson" data={featureCollection([])}>
        {[-1, 0, 1, 0].map((coord, index) => {
          const compoundKey = `${coord}-${index}`;

          return (
            <Layer
              key={`preview-lines-${compoundKey}`}
              type="line"
              layout={{
                'line-join': 'round',
                'line-cap': 'round'
              }}
              paint={{
                'line-color': index === 3 ? COLOR_UI_HIGHLIGHT : COLOR_GREEN_700,
                'line-width': index === 3 ? 4 : 2,
                'line-offset': coord * 3
              }}
            />
          );
        })}
        <Layer
          type="symbol"
          layout={{
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-image': ['image', chevronImageKey],
            'icon-rotate': 90,
            'symbol-placement': 'line',
            'symbol-spacing': 50
          }}
        />
      </Source> */}
    </>
  );
};

export default HeatmapLayer;
