import type { TrailSearchResult as AlgoliaTrail } from '@alltrails/search/types/algoliaResultTypes';
import { get, post } from '@alltrails/shared/api';
import { Map } from '@alltrails/shared/types/map';
import type { TrailId, TrailPhoto } from '@alltrails/shared/types/trail';

export type NearbyTrailsParams = {
  page: number;
  per_page: number;
};

export type NearbyTrailsResponse = {
  trails: AlgoliaTrail[];
};

type PhotosResponse = {
  photos: TrailPhoto[];
};

type MapsResponse = {
  maps: Map[];
};

export function getNearbyTrails(trailId: TrailId, params?: NearbyTrailsParams) {
  return get<NearbyTrailsResponse>(`/api/alltrails/v2/trails/${trailId}/nearby_trails`, { params: { ...params, algolia_formatted: true } });
}

export function getPhotos(trailId: TrailId) {
  return get<PhotosResponse>(`/api/alltrails/v2/trails/${trailId}/photos`, {
    params: {
      with_location: 'Y',
      per_page: 100
    }
  });
}

export async function copyVerifiedRoute(trailId, name, detail = 'deep') {
  const response = await post<MapsResponse>(`/api/alltrails/trails/${trailId}/verified/copy`, {
    detail,
    name
  });
  return response.maps[0];
}
