/* eslint-disable @next/next/no-img-element */
import { ComponentProps } from 'react';
import { getPinImage } from '../../utils/images';
import MapMarker from '../MapMarker';
import { PinImageKey, PinType } from '../../types/Images';
import styles from './styles/styles.module.scss';

type BasicMarkerProps = { alt?: string; pinType: PinType; pinImageKey: PinImageKey } & Pick<
  ComponentProps<typeof MapMarker>,
  'latitude' | 'longitude'
>;

const BasicMarker = ({ alt, pinType, pinImageKey, ...markerProps }: BasicMarkerProps) => {
  return (
    <MapMarker {...markerProps}>
      <img className={styles.img} src={getPinImage(pinType, pinImageKey)} alt="" />
    </MapMarker>
  );
};

export default BasicMarker;
