import Icon from '../components/Icon';

const ZoomIn = Icon(
  'zoom-in',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3.75a.75.75 0 0 0-1.5 0v7.5h-7.5a.75.75 0 0 0 0 1.5h7.5v7.5a.75.75 0 0 0 1.5 0v-7.5h7.5a.75.75 0 0 0 0-1.5h-7.5v-7.5Z"
    />
  </>
);

export default ZoomIn;
