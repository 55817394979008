type Color = string | unknown[];

type DeconstructedColor = ['at', number, ['to-rgba', Color]];

/**
 * colorWithOpacityExpression takes any color and mutates it to add an opacity. This is useful for
 * dynamically altering the opacity of a polyline. We can pass in a coalesce expression or simple color
 * and set the opacity as needed. We do this to satisfy XPLOR-862. As of that ticket we are on Mapbox SDK
 * >=2.7.0. From that version on having a separate line-opacity and line-color results in removing artifacts
 * when polylines overlap. We want to *maintain* the behavior with the artifacts so that dense overlapping
 * areas are darker. To do that we must migrate opacity settings into line-color.
 *
 * @param color string | unknown[]
 * @param opacity number
 * @returns Expression
 */

// eslint-disable-next-line import/prefer-default-export
export function colorWithOpacityExpression(
  color: Color,
  opacity: number
): ['rgba', DeconstructedColor, DeconstructedColor, DeconstructedColor, number] {
  const [red, green, blue] = new Array(3).fill(0).map((_, index: number): DeconstructedColor => ['at', index, ['to-rgba', color]]);

  // Break down the current color into its rgba components and then reassemble with the new opacity.
  return ['rgba', red, green, blue, opacity];
}
