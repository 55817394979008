import { useLanguageRegionCode } from '@alltrails/language';
import BaseActivityCard from '@alltrails/shared/components/ActivityCard';
import { useSelector } from '../../redux';
import useAdminFooterCTAs from '../../hooks/useAdminFooterCTAs';
import useAdminTrackLinks from './useAdminTrackLinks';
import { AdminTrackHit, trackHitToActivity } from './utils';

type ActivityCardProps = {
  currentResult: AdminTrackHit;
  setIsPopupHovered: (isPopupHovered: boolean) => void;
};

const ActivityCard = ({ currentResult, setIsPopupHovered }: ActivityCardProps) => {
  const languageRegionCode = useLanguageRegionCode();
  const displayMetric = useSelector(state => state.context.displayMetric);
  const adminTrackLinks = useAdminTrackLinks(currentResult);
  const footerCTAs = useAdminFooterCTAs(adminTrackLinks);

  return (
    <BaseActivityCard
      languageRegionCode={languageRegionCode}
      onCardClick={() => window.open(`/explore/recording/${currentResult?.slug}`, '_blank')}
      onCardMouseEnter={() => setIsPopupHovered(true)}
      onCardMouseLeave={() => setIsPopupHovered(false)}
      variant="small"
      activity={trackHitToActivity(currentResult as AdminTrackHit)}
      useMetric={!!displayMetric}
      footerCTAs={footerCTAs}
      showActivityType
    />
  );
};

export default ActivityCard;
