const getMapboxAccessToken = () => {
  const token = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
  if (token) {
    return token;
  }

  throw new Error('Missing mapbox access token');
};

export default getMapboxAccessToken;
