import { useEffect, useMemo } from 'react';
import { defaultPitchFor3dTerrain } from '../utils/constants';
import useMap from './useMap';

export default function use3dMap(is3dActive = false, ignoreInitialPitch = false) {
  const map = useMap();

  useEffect(() => {
    if (is3dActive) {
      if (!ignoreInitialPitch) {
        map?.easeTo({ pitch: defaultPitchFor3dTerrain });
      }
    } else {
      map?.resetNorthPitch();
    }
    // Don't want to change the pitch here if ignoreInitialPitch changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is3dActive, map]);

  // Only return a new object if is3dActive has changed.
  return useMemo(
    () => ({
      styles: {
        fog: is3dActive
          ? {
              range: [2, 12],
              color: 'white',
              'horizon-blend': 0.1
            }
          : undefined,
        terrain: is3dActive
          ? {
              source: 'mapbox-terrain',
              exaggeration: 1.5
            }
          : undefined
      }
    }),
    [is3dActive]
  );
}
