import { useCallback } from 'react';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import User from '@alltrails/shared/types/User';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { useLocalStorage } from '@alltrails/local-storage';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';

function onAfterRoadblock(afterRoadblock: (() => void) | string, openInNewTab?: boolean) {
  if (typeof afterRoadblock === 'function') {
    afterRoadblock();
  } else if (openInNewTab) {
    window.open(afterRoadblock, '_blank');
  } else {
    window.location.assign(afterRoadblock);
  }
}

export default function useRoadblock({
  afterRoadblock,
  atRef,
  eventName,
  languageRegionCode,
  openInNewTab,
  returnToUrl,
  user,
  setIsInterstitialOpen
}: {
  afterRoadblock: (() => void) | string;
  atRef?: string;
  eventName: string;
  languageRegionCode: LanguageRegionCode;
  openInNewTab?: boolean;
  returnToUrl: string;
  user: User | null;
  setIsInterstitialOpen: (isInterstitialOpen: boolean) => boolean;
}) {
  const isMobile = useIsMobileSizedScreen();
  const { getValue, setValue } = useLocalStorage('authRoadblockCount');

  return useCallback(() => {
    if (!user) {
      const ref = atRef ? `ref=${atRef}&` : '';
      const returnTo = `returnTo=${encodeURIComponent(returnToUrl)}`;
      window.location.assign(wrapUrlSafe(`/signup?${ref}${returnTo}`, languageRegionCode));
      return;
    }

    // Proceed directly to next step if not mobile
    if (!isMobile) {
      onAfterRoadblock(afterRoadblock, openInNewTab);
      return;
    }

    // Only a subset of roadblocks apply to signed-in-user
    const signedInRoadblocks = ['trail_header_map', 'trail_explore', 'trail_print', 'park_print', 'create_map', 'print_maps', 'park_directions'];

    // Show only every third time
    const signedInRoadblockEvery = 3;
    if (signedInRoadblocks.includes(eventName)) {
      const authRoadblockCount = (Number(getValue()) + 1) % signedInRoadblockEvery;
      setValue(String(authRoadblockCount));

      if (authRoadblockCount + 1 === signedInRoadblockEvery) {
        // setIsInterstitialOpen will only open the interstitial if the dismissal duration has passed.
        // Verify that the interstitial will open before proceeding to the next step.
        const willOpenInterstitial = setIsInterstitialOpen(true);
        if (willOpenInterstitial) {
          return;
        }
      }
    }

    // No interstitial shown, so proceed directly to next step
    onAfterRoadblock(afterRoadblock, openInNewTab);
  }, [afterRoadblock, atRef, eventName, getValue, isMobile, languageRegionCode, openInNewTab, returnToUrl, setValue, user, setIsInterstitialOpen]);
}
