import { type ComponentProps, useState } from 'react';
import { useIntl } from 'react-intl';
import IconButtonGroup from '@alltrails/denali/components/IconButtonGroup';
import { useAuthorization, useUser } from '@alltrails/context';
import { useLanguageRegionCode } from '@alltrails/language';
import MapsTwoD from '@alltrails/denali/icons/MapsTwoD';
import MapsThreeD from '@alltrails/denali/icons/MapsThreeD';
import Layers from '@alltrails/denali/icons/Layers';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import StyleSelectorModal from '../StyleSelectorModal';
import useStyleKeyboardShortcuts from '../../hooks/useStyleKeyboardShortcuts';
import { useDispatch, useSelector } from '../../redux';
import { updateIs3dActive } from '../../redux/reducer';
import { styleSupports3d } from '../../utils/styleIdHelpers';
import useStyleSelection from '../../hooks/useStyleSelection';

type StyleControlsProps = {
  on3dButtonTapped?: () => void;
  onLayerModalLaunched?: () => void;
  onStyleModalChange?: (isOpen: boolean) => void;
} & Parameters<typeof useStyleSelection>[0];

const StyleControls = ({
  on3dButtonTapped,
  onLayerModalLaunched,
  onStyleModalChange,
  onMapBaseLayerTapped,
  onMapOverlayTapped,
  disableAnalytics
}: StyleControlsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const { hasPermission } = useAuthorization();

  const { baseStyleId, is3dActive } = useSelector(state => ({
    baseStyleId: state.map.baseStyleId,
    is3dActive: state.map.is3dActive
  }));

  const { onStyleSelect } = useStyleSelection({ onMapBaseLayerTapped, onMapOverlayTapped, disableAnalytics });

  const [isMapSelectionModalOpen, setIsMapSelectionModalOpen] = useState(false);

  const setModalOpenStatus = (isOpen: boolean) => {
    onStyleModalChange?.(isOpen);
    setIsMapSelectionModalOpen(isOpen);
  };

  const buttons: ComponentProps<typeof IconButtonGroup>['buttons'] = [
    {
      icon: { Component: Layers },
      onClick: () => {
        onLayerModalLaunched?.();
        setModalOpenStatus(true);
      },
      title: intl.formatMessage({ defaultMessage: 'Map options' }),
      testId: 'map-options'
    }
  ];

  if (styleSupports3d(baseStyleId)) {
    buttons.push({
      icon: is3dActive ? { Component: MapsTwoD } : { Component: MapsThreeD, color: '--color-text-plus' },
      onClick: () => {
        on3dButtonTapped?.();
        if (user?.pro) {
          dispatch(updateIs3dActive(is3dActive ? false : true));
        } else {
          window.location.assign(wrapUrlSafe('/plus', languageRegionCode));
        }
      },
      title: is3dActive ? intl.formatMessage({ defaultMessage: 'Disable 3D map' }) : intl.formatMessage({ defaultMessage: 'Enable 3D map' }),
      testId: '3d-toggle'
    });
  }

  // Note that this is defined outside the scope of the style modal. These
  // shortcuts are meant to be used without having to open the modal
  useStyleKeyboardShortcuts(hasPermission({ permission: 'trails:manage' }) ? onStyleSelect : undefined);

  return (
    <>
      <IconButtonGroup buttons={buttons} orientation="vertical" testId="map-selection-controls" />
      <StyleSelectorModal
        isOpen={isMapSelectionModalOpen}
        onCloseRequest={() => {
          setModalOpenStatus(false);
        }}
        onMapBaseLayerTapped={onMapBaseLayerTapped}
        onMapOverlayTapped={onMapOverlayTapped}
        disableAnalytics={disableAnalytics}
      />
    </>
  );
};

export default StyleControls;
