import { useIntl } from 'react-intl';
import { FONT_FAMILY_PRIMARY, COLOR_TEXT_SECONDARY, FONT_SIZE_50 } from '@alltrails/denali/tokens';

const dy = 16;
const moveY = 10;

type AxisLabelProps = {
  x?: number;
  y?: number;
  payload?: { value: string | number };
  displayMetric?: boolean;
  minX?: number;
  maxX?: number;
  maxY?: number;
};

const XAxisTick = ({ x, y, payload, minX, maxX, displayMetric }: AxisLabelProps) => {
  const value = Number(payload?.value) || 0;
  const isFirst = Number(value?.toFixed?.()) === Number(minX?.toFixed?.());
  const isLast = Number(value?.toFixed?.()) === Number(maxX?.toFixed?.());
  const intl = useIntl();
  const decimalPoint = value > 1000 || value === 0 ? 0 : 1;
  const tickLabel = intl.formatNumber(displayMetric ? value : value, {
    style: 'unit',
    unit: displayMetric ? 'kilometer' : 'mile',
    notation: 'compact',
    minimumFractionDigits: decimalPoint,
    maximumFractionDigits: decimalPoint
  });
  const firstLastTextAnchor = isFirst ? 'start' : 'end';

  return (
    <g transform={`translate(${x},${(y || 0) - moveY})`} data-testid={isLast ? 'last-x-tick' : 'x-tick'}>
      <text
        x={0}
        y={0}
        dy={dy}
        textAnchor={isLast || isFirst ? firstLastTextAnchor : 'middle'}
        fill={COLOR_TEXT_SECONDARY}
        fontFamily={FONT_FAMILY_PRIMARY}
        fontSize={FONT_SIZE_50}
      >
        {tickLabel}
      </text>
    </g>
  );
};

const YAxisTick = ({ x, y, payload, maxY, displayMetric }: AxisLabelProps) => {
  const moveX = (x || 0) + 8;
  const value = Number(payload?.value) || 0;
  const isLast = Number(value.toFixed()) === Number(maxY?.toFixed());
  const intl = useIntl();
  const tickLabel = intl.formatNumber(value, {
    style: 'unit',
    unit: displayMetric ? 'meter' : 'foot',
    maximumFractionDigits: 0
  });
  const firstOffset = moveY;
  const lastOffset = 18;
  const yTranslate = isLast ? (y || 0) - lastOffset : (y || 0) - firstOffset;

  return (
    <g transform={`translate(${moveX},${yTranslate})`} data-testid={isLast ? 'last-y-tick' : 'first-y-tick'}>
      <text x={0} y={0} dy={dy} textAnchor="end" fill={COLOR_TEXT_SECONDARY} fontFamily={FONT_FAMILY_PRIMARY} fontSize={FONT_SIZE_50}>
        {tickLabel}
      </text>
    </g>
  );
};

export { XAxisTick, YAxisTick };
