import Icon from '../components/Icon';

const Translation = Icon(
  'translation',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 2.25A.75.75 0 0 1 9 3v1.5h5.25a.75.75 0 0 1 0 1.5h-2.279a9.75 9.75 0 0 1-2.596 5.905l.112.08a8.25 8.25 0 0 0 4.306 1.502l2.036-4.072a.75.75 0 0 1 1.342 0l3.743 7.486.014.028 1.493 2.986a.75.75 0 1 1-1.342.67L19.786 18h-6.573l-1.292 2.585a.75.75 0 1 1-1.342-.67l1.493-2.986.008-.017.006-.01.986-1.974a9.75 9.75 0 0 1-4.822-1.993 9.75 9.75 0 0 1-6 2.065.75.75 0 0 1 0-1.5 8.25 8.25 0 0 0 4.876-1.595A9.75 9.75 0 0 1 5.059 8.5.75.75 0 0 1 6.473 8a8.25 8.25 0 0 0 1.777 2.912A8.25 8.25 0 0 0 10.466 6H2.25a.75.75 0 0 1 0-1.5H7.5V3a.75.75 0 0 1 .75-.75Zm6.664 12.349-.95 1.901h5.072L16.5 11.427l-1.572 3.144a.913.913 0 0 1-.014.028Z"
    />
  </>
);

export default Translation;
