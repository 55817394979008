import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import SwiperCarousel from '@alltrails/shared/components/SwiperCarousel';
import { SPACE_16 } from '@alltrails/denali/tokens';
import { useSelector, useDispatch } from '../../redux';
import type { AllTrailsResult } from '../../types/Results';
import { updateClickedCoordinates } from '../../redux/reducer';
import { coordinatesToSerializedCoordinates } from '../../utils/serializedCoordinates';
import styles from './styles/styles.module.scss';

type Props = {
  currentResult?: AllTrailsResult;
  renderPopupChild: (result: AllTrailsResult) => JSX.Element;
};

const MobilePopupCarousel = ({ renderPopupChild, currentResult }: Props) => {
  const dispatch = useDispatch();
  const { resultsById, trailheadResults } = useSelector(state => ({
    resultsById: state.map.resultsById,
    trailheadResults: state.map.trailheadResults
  }));
  const [currentIndex, setCurrentIndex] = useState(0);

  const resultsAtCluster = useMemo(
    () =>
      trailheadResults &&
      trailheadResults.reduce((previousTrailheadId, currentTrailheadId) => {
        if (currentTrailheadId && resultsById[currentTrailheadId]) {
          previousTrailheadId.push(resultsById[currentTrailheadId]);
        }
        return previousTrailheadId;
      }, [] as AllTrailsResult[]),

    [trailheadResults, resultsById]
  );

  const onSlideChange = useCallback(
    (index: number) => {
      setCurrentIndex(index);
      // eslint-disable-next-line no-underscore-dangle
      const coordinates = resultsAtCluster?.[index]?._cluster_geoloc
        ? // eslint-disable-next-line no-underscore-dangle
          resultsAtCluster?.[index]?._cluster_geoloc
        : // eslint-disable-next-line no-underscore-dangle
          resultsAtCluster?.[index]?._geoloc;
      const serializedCoordinates = coordinates && coordinatesToSerializedCoordinates(coordinates);
      dispatch(updateClickedCoordinates?.({ coordinates: serializedCoordinates, index }));
    },
    [dispatch, resultsAtCluster]
  );

  const getItemKey = useCallback((result: AllTrailsResult) => String(result?.ID), []);

  const renderItem = useCallback(
    (result: AllTrailsResult) => <div className={styles.carouselSlide}>{renderPopupChild(result)}</div>,
    [renderPopupChild]
  );

  if (!trailheadResults || !currentResult || !resultsAtCluster || resultsAtCluster?.length === 0) {
    return null;
  }

  return (
    <div className={styles.carouselContainer} data-testid="mobile-popup-carousel">
      <div className={styles.pinCount}>
        <span className={styles.text}>
          {currentIndex + 1} <FormattedMessage defaultMessage="of" /> {resultsAtCluster?.length}
        </span>
      </div>
      <SwiperCarousel
        uniqueId="trailhead-carousel"
        items={resultsAtCluster}
        renderItem={renderItem}
        getItemKey={getItemKey}
        showOverflow
        scrollMode={false}
        gap={SPACE_16}
        loop
        onSlideChange={onSlideChange}
      />
    </div>
  );
};

export default MobilePopupCarousel;
