'use client';

import IconButton from '@alltrails/denali/components/IconButton';
import ArrowSq from '@alltrails/denali/icons/ArrowSq';
import Car from '@alltrails/denali/icons/Car';
import Share from '@alltrails/denali/icons/Share';
import logDirectionsButtonTapped from '@alltrails/analytics/events/logDirectionsButtonTapped';
import PageType from '@alltrails/analytics/enums/PageType';
import { useIntl } from '@alltrails/shared/react-intl';
import { ShareObject } from '@alltrails/sharing';
import { useLanguageRegionCode } from '@alltrails/language';
import { useUser } from '@alltrails/context';
import useNavigateBackInternal from '@alltrails/layout/hooks/useNavigateBackInternal';
import useRoadblock from '@alltrails/layout/hooks/useRoadblock';
import useInstallPrompt from '@alltrails/install-prompt/hooks/useInstallPrompt';
import InstallInterstitial from '@alltrails/install-prompt/components/InstallInterstitial';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { useModal } from '../../ShareModal/ShareModalContext';
import styles from './MobileButtons.module.scss';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';

type Props = {
  directionsLink?: string;
  shareObject?: ShareObject;
  roadblockPrefix?: LocationDetailsResponseType;
  id: number;
  pageType?: PageType;
};

const MobileButtons = ({ directionsLink, shareObject, roadblockPrefix, id, pageType }: Props) => {
  const { openModal } = useModal();
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const [isInterstitialOpen, setIsInterstitialOpen] = useInstallPrompt('interstitial', false);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [currentUrl, setCurrentUrl] = useState('');
  const { canNavigateBack, navigateBack } = useNavigateBackInternal();

  useEffect(() => setCurrentUrl(`${pathname}?${searchParams}`), [pathname, searchParams]);

  const onRoadblock = useRoadblock({
    afterRoadblock: directionsLink || '',
    eventName: `${roadblockPrefix}_directions`,
    languageRegionCode,
    returnToUrl: currentUrl,
    user,
    setIsInterstitialOpen,
    openInNewTab: true
  });
  const roadblockDirections = !user;

  const onDirectionsClick = useCallback(() => {
    logDirectionsButtonTapped({ id, page_type: pageType });
    if (roadblockDirections) {
      onRoadblock();
    } else {
      return undefined;
    }
  }, [id]);

  return (
    <div className={styles.mobileButtonsContainer} data-testid="mobile-buttons-container">
      <IconButton
        icon={{ Component: ArrowSq, orientation: 'left' }}
        onClick={navigateBack}
        title={canNavigateBack ? intl.formatMessage({ defaultMessage: 'Back' }) : intl.formatMessage({ defaultMessage: 'Back to Explore' })}
        testId="location-page-back"
        variant="transparent"
        size="sm"
        className={styles.button}
      />
      <div className={styles.rightButtonsContainer}>
        <>
          {directionsLink && (
            <IconButton
              icon={{ Component: Car }}
              linkInfo={roadblockDirections ? undefined : { href: directionsLink, target: '_blank', rel: ['noopener', 'noreferrer'] }}
              onClick={onDirectionsClick}
              title={intl.formatMessage({ defaultMessage: 'Directions' })}
              testId="location-page-directions"
              variant="transparent"
              size="sm"
              className={styles.button}
            />
          )}
          <InstallInterstitial
            isOpen={isInterstitialOpen}
            onRequestClose={() => setIsInterstitialOpen(false)}
            promptLocation={InstallPromptLocation.ParksPage}
          />
        </>
        {shareObject && (
          <IconButton
            icon={{ Component: Share }}
            onClick={openModal}
            title={intl.formatMessage({ defaultMessage: 'Share' })}
            testId="location-page-share"
            variant="transparent"
            size="sm"
            className={styles.button}
          />
        )}
      </div>
    </div>
  );
};

export default MobileButtons;
