import { useEffect } from 'react';
import { StyleId } from '../types/Styles';

export const keycodeShortcuts: Partial<Record<StyleId, string>> = {
  adminBasemap: 'KeyB',
  adminOverlay: 'KeyY',
  alltrailsOutdoorsV2: 'KeyF',
  alltrailsSatellite: 'KeyS',
  geoBoundaries: 'KeyQ',
  googleSatellite: 'KeyA',
  heatmap: 'KeyP',
  nearbyTrails: 'KeyO',
  openstreet: 'KeyE',
  recordingsAll: 'KeyL',
  recordingsPop: 'KeyK',
  roadmap: 'KeyR',
  topo: 'KeyT',
  worldparks: 'KeyW',
  worldtopo: 'KeyD'
};

const useStyleKeyboardShortcuts = (onStyleSelect?: (styleId: StyleId) => void) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !onStyleSelect) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      // Must be also holding down alt
      if (event.altKey) {
        const styleId = (Object.keys(keycodeShortcuts) as StyleId[]).find(id => keycodeShortcuts[id] === event.code);

        if (styleId) {
          onStyleSelect(styleId);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onStyleSelect]);
};

export default useStyleKeyboardShortcuts;
