import { get } from '@alltrails/shared/api';

type Params = {
  insideBoundingBox: string;
  limit?: number;
  assignmentTypes?: string[];
  excludedIds?: number[];
};

export type GeoBoundarySearchResultGeoBoundaryTrailFilter = {
  assignmentId: string;
  assignmentType: string;
  creatorId: number;
  id: number;
  name: string;
  project: {
    id: number;
    name: string;
  };
};

export type GeoBoundarySearchResult = {
  geoBoundaryTrailFilters: GeoBoundarySearchResultGeoBoundaryTrailFilter[];
  id: number;
  readOnly: boolean;
  simplifiedBoundaries: string;
};
export type GeoBoundariesResponse = {
  geoBoundarySearchResults: GeoBoundarySearchResult[];
};

const getGeoBoundariesInBounds = ({ insideBoundingBox, assignmentTypes }: Params) => {
  const params = new URLSearchParams();
  params.append('insideBoundingBox', insideBoundingBox);
  if (assignmentTypes) {
    assignmentTypes.forEach(assignmentType => params.append('assignmentTypes[]', assignmentType));
  }
  return get<GeoBoundariesResponse>(`/api/alltrails/geo-service/geo_boundaries/search?${params.toString()}`);
};

export default getGeoBoundariesInBounds;
