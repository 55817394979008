import type { MapRef, LngLatBoundsLike } from 'react-map-gl';
import { AllTrailsResult } from '../types/Results';
import fitBoundsOnLoad from './fitBoundsOnLoad';

const fitBoundsParams = {
  padding: 20,
  maxZoom: 12,
  duration: 0
};

type Props = {
  map?: MapRef;
  results?: AllTrailsResult[];
  bounds?: LngLatBoundsLike;
};

const fitBoundsOfArea = ({ map, results, bounds }: Props) => {
  if (!map) {
    return;
  }

  if (!!bounds) {
    map.fitBounds(bounds, fitBoundsParams);
  }

  if (!!results) {
    fitBoundsOnLoad(results, map, 0, 20, 12);
  }
};

export default fitBoundsOfArea;
