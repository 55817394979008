import { Feature } from 'geojson';
import { AlgoliaHit } from '@alltrails/search/types/algoliaResultTypes';

const CIRCLE_SPIRAL_SWITCHOVER = 9;
const CIRCLE_FOOT_SEPARATION = 25;
const SPIRAL_FOOT_SEPARATION = 28;
const SPIRAL_LENGTH_START = 15;
const SPIRAL_LENGTH_FACTOR = 4;

export type SpiderLeg = {
  x: number;
  y: number;
  angle: number;
  legLength: number;
  index: number;
  properties: AlgoliaHit;
};
const twoPi = Math.PI * 2;

export function generateSpiralParams(features: Feature[]): SpiderLeg[] {
  let legLength = SPIRAL_LENGTH_START;
  let angle = 0;
  return features.map((feature, index) => {
    angle += SPIRAL_FOOT_SEPARATION / legLength + index * 0.0005;
    const pt = {
      x: legLength * Math.cos(angle),
      y: legLength * Math.sin(angle),
      angle,
      legLength,
      index,
      properties: feature.properties as AlgoliaHit
    };
    legLength += (twoPi * SPIRAL_LENGTH_FACTOR) / angle;
    return pt;
  });
}

export function generateCircleParams(features: Feature[]): SpiderLeg[] {
  const count = features.length;
  const circumference = CIRCLE_FOOT_SEPARATION * (2 + count);
  const legLength = circumference / twoPi; // = radius from circumference
  const angleStep = twoPi / count;

  return features.map((feature, index) => {
    const angle = index * angleStep;
    return {
      x: legLength * Math.cos(angle),
      y: legLength * Math.sin(angle),
      angle,
      legLength,
      index,
      properties: feature.properties as AlgoliaHit
    };
  });
}

export function generateSpiderLegParams(features: Feature[]): SpiderLeg[] {
  if (!features) {
    return [];
  }
  const count = features.length;
  if (count >= CIRCLE_SPIRAL_SWITCHOVER) {
    return generateSpiralParams(features);
  }
  return generateCircleParams(features);
}

export const popupOffsetForSpiderLeg = (leg: SpiderLeg): [number, number] => {
  const { x, y } = leg;
  return [x, y];
};
