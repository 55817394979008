import { useEffect, useState } from 'react';
import { type IntlShape, useIntl } from 'react-intl';
import type { MapboxStyle } from 'react-map-gl';
import { getMapboxAccessToken } from '@alltrails/core';
import { useLanguageCode } from '@alltrails/language';
import LanguageCode from '@alltrails/shared/types/LanguageCode';
import { useAuthorization } from '@alltrails/context';
import type { BaseStyleId } from '../types/Styles';
import { getBaseStyle } from '../utils/getStyle';
import { useSelector } from '../redux';

type State = {
  cache: Partial<Record<BaseStyleId, string | MapboxStyle>>;
  currentStyle?: string | MapboxStyle;
};

/**
 * localizeStyle alters a style configuration to use the user's language and display units
 *
 * This is copied over from the monolith. There are alternatives to this approach, but this is the simplest.
 *
 * https://docs.mapbox.com/mapbox-gl-js/example/language-switch/
 * https://docs.mapbox.com/help/troubleshooting/change-language/
 * https://github.com/mapbox/mapbox-gl-language
 *
 * @param style
 * @param displayMetric
 * @param languageCode
 * @param intl
 * @returns
 */
function localizeStyle({
  style,
  displayMetric,
  languageCode,
  intl
}: {
  style: MapboxStyle;
  displayMetric?: boolean;
  languageCode: LanguageCode;
  intl: IntlShape;
}) {
  // Do nothing if displaying English, imperial layer
  if ((!languageCode || languageCode === 'en') && !displayMetric) {
    return style;
  }

  // Use first name found from: user-locale name (eg Moscou for a French user), English name (eg Moscow), name in place's local language (eg Москва)
  let styleStr = JSON.stringify(style)
    .replace(/\["coalesce",\["get","name_en"\],\["get","name"\]\]/g, `["coalesce",["get","name_${languageCode}"],["get","name_en"],["get","name"]]`)
    .replace(/n:en/g, `n:${languageCode}`)
    .replace(/PRIVATE/g, intl.formatMessage({ defaultMessage: 'Private' }));

  if (displayMetric) {
    styleStr = styleStr
      .replace(/elevation_ft/g, 'elevation_m')
      .replace(/ ft/g, ' m')
      .replace(/\["get","l"]/g, '["/",["round",["*",["get","l"],16.1]],10]')
      .replace(/\["\*",\["get","ele"],3\.281]/g, '["get","ele"]') // used in AllTrails layer
      .replace(/\["\*",\["get","ele"],3\.28084]/g, '["get","ele"]'); // used in AllTrails Mapbox Outdoors layer
  }

  return JSON.parse(styleStr);
}

export default function useBaseStyle(): string | MapboxStyle | undefined {
  const mapboxStyleIds = useSelector(state => state.map.mapboxStyleIds);
  const intl = useIntl();
  const languageCode = useLanguageCode();
  const mapboxAccessToken = getMapboxAccessToken();
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });
  const isPLP = hasPermission({ permission: 'public_lands:manage' });
  const hideCustomAttribution = isPLP && !isAdmin;

  const { displayMetric, adminStyleSettings, baseStyleId } = useSelector(state => ({
    displayMetric: state.context.displayMetric,
    adminStyleSettings: state.map.adminStyleSettings,
    baseStyleId: state.map.baseStyleId
  }));

  const [{ cache, currentStyle }, setState] = useState<State>({
    cache: {}
  });
  // Update the cache of styles whenever the selected id changes
  useEffect(() => {
    (async () => {
      const cachedStyle = cache[baseStyleId];
      if (cachedStyle) {
        setState({ cache, currentStyle: cachedStyle });
        return;
      }

      let style;

      // Layers that we must dynamically update on the client. Rather than passing in a URL or configuration to our
      // map we have to manually load the style from Mapbox and then modify it.
      switch (baseStyleId) {
        case 'roadmap':
          style = await (
            await fetch(getBaseStyle({ adminStyleSettings, styleId: baseStyleId, hideCustomAttribution, mapboxStyleIds }) as string)
          ).json();
          // We must manually inject the glyphs for this style. Not needed for raster styles we manually construct or
          // styles we control in Mapbox Studio that have the fonts embedded.
          style.glyphs = 'mapbox://fonts/alltrails/{fontstack}/{range}.pbf';
          break;
        case 'worldparks':
        case 'alltrailsOutdoorsV2':
          style = await (
            await fetch(getBaseStyle({ adminStyleSettings, styleId: baseStyleId, hideCustomAttribution, mapboxStyleIds }) as string)
          ).json();
          style = localizeStyle({ style, displayMetric, languageCode, intl });
          break;
        default:
          style = getBaseStyle({ adminStyleSettings, styleId: baseStyleId, hideCustomAttribution, mapboxStyleIds });
      }

      // Update state now that the data is loaded and available
      setState({
        cache: {
          ...cache,
          [baseStyleId]: style
        },
        currentStyle: style
      });
    })();
  }, [cache, mapboxAccessToken, displayMetric, languageCode, intl, baseStyleId, adminStyleSettings, hideCustomAttribution, mapboxStyleIds]);

  return currentStyle;
}
