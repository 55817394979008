import { useCallback, useEffect, useState, useMemo, SetStateAction, Dispatch } from 'react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { getGroupedSegments, processGroupedSegments, convertNDimTo2DimPolylines, processElevations } from '../utils/legacyGeoJSONConversions';
import type { ActivityWithTracks, ElevationPoint, MapWithRoutes, Polylines } from '../types/Geo';
import getElevationsForPolylines from '../requests/getElevationsForPolylines';

const elevationDebounce = 1500;

type Args = {
  displayMetric?: boolean;
  map?: Partial<ActivityWithTracks> | Partial<MapWithRoutes>;
  polylines?: Polylines;
  setRawChartData: Dispatch<SetStateAction<ElevationPoint[]>>;
  setReferenceLineIndices?: Dispatch<SetStateAction<number[] | null>>;
};

export default function useElevationData({ displayMetric, map, polylines, setRawChartData, setReferenceLineIndices }: Args) {
  const [hasExistingPolylineElevations, setHasExistingPolylineElevations] = useState<boolean>(false);
  const [segments, setSegments] = useState<any[]>([]);

  const fetchElevations = useCallback(
    async (encodedPolylines: string[]) => {
      try {
        const elevations = await getElevationsForPolylines(encodedPolylines);
        const { pointByPointSegmented, elevationGain } = processElevations(elevations, displayMetric);
        setRawChartData(pointByPointSegmented?.flat?.() ?? []);
      } catch (error) {
        console.error('Error getting elevations ', error);
        setRawChartData([]);
      }
    },
    [displayMetric, setRawChartData]
  );

  const debouncedFetchElevations = useMemo(() => debounce(fetchElevations, elevationDebounce), [fetchElevations]);

  useEffect(() => {
    // Special handling for in-progress live-share
    if (!hasExistingPolylineElevations && polylines && polylines.length > 0) {
      const encodedPolylines = convertNDimTo2DimPolylines(polylines);
      fetchElevations(encodedPolylines);
    } else if (!polylines) {
      const groupedSegments = getGroupedSegments(map);
      const tempSegments = groupedSegments?.map(group => group.segments).flat();
      // Don't make updates if segment data hasn't changed
      if (isEqual(tempSegments, segments)) {
        return;
      }

      if (tempSegments.length < 1) {
        setRawChartData([]);
        return;
      }

      if (tempSegments) {
        setSegments(tempSegments);
      }

      const { pointByPointSegmented } = processGroupedSegments(groupedSegments, displayMetric, true);
      // Show a ReferenceLine at the end of each segment
      const cumulativeLengths = pointByPointSegmented.reduce((acc: number[], segment) => {
        if (segment.length > 1) {
          acc.push(segment[segment.length - 1][0]);
        }
        return acc;
      }, []);
      setReferenceLineIndices?.(cumulativeLengths.slice(0, -1)); // the last index is the end of the last segment, so we don't need it
      setRawChartData(pointByPointSegmented.flat());
    }
  }, [
    map,
    polylines,
    segments,
    displayMetric,
    hasExistingPolylineElevations,
    setRawChartData,
    fetchElevations,
    debouncedFetchElevations,
    setReferenceLineIndices
  ]);

  useEffect(() => {
    setHasExistingPolylineElevations(false); // Reset this flag when polylines changes to ensure we re-fetch elevations
  }, [polylines]);
}
