import { ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuthorization } from '@alltrails/context';
import { Image, Typography } from '@alltrails/core';
import Button from '@alltrails/denali/components/Button';
import Checkbox from '@alltrails/denali/components/Checkbox';
import PlusBadge from '@alltrails/denali/components/PlusBadge';
import Select from '@alltrails/denali/components/Select';
import ActivityType from '@alltrails/shared/types/activityType';
import useActivityStrings from '@alltrails/shared/hooks/useActivityStrings';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import DropdownMenu from '@alltrails/denali/components/DropdownMenu';
import ChevronSm from '@alltrails/denali/icons/ChevronSm';
import { BoundaryFilterType, StyleId } from '../../types/Styles';
import { isBaseStyle, isDynamicStyle, isStaticStyle } from '../../utils/styleIdHelpers';
import { useDispatch, useSelector } from '../../redux';
import { toggleHeatmapStyleId, updateAdminStyleSettings, addOrRemoveBoundaryFilter } from '../../redux/reducer';
import useStyleSelection from '../../hooks/useStyleSelection';
import { baseStyleCardConfigs, dynamicStyleCardConfigs, staticStyleCardConfigs } from '../../utils/styleCardConfigs';
import { keycodeShortcuts } from '../../hooks/useStyleKeyboardShortcuts';
import styles from './styles/styles.module.scss';

type Props = {
  onUrlButtonClick?: () => void;
  styleId: StyleId;
} & Parameters<typeof useStyleSelection>[0];

const StyleSelectionCard = ({ onUrlButtonClick, styleId, onMapBaseLayerTapped, onMapOverlayTapped, disableAnalytics }: Props) => {
  const [boundaryTypeSelectOpen, setBoundaryTypeSelectOpen] = useState(false);
  const isMobile = useIsMobileSizedScreen();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission({ permission: 'trails:manage' });
  const isPLP = hasPermission({ permission: 'public_lands:manage' });
  const { adminStyleSettings, baseStyleId, dynamicStyleIds, heatmapStyleIds, staticStyleIds } = useSelector(state => ({
    adminStyleSettings: state.map.adminStyleSettings,
    baseStyleId: state.map.baseStyleId,
    dynamicStyleIds: state.map.dynamicStyleIds,
    heatmapStyleIds: state.map.heatmapStyleIds,
    staticStyleIds: state.map.staticStyleIds
  }));

  const { onStyleSelect } = useStyleSelection({ onMapBaseLayerTapped, onMapOverlayTapped, disableAnalytics });

  const { active, displayName, imgSrc, requiresPlus, supports3d } = useMemo<{
    active: boolean;
    displayName: ReactNode;
    imgSrc: string;
    requiresPlus?: boolean;
    supports3d?: boolean;
  }>(() => {
    if (isBaseStyle(styleId)) {
      const { displayName, imgSrc, supports3d } = baseStyleCardConfigs[styleId];
      const active = baseStyleId === styleId;
      return { active, displayName, imgSrc, supports3d };
    } else if (isStaticStyle(styleId)) {
      const { displayName, imgSrc, requiresPlus } = staticStyleCardConfigs[styleId];
      const active = staticStyleIds.includes(styleId);
      return { active, displayName, imgSrc, requiresPlus };
    } else {
      const { displayName, imgSrc, requiresPlus } = dynamicStyleCardConfigs[styleId];
      const active = dynamicStyleIds.includes(styleId);
      return { active, displayName, imgSrc, requiresPlus };
    }
  }, [baseStyleId, dynamicStyleIds, staticStyleIds, styleId]);

  const heatmapCheckbox = useMemo(() => {
    if (isDynamicStyle(styleId) && dynamicStyleCardConfigs[styleId].hasHeatmap && (isAdmin || isPLP)) {
      return (
        <Checkbox
          className={styles.heatmapCheckbox}
          disabled={!dynamicStyleIds.includes(styleId)}
          id={styleId}
          labelElement={null}
          onChange={() => dispatch(toggleHeatmapStyleId(styleId))}
          onClick={event => event.stopPropagation()}
          selected={heatmapStyleIds.includes(styleId)}
          testId={`${styleId}-heatmap`}
        />
      );
    }
    return null;
  }, [isAdmin, isPLP, dispatch, dynamicStyleIds, heatmapStyleIds, styleId]);

  const { activitySelectOptions } = useActivityStrings();

  const activitySelect = useMemo(() => {
    if (styleId === 'recordingsAll' || styleId === 'recordingsPop') {
      return (
        <Select
          className={styles.activitySelect}
          hideLabel
          labelText={intl.formatMessage({ defaultMessage: 'Activity type' })}
          options={[{ label: <FormattedMessage defaultMessage="All" />, value: 'all' }, ...activitySelectOptions]}
          onChange={value => {
            dispatch(updateAdminStyleSettings({ ...adminStyleSettings, activityType: value === 'all' ? 'all' : (value as ActivityType) }));
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          placeholder=""
          size="sm"
          testId={`${styleId}-activity-select`}
          value={adminStyleSettings?.activityType}
        />
      );
    }
    return null;
  }, [activitySelectOptions, adminStyleSettings, dispatch, intl, styleId]);

  const boundaryAssignmentMultiselect = useMemo(() => {
    if (styleId === 'geoBoundaries') {
      const optionTitles = {
        [BoundaryFilterType.osm]: intl.formatMessage({ defaultMessage: 'OSM Parks' }),
        [BoundaryFilterType.alertClosure]: intl.formatMessage({ defaultMessage: 'Alerts: Closure' }),
        [BoundaryFilterType.alertNonClosure]: intl.formatMessage({ defaultMessage: 'Alerts: Danger, Caution, Info' }),
        [BoundaryFilterType.groupPermissions]: intl.formatMessage({ defaultMessage: 'Permissions' })
      };
      const options = Object.values(BoundaryFilterType).map(filter => ({
        selected: adminStyleSettings?.boundaryFilters?.includes(filter),
        testId: `option-${filter}`,
        title: optionTitles[filter],
        onClick: () => dispatch(addOrRemoveBoundaryFilter(filter))
      }));

      return (
        <DropdownMenu
          options={options}
          placement="bottom-start"
          anchor={
            <button
              className={styles.filterMultiSelect}
              data-testid="multiselect-style-filter"
              onClick={() => setBoundaryTypeSelectOpen(!boundaryTypeSelectOpen)}
            >
              <FormattedMessage
                defaultMessage="{filterCount, plural, =0 {All} one {# Filter} other {# Filters}}"
                values={{ filterCount: adminStyleSettings?.boundaryFilters?.length || 0 }}
              />
              <ChevronSm orientation={boundaryTypeSelectOpen ? 'up' : 'down'} size="sm" color="--color-text-placeholder" />
            </button>
          }
          isOpen={boundaryTypeSelectOpen}
          onCloseRequest={() => setBoundaryTypeSelectOpen(false)}
        />
      );
    }
    return null;
  }, [boundaryTypeSelectOpen, styleId, adminStyleSettings, dispatch, intl]);

  const keyboardShortcutText = useMemo(() => {
    const keycode = keycodeShortcuts[styleId];
    if (keycode && isAdmin) {
      return `Opt+${keycode.replace('Key', '')}`; // KeyA -> A
    }
    return undefined;
  }, [isAdmin, styleId]);

  const urlButton = useMemo(() => {
    const requiresUrl =
      (isBaseStyle(styleId) && baseStyleCardConfigs[styleId].requiresAdminStyleSourceUrl) ||
      (isStaticStyle(styleId) && staticStyleCardConfigs[styleId].requiresAdminStyleSourceUrl);
    if (requiresUrl && onUrlButtonClick) {
      return (
        <Button
          className={styles.urlButton}
          onClick={onUrlButtonClick}
          size="sm"
          stopPropagation
          testId={`${styleId}-url-button`}
          text="URL"
          variant="primary"
        />
      );
    }
    return null;
  }, [onUrlButtonClick, styleId]);

  return (
    <div className={styles.container}>
      <button
        data-testid={styleId}
        className={classNames(styles.button, { [styles.active]: active })}
        onClick={() => {
          onStyleSelect(styleId);
        }}
      >
        <Image className={styles.image} alt={intl.formatMessage({ defaultMessage: 'Preview' })} src={imgSrc} fill />
        {supports3d && (
          <div className={styles.tag3d}>
            <FormattedMessage defaultMessage="3D available" />
          </div>
        )}
        {requiresPlus && !isAdmin ? <PlusBadge size="md" className={styles.plusBadge} /> : null}
      </button>
      {activitySelect}
      {boundaryAssignmentMultiselect}
      {heatmapCheckbox}
      {urlButton}
      <Typography mb="8" variant={isAdmin ? 'text100bold' : 'text200'}>
        {displayName}
      </Typography>
      {!isMobile && keyboardShortcutText && (
        <Typography variant="text100" color="secondary">
          {keyboardShortcutText}
        </Typography>
      )}
    </div>
  );
};

export default StyleSelectionCard;
