import { useEffect, useMemo, useState } from 'react';
import { LngLatBounds } from 'mapbox-gl';
import useAlgoliaIndex from '@alltrails/search/hooks/useAlgoliaIndex';
import { FeatureCollection } from 'geojson';
import { AlgoliaHit } from '@alltrails/search/types/algoliaResultTypes';
import { AlgoliaObjectType, getNotObjectIdFilters } from '@alltrails/search/utils/algoliaFilters';
import { useSelector } from '../../redux';
import AdminClusteredPins from '../AdminClusteredPins';
import { pointItemsToGeojson } from '../../utils/legacyGeoJSONConversions';
import HeatmapLayer from './HeatmapLayer';
import { getBoundingBoxParams } from './algoliaUtils';

type NearbyTrailsOverlayProps = {
  bounds?: LngLatBounds;
  showHeatmap?: boolean;
  excludedTrailIds?: number[];
};

const NearbyTrailsOverlay = ({ bounds, showHeatmap, excludedTrailIds }: NearbyTrailsOverlayProps) => {
  const { algoliaConfigs } = useSelector(state => ({ algoliaConfigs: state.algoliaConfigs }));
  const index = useAlgoliaIndex(algoliaConfigs, ['trail']);
  const [results, setResults] = useState<AlgoliaHit[]>([]);

  useEffect(() => {
    if (!bounds) {
      return;
    }
    const getResults = async () => {
      const result = await index.search('', {
        hitsPerPage: 5000,
        facetFilters: ['type:trail'],
        filters: excludedTrailIds ? getNotObjectIdFilters(AlgoliaObjectType.Trail, excludedTrailIds) : undefined,
        ...getBoundingBoxParams(bounds)
      });
      setResults(result.hits);
    };
    getResults();
  }, [bounds, index, setResults, excludedTrailIds]);

  const geoJson = useMemo(() => {
    return results?.length ? pointItemsToGeojson(results) : undefined;
  }, [results]);

  return (
    <>
      {geoJson ? <AdminClusteredPins featureCollection={geoJson as FeatureCollection} type="trail" id="nearbyTrails" /> : null}
      {showHeatmap && <HeatmapLayer results={results} type="trail" uniqueId="nearbyTrails" />}
    </>
  );
};

export default NearbyTrailsOverlay;
