import { trailCollectionImages } from '../TrailCollection';
import { adminClusteredPinsImages } from '../AdminClusteredPins';
import { polylineImages } from '../Polyline';
import DynamicStyleOverlays from './DynamicStyleOverlays';

export default DynamicStyleOverlays;

export const dynamicStyleImages = {
  ...polylineImages,
  ...trailCollectionImages,
  ...adminClusteredPinsImages
};
