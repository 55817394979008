import { shallowEqual, type TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { type Context, contextReducer as context } from '@alltrails/context';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import algoliaConfigs from '@alltrails/redux/slices/algoliaConfigs';
import lists, { type ListsState } from '@alltrails/redux/slices/lists';
import { MapState, mapReducer as map } from './reducer';

export type PreloadedState = Partial<{
  algoliaConfigs: AlgoliaConfigs;
  context: Partial<Context>;
  lists: Partial<ListsState>;
  map: Partial<MapState>;
}>;

type FullPreloadedState = Partial<{
  algoliaConfigs: AlgoliaConfigs;
  context: Context;
  lists: ListsState;
  map: MapState;
}>;

export const reducer = {
  algoliaConfigs,
  context,
  lists,
  map
};

export const buildStore = (preloadedState?: FullPreloadedState) => configureStore({ reducer, preloadedState });

export type AppStore = ReturnType<typeof buildStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<AppStore['getState']>;

// these are intended to use within the package only
export const useDispatch = (): AppDispatch => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = (selector: (state: RootState) => any, equalityFn = shallowEqual) =>
  useAppSelector<RootState, any>(selector, equalityFn);
