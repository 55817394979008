import BaseMapCard from '@alltrails/shared/components/MapCard';
import { useLanguageRegionCode } from '@alltrails/language';
import { useSelector } from '../../redux';
import useAdminFooterCTAs from '../../hooks/useAdminFooterCTAs';
import useAdminTrackLinks from './useAdminTrackLinks';
import { AdminMapHit, mapHitToMap } from './utils';

type MapCardProps = {
  currentResult: AdminMapHit;
  setIsPopupHovered: (isPopupHovered: boolean) => void;
};

const MapCard = ({ currentResult, setIsPopupHovered }: MapCardProps) => {
  const languageRegionCode = useLanguageRegionCode();
  const displayMetric = useSelector(state => state.context.displayMetric);
  const adminTrackLinks = useAdminTrackLinks(currentResult);
  const footerCTAs = useAdminFooterCTAs(adminTrackLinks);

  return (
    <BaseMapCard
      languageRegionCode={languageRegionCode}
      onCardClick={() => window.open(`/explore/map/${currentResult?.slug}`, '_blank')}
      onCardMouseEnter={() => setIsPopupHovered(true)}
      onCardMouseLeave={() => setIsPopupHovered(false)}
      variant="small"
      map={mapHitToMap(currentResult)}
      useMetric={!!displayMetric}
      footerCTAs={footerCTAs}
    />
  );
};

export default MapCard;
