import { useEffect, useState } from 'react';
import { type GeoJSONSource, Layer } from 'react-map-gl';
import { Feature, Point } from 'geojson';
import { COLOR_TEXT_PRIMARY_INVERSE, FONT_SIZE_100, FONT_SIZE_50 } from '@alltrails/denali/tokens';
import { defaultSymbolLayerLayoutProps, getLayerBeforeId, trailClustersId } from '../../utils/layers';
import { useDispatch, useSelector } from '../../redux';
import { updateCursor } from '../../redux/reducer';
import useMap from '../../hooks/useMap';
import useSvgImage from '../../utils/useSvgImage';
import trailClusterSvg from './trailClusterSvg';
import trailClusterHoveredSvg from './trailClusterHoveredSvg';

const svgSize = 32;
const trailClusterImageName = 'trail-cluster';
const trailClusterHoveredImageName = 'trail-cluster-hovered';

export type TrailClustersProps = {
  sourceId: string;
};

const TrailClusters = ({ sourceId }: TrailClustersProps) => {
  const map = useMap();
  const [hoveredClusterId, setHoveredClusterId] = useState(); // This is outside of redux because as of now, we have no reason to know this outside of this component
  const dispatch = useDispatch();
  const { allowClickEvents } = useSelector(state => ({ allowClickEvents: state.map.allowClickEvents }));

  const trailClusterImageIsLoaded = useSvgImage(trailClusterImageName, trailClusterSvg, svgSize, svgSize);
  const trailClusterHoveredImageIsLoaded = useSvgImage(trailClusterHoveredImageName, trailClusterHoveredSvg, svgSize, svgSize);

  useEffect(() => {
    if (!allowClickEvents) {
      return;
    }
    map?.on('mousemove', trailClustersId, e => {
      if (e.features) {
        const clusterId = e.features[0]?.properties?.cluster_id;
        if (clusterId) {
          dispatch(updateCursor('pointer'));
          setHoveredClusterId(clusterId);
        }
      }
    });

    map?.on('mouseleave', trailClustersId, e => {
      dispatch(updateCursor('unset'));
      setHoveredClusterId(undefined);
    });
  }, [allowClickEvents, dispatch, map]);

  useEffect(() => {
    if (!allowClickEvents) {
      return;
    }
    // On cluster click, zoom the map into the point that the cluster would break apart
    map?.on('click', trailClustersId, e => {
      if (e.features) {
        const cluster = e.features[0] as Feature<Point>;
        const clusterId = cluster.properties?.cluster_id;
        if (clusterId) {
          (e.target.getSource(sourceId) as GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
              return;
            }

            map.easeTo({ center: cluster.geometry.coordinates as [number, number], zoom });
          });
        }
      }
    });
  }, [allowClickEvents, map, sourceId]);

  if (!trailClusterImageIsLoaded || !trailClusterHoveredImageIsLoaded) {
    return null;
  }

  return (
    <Layer
      source={sourceId}
      type="symbol"
      id={trailClustersId}
      beforeId={getLayerBeforeId(map, trailClustersId)}
      filter={['has', 'point_count']}
      layout={{
        ...defaultSymbolLayerLayoutProps,
        'text-size': ['step', ['get', 'point_count'], FONT_SIZE_100, 100, FONT_SIZE_50, 1000, 8], // Reduce the font size at 100 and 1000 to avoid overflow
        'text-anchor': 'center',
        'text-field': '{point_count}',
        'icon-image': ['case', ['==', ['get', 'cluster_id'], hoveredClusterId || null], trailClusterHoveredImageName, trailClusterImageName]
      }}
      paint={{ 'text-color': COLOR_TEXT_PRIMARY_INVERSE }}
    />
  );
};

export default TrailClusters;
